<template>
  <div>
    <sub-header name="Crear inspecciones" :is_master="false"></sub-header>
    <div v-if="loading">
      <loading></loading>
    </div>
    <div v-else>
      <div class="my-8 flex-1 justify-center px-4">
        <v-select
            v-model="selected_type"
            :items="types"
            attach
            chips
            label="Tipo de inspeccion"
            item-text="name"
            item-value="_id"
            @change="selectType"
        ></v-select>
      </div>
      <div v-if="item_selected && item_selected.isVehicle" class="my-8 flex-1 justify-center px-4">
        <v-autocomplete
            @change="loadVehicleInfo"
            class="w-full md:w-2/5 mx-auto "
            clearable
            v-model="select_vehicle"
            :items="vehicles_data"
            label="Selecciona el vehículo a inspeccionar">
          <template v-slot:selection="data">
            {{ data.item.plate }}
          </template>
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title v-html="data.item.plate"></v-list-item-title>
              <v-list-item-subtitle v-html="data.item.name"></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
        <div v-if="select_vehicle">
          <v-expansion-panels
              v-model="panel"
              multiple
          >
            <v-expansion-panel>
              <v-expansion-panel-header>Información del vehículo</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="flex mt-4">
                  <div class="w-3/5 md:w-1/2">
                    <div class="text-subtitle-2 text-gray-1">Conductor:</div>
                    <div class="text-body-1 text-gray-2">{{ select_vehicle.name }}</div>
                  </div>
                  <div class="w-2/5 md:w-1/2">
                    <div class="text-subtitle-2 text-gray-1">Documento:</div>
                    <div class="text-body-1 text-gray-2">{{ select_vehicle.document }}</div>
                  </div>
                </div>
                <div class="flex mt-4">
                  <div class="w-3/5 md:w-1/2">
                    <div class="text-subtitle-2 text-gray-1">Categoria licencia:</div>
                    <div class="text-body-1 text-gray-2">{{ driver_licence_category }}</div>
                  </div>
                  <div class="w-2/5 md:w-1/2">
                    <div class="text-subtitle-2 text-gray-1">Fecha vencimiento:</div>
                    <div class="text-body-1" :class="validateDate(driver_licence_date) ? 'text-danger' : 'text-gray-2'">
                      {{ driver_licence_date }}
                    </div>
                  </div>
                </div>
                <div class="flex mt-4">
                  <div class="w-3/5 md:w-3/5">
                    <div class="text-subtitle-2 text-gray-1">Marca:</div>
                    <div class="text-body-1 text-gray-2">{{ vehicle_brand }}</div>
                  </div>
                  <div class="w-2/5 md:w-2/5">
                    <div class="text-subtitle-2 text-gray-1">Modelo:</div>
                    <div class="text-body-1 text-gray-2">{{ vehicle_model }}</div>
                  </div>
                </div>
                <div class="flex mt-4">
                  <div class="w-3/5 md:w-3/5">
                    <div class="text-subtitle-2 text-gray-1">SOAT:</div>
                    <div class="text-body-1 text-gray-2">{{ vehicle_soat }}</div>
                  </div>
                  <div class="w-2/5 md:w-2/5">
                    <div class="text-subtitle-2 text-gray-1">Tecnomecanica:</div>
                    <div class="text-body-1 text-gray-2">{{ vehicle_tecno }}</div>
                  </div>
                </div>
                <div class="flex mt-4">
                  <div class="w-3/5 pr-2">
                    <div class="text-subtitle-2 text-gray-1 mt-4">Flota:</div>
                    <div class="text-body-1 text-gray-2">{{ select_vehicle.fleet }}</div>
                  </div>
                  <v-form
                      class="w-2/5"
                      ref="form"
                      v-model="valid"
                      lazy-validation
                  >
                    <div>
                      <div class="text-subtitle-2 text-gray-1 mt-4">Kilometraje:</div>
                      <div class="text-body-1 text-gray-2">
                        <v-text-field label="Ingrese el kilometraje"
                                      v-model="current_mileage"
                                      :rules="rules"
                                      hide-details="auto">
                        </v-text-field>
                      </div>
                    </div>
                  </v-form>
                </div>
                <div class="w-full text-center my-4">
                  <v-btn
                      v-if="!generate_inspection"
                      :disabled="!valid"
                      color="primary"
                      class="ma-2 white--text"
                      @click="createInspection">
                    Generar inspección
                  </v-btn>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Inspección</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-if="generate_inspection && id_inspection" class="w-full mx-4 my-4">
                  <div class="my-4 mx-auto flex justify-center">
                    <span class="text-caption text-danger mx-4">
                      Prioridad Alta
                    </span> |
                    <span class="text-caption text-orange-dark mx-4">
                      Prioridad Media
                    </span>|
                    <span class="text-caption text-warning-2 mx-4">
                      Prioridad Baja
                    </span>
                  </div>
                  <div class="md:flex md:flex-wrap">
                    <div v-for="(item,index) in inspection_items" :key="index"
                         class="border-b border-primary w-full md:w-1/2 px-4 my-4">
                      <div class="flex">
                        <div class="w-1/2">
                          <div class="w-full text-body2" :class="getColor(item.priority)">{{ item.name }}</div>
                          <div class="w-full text-caption text-gray-2">{{ item.description }}</div>
                        </div>
                        <v-btn-toggle
                            class="mb-4 w-1/2"
                            v-model="item.state"
                            group
                            @change="changeState(item)"
                        >
                          <v-btn class="ma-2"
                                 outlined
                                 color="success"
                                 value="B">
                            Bueno
                          </v-btn>
                          <v-btn class="ma-2"
                                 outlined
                                 color="red"
                                 value="M">
                            Malo
                          </v-btn>
                        </v-btn-toggle>
                      </div>
                    </div>
                    <div class="my-6 text-center">
                      <div class="w-full text-body2 text-gray">Luego de verificado todos los items anteriores, se
                        determina
                        el cumplimiento y aprobación del vehículo
                      </div>
                      <v-btn-toggle
                          class="mb-4"
                          v-model="pass_inspection"
                          group
                      >
                        <v-btn class="ma-2"
                               outlined
                               color="success"
                               value="S">
                          SI
                        </v-btn>
                        <v-btn class="ma-2"
                               outlined
                               color="red"
                               value="N">
                          NO
                        </v-btn>
                      </v-btn-toggle>
                      <div class="w-full text-body2 text-gray mt-4">El conductor ha recibido folletos
                        ilustrativos de
                        apoyo: Politicas (coporativa, seguridad vial, salud, higiene, ambiente, alcohol y drogas) y
                        estandares
                        de seguridad.
                      </div>
                      <v-btn-toggle
                          class="mb-4"
                          v-model="info_inspection"
                          group
                      >
                        <v-btn class="ma-2"
                               outlined
                               color="success"
                               value="S">
                          SI
                        </v-btn>
                        <v-btn class="ma-2"
                               outlined
                               color="red"
                               value="N">
                          NO
                        </v-btn>
                      </v-btn-toggle>
                      <div class="w-full">
                        <v-btn
                            color="primary"
                            class="ma-2"
                            @click="sendInspection">
                          Finalizar
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
      <div v-else-if="item_selected && item_selected._id === '6213e61ff6380c07458c13df'"
           class="my-8 flex-1 justify-center px-4">
        <v-autocomplete
            class="w-full md:w-2/5 mx-auto "
            clearable
            v-model="select_user"
            :items="users_data"
            label="Selecciona el usuario">
          <template v-slot:selection="data">
            {{ data.item.name }}
          </template>
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
              <v-list-item-subtitle v-html="data.item.document"></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
        <div v-if="select_user">
          <v-expansion-panels
              v-model="panel"
              multiple
          >
            <v-expansion-panel>
              <v-expansion-panel-header>Información del usuario</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="flex mt-4">
                  <div class="w-3/5 md:w-1/2">
                    <div class="text-subtitle-2 text-gray-1">Nombre:</div>
                    <div class="text-body-1 text-gray-2">{{ select_user.name }}</div>
                  </div>
                  <div class="w-2/5 md:w-1/2">
                    <div class="text-subtitle-2 text-gray-1">Documento:</div>
                    <div class="text-body-1 text-gray-2">{{ select_user.document }}</div>
                  </div>
                </div>
                <div class="flex mt-4">
                  <div class="w-3/5 md:w-1/2">
                    <div class="text-subtitle-2 text-gray-1">Telefono:</div>
                    <div class="text-body-1 text-gray-2">{{ select_user.position }}</div>
                  </div>
                  <div class="w-2/5 md:w-1/2">
                    <div class="text-subtitle-2 text-gray-1">Cargo:</div>
                    <div class="text-body-1 text-gray-2">{{ select_user.phone }}</div>
                  </div>
                </div>
                <div class="w-full text-center my-4">
                  <v-btn
                      v-if="!generate_inspection"
                      :disabled="!valid"
                      color="primary"
                      class="ma-2 white--text"
                      @click="createInspection">
                    Generar inspección
                  </v-btn>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Inspección</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-if="generate_inspection && id_inspection" class="w-full mx-4 my-4">
                  <div class="text-body2 text-gray-2">ACTUALMENTE PRESENTA SÍNTOMAS RESPIRATORIOS COMO LOS DESCRITOS A CONTINUACIÓN:</div>
                  <div class="md:flex md:flex-wrap">
                    <div v-for="(item,index) in inspection_items" :key="index"
                         class="border-b border-primary w-full md:w-1/2 px-4 my-4">
                      <div class="flex">
                        <div class="w-1/2">
                          <div class="w-full text-body2" :class="getColor(item.priority)">{{ item.name }}</div>
                          <div class="w-full text-caption text-gray-2">{{ item.description }}</div>
                        </div>
                        <v-btn-toggle
                            class="mb-4 w-1/2"
                            v-model="item.state"
                            group
                            @change="changeState(item)"
                        >
                          <v-btn class="ma-2"
                                 outlined
                                 color="success"
                                 value="S">
                            SI
                          </v-btn>
                          <v-btn class="ma-2"
                                 outlined
                                 color="red"
                                 value="N">
                            NO
                          </v-btn>
                        </v-btn-toggle>
                      </div>
                    </div>
                    <div class="my-6 text-center">
                      <div class="w-full text-body2 text-gray">
                        ¿ESTUVO EN CONTACTO CON PERSONAS CATALOGADAS COMO CASOS SOSPECHOSOS PARA COVID-19 EN LOS ÚLTIMOS 30 DÍAS (FAMILIARES,AMIGOS,CONOCIDO)
                      </div>
                      <v-btn-toggle
                          class="mb-4"
                          v-model="contact_covid"
                          group
                      >
                        <v-btn class="ma-2"
                               outlined
                               color="success"
                               value="S">
                          SI
                        </v-btn>
                        <v-btn class="ma-2"
                               outlined
                               color="red"
                               value="N">
                          NO
                        </v-btn>
                      </v-btn-toggle>
                      <div class="w-full text-body2 text-gray mt-4">
                        ALGUNA DE LAS PERSONAS CON LA CUAL CONVIVE HA SIDO DIAGNOSTICADO CON COVID-19
                      </div>
                      <v-btn-toggle
                          class="mb-4"
                          v-model="diagnose_covid"
                          group
                      >
                        <v-btn class="ma-2"
                               outlined
                               color="success"
                               value="S">
                          SI
                        </v-btn>
                        <v-btn class="ma-2"
                               outlined
                               color="red"
                               value="N">
                          NO
                        </v-btn>
                      </v-btn-toggle>
                      <div class="w-full text-body2 text-gray mt-4">
                        ¿LE HAN PRACTICADO PRUEBAS DE COVID-19 EN LOS ÚLTIMOS 40 DÍAS?
                      </div>
                      <v-btn-toggle
                          class="mb-4"
                          v-model="tests_covid"
                          group
                      >
                        <v-btn class="ma-2"
                               outlined
                               color="success"
                               value="S">
                          SI
                        </v-btn>
                        <v-btn class="ma-2"
                               outlined
                               color="red"
                               value="N">
                          NO
                        </v-btn>
                      </v-btn-toggle>
                      <div class="w-full text-body2 text-gray mt-4">
                        SI SU RESPUESTA ANTERIOR FUE "SI"INDIQUE SU RESULTADO
                      </div>
                      <v-select
                          class="px-4"
                          v-model="test_result_covid"
                          :items="test_result_covid_options"
                          label=""
                          item-text="name"
                          item-value="key"
                          clearable
                      >
                        <v-icon
                            slot="prepend"
                        >
                          mdi-sort-bool-descending-variant
                        </v-icon>
                      </v-select>
                      <div class="w-full text-body2 text-gray mt-4">
                        ¿ACTUALMENTE SE ENCUENTRA CON INCAPACIDAD?
                      </div>
                      <v-btn-toggle
                          class="mb-4"
                          v-model="incapacity_covid"
                          group
                      >
                        <v-btn class="ma-2"
                               outlined
                               color="success"
                               value="S">
                          SI
                        </v-btn>
                        <v-btn class="ma-2"
                               outlined
                               color="red"
                               value="N">
                          NO
                        </v-btn>
                      </v-btn-toggle>
                      <div class="w-full text-body2 text-gray mt-4">
                        SI SU RESPUESTA ANTERIOR FUE "SI" INDIQUE CUAL ES EL DIAGNOSTICO Y TIEMPO DE INCAPACIDAD.
                      </div>
                      <v-text-field label=""
                                    v-model="incapacity_details_covid"
                                    hide-details="auto">
                      </v-text-field>
                      <div class="w-full">
                        <v-btn
                            color="primary"
                            class="ma-2"
                            @click="sendInspection">
                          Finalizar
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>
    </div>

    <v-dialog
        v-model="view_report_novelty"
        max-width="90%"
    >
      <v-card class="w-full p-4">
        <v-card-title>
          <span class="headline">Generar Novedad</span>
        </v-card-title>
        <div class="text-caption text-primary mb-4">
          Fecha limite para accion correctiva
        </div>
        <v-row justify="center">
          <v-date-picker v-model="novelty_date"></v-date-picker>
        </v-row>
        <v-textarea
            class="my-6"
            label="Observacion"
            v-model="novelty_description"
        ></v-textarea>
        <div class="my-6">
          <div class="text-caption text-primary mb-4">
            Foto de la novedad
          </div>
          <input v-if="!novelty_image" type="file" id="file" accept="image/*" capture
                 @change="previewFiles">
          <img v-else :src="novelty_image" alt="Novedad">
        </div>
        <v-card-actions class="mt-6">
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              :disabled="loading || !novelty_image || !novelty_description || !novelty_date"
              @click="saveNovelty"

          >
            Guardar
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="view_report_novelty = false"
          >
            Cerrar
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>

import moment from "moment";
import store from "@/store";
import router from "@/router";

export default {
  name: "CreateInspection",
  data() {
    return {
      loading: false,
      vehicles_data: [],
      select_vehicle: null,
      inspection_items: [],
      vehicle_brand: null,
      vehicle_model: null,
      generate_inspection: false,
      current_mileage: null,
      rules: [
        value => !!value || 'Requerido.',
      ],
      id_inspection: null,
      valid: true,
      current_date: moment(new Date(Date.now())).utc().format('YYYY/MM/DD'),
      inspection_details: [],
      index_view: 0,
      item_state: null,
      item_novelty: null,
      item_priority: null,
      item_id: null,
      disabled_next: false,
      novelty_dialog: false,
      activePicker: null,
      novelty_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      novelty_description: null,
      novelty_id: null,
      view_report_novelty: false,
      novelty_image: null,
      driver_licence: null,
      driver_licence_date: null,
      driver_licence_category: null,
      item_name: null,
      vehicle_soat: null,
      vehicle_tecno: null,
      pass_inspection: null,
      info_inspection: null,
      panel: [0],
      types: [],
      selected_type: null,
      item_selected: null,
      users_data: [],
      select_user: null,
      incapacity_details_covid: null,
      incapacity_covid: null,
      test_result_covid: null,
      test_result_covid_options: [
        {key: 'positive', name: 'Positivo'},
        {key: 'negative', name: 'Negativo'},
        {key: 'nothing', name: 'No Concluyente'},
        {key: 'pending', name: 'Pendiente por resultado'},
        {key: 'N/A', name: 'No Aplica'},
      ],
      tests_covid: null,
      diagnose_covid: null,
      contact_covid: null,
    }
  },
  mounted() {
    this.loadModels()
  },
  methods: {
    loadModels() {
      this.loading = true

      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }

      let url = `${process.env.VUE_APP_API_URL}/api/sutech/getItemsAndTypesByBrand/`

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.ok) {
              data.itemAndType.forEach((item) => {
                this.types.push(item.typeCheck)
              })
            }
          })
          .finally(() => this.loading = false)
    },
    loadVehicles() {
      this.loading = true

      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }

      let url = `${process.env.VUE_APP_API_URL}/api/sutech/getPersonsByWidetech`

      this.vehicles_data = []

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.ok) {
              if (data.peoples.length) {
                this.vehicles_data = data.peoples.filter(item => item.fleet !== 'N/A')
              }
            } else if (data.status === '401') {
              localStorage.removeItem('sutech_token')
              store.commit('setMenu', false)
              router.push('login')
            }
          })
          .catch((error) => {
            console.log('error ' + error)
          })
          .finally(() => this.loading = false)
    },
    loadUsers() {
      this.loading = true

      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }

      let url = `${process.env.VUE_APP_API_URL}/api/sutech/getUsersByBrands`

      this.users_data = []

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.ok) {
              if (data.usuarios.length) {
                this.users_data = data.usuarios
              }
            } else if (data.status === '401') {
              localStorage.removeItem('sutech_token')
              store.commit('setMenu', false)
              router.push('login')
            }
          })
          .catch((error) => {
            console.log('error ' + error)
          })
          .finally(() => this.loading = false)
    },
    selectType() {
      const item = this.types.filter((items) => {
        return items._id === this.selected_type
      })

      this.item_selected = item[0]
      this.loadItems(this.item_selected._id)
      if (this.item_selected && this.item_selected.isVehicle) this.loadVehicles()
      else if (this.item_selected && this.item_selected._id === '6213e61ff6380c07458c13df') this.loadUsers()
    },
    loadItems(type_check) {
      this.loading = true

      let config = {
        headers: {
          token: localStorage.sutech_token,
          typeCheck: type_check,
        }
      }

      let url = `${process.env.VUE_APP_API_URL}/api/sutech/getItemsByTypeAndBrands`

      this.inspection_items = []

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.ok) {
              if (data.checkItems.length) {
                data.checkItems.forEach(item => {
                  this.inspection_items.push({
                    "name": item.name,
                    "description": item.description,
                    "state": this.item_selected && this.item_selected._id === '6213e61ff6380c07458c13df' ? 'S' : 'B',
                    "priority": item.priority,
                    "checkItem": item._id
                  })
                })
              }
            } else if (data.status === '401') {
              localStorage.removeItem('sutech_token')
              store.commit('setMenu', false)
              router.push('login')
            }
          })
          .catch((error) => {
            console.log('error ' + error)
          })
          .finally(() => this.loading = false)
    },
    getColor(priority) {
      switch (priority) {
        case 'ALTA':
          return 'text-danger'
        case 'MEDIA':
          return 'text-orange-dark'
        case 'BAJA':
          return 'text-warning-2'
      }
    },
    loadVehicleInfo() {
      if (this.select_vehicle) {
        this.loading = true
        const body = this.$qs.stringify({
          plate: this.select_vehicle.plate,
        })

        const body2 = this.$qs.stringify({
          document: this.select_vehicle.document,
        })

        let config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            token: localStorage.sutech_token,
          }
        }

        this.$axios.post(`${process.env.VUE_APP_API_URL}/api/sutech/getRuntOne`, body, config)
            .then(({data}) => {
              if (data.ok) {
                this.vehicle_brand = `${data.InfoMobile.marca} - ${data.InfoMobile.linea}`
                this.vehicle_model = data.InfoMobile.aaaaModelo
                this.vehicle_soat = data.runtMobile.soat.fechaVencimiento
                this.vehicle_tecno = data.runtMobile.revision.fechaVencimiento
              } else if (data.status === '401') {
                localStorage.removeItem('sutech_token')
                store.commit('setMenu', false)
                router.push('login')
              }
            })
            .catch((error) => {
              console.log('error ' + error)
            })
            .finally(() => this.loading = false)

        this.$axios.post(`${process.env.VUE_APP_API_URL}/api/sutech/getSimitLicencesOne`, body2, config)
            .then(({data}) => {
              if (data.ok) {

                let licence = data.simitDriver.licencias.find((item) => {
                  return item.categoria === 'A2'
                })
                this.driver_licence = licence.noLicencia
                this.driver_licence_category = licence.categoria
                this.driver_licence_date = licence.fechaVencimiento
              } else if (data.status === '401') {
                localStorage.removeItem('sutech_token')
                store.commit('setMenu', false)
                router.push('login')
              }
            })
            .catch((error) => {
              console.log('error ' + error)
            })
            .finally(() => this.loading = false)
      }
    },
    createInspection() {
      this.loading = true
      const body = this.$qs.stringify({
        isVehicle: this.item_selected.isVehicle,
        driverName: this.select_user.name,
        driverDocument: this.select_user.document,
        plateVehicle: this.select_vehicle ? this.select_vehicle.plate : 'N/A',
        currentMileage: this.select_vehicle ? this.current_mileage : 'N/A',
        vehicleBrand: this.select_vehicle ? this.vehicle_brand : 'N/A',
        vehicleModel: this.select_vehicle ? this.vehicle_model : 'N/A',
        nameFleet: this.select_vehicle ? this.select_vehicle.fleet : 'N/A',
        typeInspections: this.item_selected.name,
        currentDate: this.current_date
      })

      let config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          token: localStorage.sutech_token,
        }
      }

      this.$axios.post(`${process.env.VUE_APP_API_URL}/api/sutech/addInspectionValidation`, body, config)
          .then(({data}) => {
            if (data.ok) {
              this.generate_inspection = true
              this.id_inspection = data.inspectionsDB._id
              this.panel = [1]
            }
          })
          .catch((error) => {
            console.log('error ' + error)
          })
          .finally(() => this.loading = false)
    },
    changeState(item) {
      if (item.state == 'M' && this.item_selected && this.item_selected.isVehicle) {
        this.item_name = item.name
        this.item_priority = item.priority
        this.item_id = item.checkItem
        this.view_report_novelty = true
      }
    },
    saveNovelty() {
      if (this.novelty_description && this.novelty_date) {
        this.loading = true
        const body = this.$qs.stringify({
          currentDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          expirationDate: this.novelty_date,
          plateVehicle: this.select_vehicle.plate,
          namePeople: this.$store.state.user.name,
          typeNovelties: 'MOTOS',
          description: this.novelty_description,
          priority: this.item_priority,
          imageUrl: this.novelty_image,
          checkItem: this.item_id,
          inspections: this.id_inspection,
        })

        let config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            token: localStorage.sutech_token,
          }
        }

        this.$axios.post(`${process.env.VUE_APP_API_URL}/api/sutech/addNovelties`, body, config)
            .then(({data}) => {
              if (data.ok) {
                this.novelty_id = data.noveltiesDB._id
                this.view_report_novelty = false
                this.novelty_date = null
                this.novelty_image = null
                this.novelty_id = null
                this.novelty_description = null
              }
            })
            .catch((error) => {
              console.log('error ' + error)
            })
            .finally(() => this.loading = false)
      }
    },
    previewFiles(event) {
      this.loading = true
      const formData = new FormData()
      formData.append('image', event.target.files[0])

      let config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          token: localStorage.sutech_token,
        }
      }

      this.$axios.post(`${process.env.VUE_APP_API_URL}/api/sutech/uploadNoveltiesFleetCheck`, formData, config)
          .then(({data}) => {
            if (data.ok) {
              this.novelty_image = data.urlImage
            }
          })
          .catch((error) => {
            console.log('error ' + error)
          })
          .finally(() => this.loading = false)
    },
    sendInspection() {
      this.loading = true

      const body = this.$qs.stringify({
        inspections: this.id_inspection,
        items: JSON.stringify(this.inspection_items),
        pass_inspection: this.pass_inspection,
        info_inspection: this.info_inspection,
        incapacity_details_covid: this.incapacity_details_covid ? this.incapacity_details_covid : null,
        incapacity_covid: this.incapacity_covid ? this.incapacity_covid : null,
        test_result_covid: this.test_result_covid ? this.test_result_covid : null,
        tests_covid: this.tests_covid ? this.tests_covid : null,
        diagnose_covid: this.diagnose_covid ? this.diagnose_covid : null,
        contact_covid: this.contact_covid ? this.contact_covid : null,
      })

      let config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          token: localStorage.sutech_token,
        }
      }

      this.$axios.post(`${process.env.VUE_APP_API_URL}/api/sutech/addReports`, body, config)
          .then(({data}) => {
            if (data.ok) {
              this.generate_inspection = false
              this.id_inspection = null
              this.select_vehicle = null
              this.index_view = 0
              this.panel = [0]
              this.current_mileage = null
            }
          })
          .catch((error) => {
            console.log('error ' + error)
          })
          .finally(() => this.loading = false)
    },
    validateDate(date) {
      return moment(date, 'DD/MM/YYYY', true).format() < moment(Date.now(), true).format()
    },
  },
}
</script>
