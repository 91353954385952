<template>
  <div>
    <highcharts class="hc" :options="chartOptions" ref="chart"></highcharts>
  </div>
</template>

<script>
import Highcharts from 'highcharts'
import exportingInit from 'highcharts/modules/exporting'

exportingInit(Highcharts)
export default {
  props: {
    chartdata: null,
    data_text: null,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'column'
        },
        title: {
          text: null
        },
        accessibility: {
          announceNewData: {
            enabled: true
          }
        },
        xAxis: {
          type: 'category'
        },
        yAxis: {
          title: {
            text: null
          },
          stackLabels: {
            enabled: true,
          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
            }
          },
          bar: {
            dataLabels: {
              enabled: true
            }
          }
        },
        exporting: {
          enabled: true
        },
        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b>'
        },
        series: [
          {
            name: null,
            colorByPoint: true,
            data: this.chartdata
          }
        ],
      }
    }
  },
  created() {
    this.chartOptions.title.text = this.data_text ? this.data_text.title : 'Inspecciones por mes'
    this.chartOptions.yAxis.title = this.data_text ? this.data_text.ytitle : 'Cantidad de inspecciones'
    this.chartOptions.series[0].name = this.data_text ? this.data_text.xtitle : 'Inspecciones por meses'
  }
};
</script>