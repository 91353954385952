<template>
  <div>
    <highcharts class="hc" :options="chartOptions" ref="chart"></highcharts>
  </div>
</template>

<script>
export default {
  props:{
    chartdata: null
  },
  data() {
    return {
      chartOptions: {
        title: {
          text: 'Cantidad de novedades por mes'
        },
        yAxis: {
          title: {
            text: 'Número de novedades'
          }
        },
        xAxis: {
          title: {
            text: 'Meses'
          },
          categories: this.chartdata.labels
        },

        series: [
          {
            name: 'Alta',
            data: this.chartdata.data_high,
            color: '#ff0000'
          }, {
            name: 'Media',
            data: this.chartdata.data_medium,
            color: '#e5be01'
          }, {
            name: 'Baja',
            data: this.chartdata.data_low,
            color: '#008f39'
          },
        ]
      }
    };
  }
};
</script>