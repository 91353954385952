<template>
  <div>
    <sub-header name="Tipos de inspección" @newRegister="newRegister" :is_master="is_master"></sub-header>
    <div v-if="loading">
      <loading></loading>
    </div>
    <div v-else-if="data.length" class="flex flex-wrap">
      <div class="w-full md:w-1/3 px-2 my-2 md:my-0" :key="index" v-for="(item,index) in data">
        <v-card shadow="always" class="relative rounded-md">
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  class="absolute top-0 right-0 pt-2 pr-1"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="$store.state.user_permissions.includes('fleetVehicleTypePermission')" link>
                <v-list-item-title @click="editVehicleType(item, index)">Editar</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="$store.state.user_permissions.includes('fleetVehicleTypePermission')" link>
                <v-list-item-title @click="deleteItem(item, index)">Eliminar</v-list-item-title>
              </v-list-item>
              <v-list-item link>
                <v-list-item-title @click="viewRelateForm(item)">Relacionar Items</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <div class="flex py-2 pr-2">
            <div class="w-1/2 text-center self-center">
              <img v-if="item.typeCheck.imageUrl" :src="item.typeCheck.imageUrl"
                   style="height: 180px"
              />
              <img v-else src="@/assets/images/default_car.png" style="height: 180px"/>
            </div>
            <div class="w-1/2 self-center">
              <div class="text-primary h5">{{ item.typeCheck.name }}</div>
              <div class="text-gray-3 text-body2 my-4">{{ item.typeCheck.description }}</div>
              <div class="text-gray-3 text-body2 text-uppercase"><span
                  class="text-secondary text-capitalize">Estado: </span> {{
                  item.typeCheck.state ? $t('active') : $t('inactive')
                }}
              </div>
            </div>
          </div>
        </v-card>
      </div>
    </div>
    <empty-state v-else/>
    <v-dialog
        v-model="viewCreateForm"
        max-width="600px"
    >
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline">Nuevo tipo de vehículo</span>
        </v-card-title>
        <div class="mx-6">
          <v-text-field
              :rules="rules"
              label="Nombre"
              v-model="vehicle_type_name"
          >
            <v-icon
                slot="prepend"
            >
              mdi-car-key
            </v-icon>
          </v-text-field>
          <v-text-field
              :rules="rules"
              label="Descripción"
              v-model="vehicle_type_description"
          >
            <v-icon
                slot="prepend"
            >
              mdi-car-info
            </v-icon>
          </v-text-field>
          <v-file-input
              v-model="vehicle_type_image"
              accept=".png,.jpg,.jpge"
              label="Imagen"
          ></v-file-input>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="viewCreateForm = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
              v-if="item_edit"
              color="primary"
              text
              @click="updateVehicleType"
              :disabled="loading"
          >
            {{ $t('save') }}
          </v-btn>
          <v-btn v-else
                 color="primary"
                 text
                 @click="saveVehicleType"
                 :disabled="loading"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="selected_vehicle_type">
      <v-dialog
          v-model="view_relate_form"
          max-width="600px"
      >
        <v-card :loading="loading">
          <v-card-title>
            <span class="headline">Relacionar items - {{ selected_vehicle_type.name }}</span>
          </v-card-title>
          <div class="mx-4" :key="index" v-for="(item,index) in check_items_all">
            <v-checkbox
                v-model="selected_items"
                :label="item.name"
                :value="item._id"
            ></v-checkbox>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="view_relate_form = false"
            >
              {{ $t('cancel') }}
            </v-btn>
            <v-btn
                color="primary"
                text
                @click="realtedVehicleItems"
                :disabled="loading"
            >
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>

export default {
  name: "VehicleType",
  data() {
    return {
      loading: false,
      viewCreateForm: false,
      data: [],
      rules: [
        value => !!value || 'Este campo es obligatorio',
      ],
      vehicle_type_name: null,
      vehicle_type_description: null,
      vehicle_type_image: null,
      item_edit: null,
      view_relate_form: false,
      selected_vehicle_type: null,
      check_items: null,
      selected_items: [],
      check_items_all: null,
      is_master: false,
    }
  },
  mounted() {
    this.loadData()
    this.loadItems()
    if (this.$store.state.user_permissions.includes('fleetVehicleTypePermission')) this.is_master = true
  },
  methods: {
    newRegister() {
      this.viewCreateForm = true;
    },
    loadData() {
      this.loading = true

      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }

      this.$axios.get(`${process.env.VUE_APP_API_URL}/api/sutech/getItemsAndTypesByBrand/`, config)
          .then(({data}) => {
            if (data.ok) {
              this.data = data.itemAndType
              this.loading = false
            } else {
              this.loading = false
            }
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    saveVehicleType() {
      if (this.vehicle_type_name && this.vehicle_type_description) {
        this.loading = true
        const body = this.$qs.stringify({
          name: this.vehicle_type_name,
          description: this.vehicle_type_description,
          image: this.vehicle_type_image,
          state: true
        })

        let config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            token: localStorage.sutech_token,
          }
        }

        this.$axios.post(`${process.env.VUE_APP_API_URL}/api/sutech/addCheckTypeAndImage/`, body, config)
            .then(({data}) => {
              if (data.ok) {
                this.data.push(data.typeInspecDB)
                this.viewCreateForm = false
                this.loading = false
              } else {
                this.loading = false
              }
            })
            .catch((error) => {
              this.loading = false
              this.viewCreateForm = false
              console.log('error ' + error)
            })
      }
    },
    editVehicleType(item, index) {
      this.item_edit = item._id
      this.item_edit_index = index
      this.vehicle_type_name = item.name
      this.vehicle_type_description = item.description
      this.viewCreateForm = true;
    },
    updateVehicleType() {
      if (this.vehicle_type_name && this.vehicle_type_description) {
        this.loading = true
        const body = this.$qs.stringify({
          name: this.vehicle_type_name,
          description: this.vehicle_type_description,
          imageUrl: this.vehicle_type_image ?? null
        })

        let config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            token: localStorage.sutech_token,
          }
        }
        this.$axios.put(`${process.env.VUE_APP_API_URL}/api/sutech/updateModelsById/id=${this.item_edit}`, body, config)
            .then(({data}) => {
              if (data.ok) {
                this.data.splice(this.item_edit_index, 1);
                this.data.push(data.typeDB)
                this.viewCreateForm = false
                this.loading = false
              } else {
                this.loading = false
              }
            })
            .catch((error) => {
              this.loading = false
              this.viewCreateForm = false
              console.log('error ' + error)
            })
      }
    },
    deleteItem(item, index) {
      this.loading = true
      let config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          token: localStorage.sutech_token,
        }
      }

      this.$axios.delete(`${process.env.VUE_APP_API_URL}/api/sutech/deleteTypeInspectionById/id=${item._id}`, config)
          .then(({data}) => {
            if (data.ok) {
              this.loading = false
              this.data.splice(index, 1);
            } else {
              this.loading = false
            }
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    viewRelateForm(item) {
      this.check_items = null
      this.selected_items = []
      this.loading = true
      this.selected_vehicle_type = item
      let config = {
        headers: {
          token: localStorage.sutech_token,
          typeCheck: item.typeCheck._id
        }
      }
      this.$axios.get(`${process.env.VUE_APP_API_URL}/api/sutech/getItemsByTypeAndBrands/`, config)
          .then(({data}) => {
            if (data.ok) {
              this.check_items = data.checkItems
              if (this.check_items){
                this.check_items.forEach( item => { this.selected_items.push(item._id) })
              }
              this.loading = false
              this.view_relate_form = true
            } else {
              this.loading = false
            }
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    realtedVehicleItems(){
      this.loading = true
      const body = this.$qs.stringify({
        itemsCheck: this.selected_items.toString(),
        typeCheck: this.selected_vehicle_type.typeCheck._id,
        idRelation: this.selected_vehicle_type._id
      })

      let config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          token: localStorage.sutech_token,
        }
      }

      this.$axios.post(`${process.env.VUE_APP_API_URL}/api/sutech/addVariousItemToType/`, body, config)
          .then(({data}) => {
            if (data.ok) {
              this.view_relate_form = false
              this.loading = false
            } else {
              this.loading = false
            }
          })
          .catch((error) => {
            this.loading = false
            this.view_relate_form = false
            console.log('error ' + error)
          })
    },
    loadItems(){
      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }

      this.$axios.get(`${process.env.VUE_APP_API_URL}/api/sutech/getItemsCheckByBrand?start=0&limit=1000`, config)
          .then(({data}) => {
            if (data.ok) {
              this.check_items_all = data.checkItems
            }
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    }
  },
  watch: {
    viewCreateForm: function (val) {
      if (!val) {
        this.vehicle_type_name = null
        this.vehicle_type_description = null
        this.vehicle_type_image = null
        this.item_edit_index = null
        this.item_edit = null
      }
    },
  }
}
</script>

<style scoped>

</style>