<template>
  <div class="my-16">
    <img class="h-64 mb-4 mx-auto" src="@/assets/images/empty_state.png">
    <div class="text-gray-3 text-body-1 text-center">{{ $t('no_register') }}</div>
    <div class="text-primary text-body-1 text-center cursor-pointer" @click="newRegister">{{ $t('new_register') }}</div>
  </div>
</template>

<script>
export default {
  name: "EmptyState",
  methods: {
    newRegister() {
      this.$emit('newRegister')
    }
  }
}
</script>