import axios from 'axios'
import store from "@/store";
import router from "@/router";
import querystring from 'querystring'

export function loadVehicleInfo(plate, document) {
    return new Promise((resolve, reject) => {
        const body = querystring.stringify({
            plate: plate,
            document: document,
        });

        const body2 = querystring.stringify({
            document: document,
        });

        let config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                token: localStorage.sutech_token,
            }
        };

        let vehicle = null;
        let driver = null;

        Promise.all([
            axios.post(`${process.env.VUE_APP_API_URL}/api/sutech/getSimitAndRunt`, body, config),
            axios.post(`${process.env.VUE_APP_API_URL}/api/sutech/getSimitLicencesOne`, body2, config)
        ]).then(([response1, response2]) => {
            const data1 = response1.data;
            const data2 = response2.data;

            if (data1.ok) {
                vehicle = {
                    vehicle_brand: `${data1.data.InfoMobile.data.marca} - ${data1.data.InfoMobile.data.linea}`,
                    vehicle_model: data1.data.InfoMobile.data.aaaaModelo,
                    vehicle_soat: data1.data.InfoMobileRunt.soat.fechaVencimiento,
                    vehicle_soat_number: data1.data.InfoMobileRunt.soat.noSoat,
                    vehicle_tecno: data1.data.InfoMobileRunt.revision.fechaVencimiento,
                    vehicle_tecno_number: data1.data.InfoMobileRunt.revision.noRevision,
                };
            } else if (data1.status === '401') {
                localStorage.removeItem('sutech_token')
                store.commit('setMenu', false)
                router.push('login')
            }

            if (data2.ok) {
                driver = {
                    driver_licence: data2.simitDriver.licencias[0].noLicencia,
                    driver_licence_category: data2.simitDriver.licencias[0].categoria,
                    driver_licence_date: data2.simitDriver.licencias[0].fechaVencimiento,
                };
            } else if (data2.status === '401') {
                localStorage.removeItem('sutech_token')
                store.commit('setMenu', false)
                router.push('login')
            }

            resolve({driver, vehicle});
        }).catch((error) => {
            reject(error);
        });
    });
}