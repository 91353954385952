<template>
  <div>
    <sub-header name="Novedades" @newRegister="newRegister" :is_master="false"></sub-header>
    <div v-if="loading">
      <loading></loading>
    </div>
    <div v-else class="flex flex-wrap">
      <div class="w-full border border-primary border-solid mx-4 my-4">
        <div class="md:flex items-center">
          <v-select
              class="px-4"
              v-model="search_status"
              :items="status_items"
              label="Estado"
              clearable
              item-text="label"
              item-value="value"
          >
            <v-icon
                slot="prepend"
            >
              mdi-list-status
            </v-icon>
          </v-select>
          <v-select
              class="px-4"
              v-model="search_priority"
              :items="priority_items"
              label="Prioridad"
              clearable
          >
            <v-icon
                slot="prepend"
            >
              mdi-sort-bool-descending-variant
            </v-icon>
          </v-select>
          <v-text-field
              class="px-4"
              label="Placa"
              v-model="search_plate"
          >
            <v-icon
                slot="prepend"
            >
              mdi-car
            </v-icon>
          </v-text-field>
          <v-col
              cols="12"
              sm="6"
              md="4"
          >
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="dates"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateRangeText"
                    label="Fecha"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="dates"
                  no-title
                  scrollable
                  range
              >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(dates)"
                >
                  Aceptar
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-btn
              dark
              color="primary"
              text
              @click="loadData"
          >
            Buscar
          </v-btn>
          <v-btn
              dark
              color="primary"
              text
              @click="clearSearch"
          >
            Limpiar
          </v-btn>
        </div>
      </div>
      <div class="my-4 mx-4 flex">
        <download-excel
            class="btn btn-default"
            :data="json_data"
            :fields="json_fields"
            :before-generate="loadExcelData"
            worksheet="My Worksheet"
            name="ExportSimit.xls"
        >
          <v-btn
              class="mx-2"
              dark
              large
              color="success"
          >
            <v-icon dark>
              mdi-file-excel
            </v-icon>
          </v-btn>
        </download-excel>
        <v-btn
            class="mx-2"
            dark
            large
            color="error"
            @click="exportPdf()"
        >
          <v-icon dark>
            mdi-file-pdf-box
          </v-icon>
        </v-btn>
      </div>
      <v-data-table
          v-if="data.length"
          :headers="headers"
          :items="data"
          class="elevation-1 w-full mx-4 my-4"
      >
        <template v-slot:item.expirationDate="{ item }">
          <div
              :class="validateDate(item.expirationDate) ? 'text-danger' : 'text-primary'"
              v-text="item.expirationDate"></div>
        </template>
        <template v-slot:item.description="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div class="text-body2 text-gray-3 mx-4"
                   v-bind="attrs"
                   v-on="on">
                {{ item.description > 50 ? item.description.substr(0, 50) + '...' : item.description }}
              </div>
            </template>
            <span>{{ item.description }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.priority="{ item }">
          <div :class="getColorStatus(item.priority)">
            {{ item.priority }}
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="viewDetail(item)"
          >
            mdi-information-outline
          </v-icon>
          <v-icon
              v-if="!item.state"
              small
              class="mr-2 text-danger"
              @click="attendNoveltyShow(item)"
          >
            mdi-chat-alert
          </v-icon>
        </template>
      </v-data-table>
      <div class="w-full" v-else>
        <empty-state @newRegister="newRegister"/>
      </div>
    </div>
    <div v-if="item_detail">
      <v-dialog
          v-model="view_detail_novelty"
          max-width="800px"
      >
        <v-card>
          <v-card-title>
            <span class="headline text-primary">Detalle de novedad</span>
          </v-card-title>
          <div class="mx-6">
            <div class="w-full flex justify-center text-center">
              <div class="w-full md:flex mx-4 my-2">
                <div class="w-full md:w-1/2 pr-2">
                  <div class="text-primary text-body-1">Novedad</div>
                  <img :src="item_detail.imageUrl" style="max-height: 370px">
                </div>
                <div class="w-full md:w-1/2 pr-2">
                  <div class="text-primary text-body-1">Solución</div>
                  <img :src="item_detail.novelty_image" style="max-height: 370px">
                </div>
              </div>
            </div>
            <div class="w-full  mx-4 my-2">
              <div class="text-gray-3 text-body2"><span
                  class="text-primary">Descripcion item: </span><span class="text-gray-2">({{
                  item_detail.checkItem.name
                }})</span> {{ item_detail.checkItem.description }}
              </div>
            </div>
            <div class="md:flex mx-4 my-2">
              <div class="w-full md:w-1/2">
                <div class="text-body2 text-primary">
                  {{ item_detail.plateVehicle }}
                </div>
              </div>
              <div class="w-full md:w-1/2">
                <div :class="validateDate(item_detail.expirationDate) ? 'text-danger' : 'text-primary'"
                     class="text-body2">
                  {{ item_detail.expirationDate }}
                </div>
              </div>
            </div>
            <div class="md:flex mx-4 my-2">
              <div class="w-full md:w-1/2">
                <div :class="getColorStatus(item_detail.priority)" class="text-body2"><span
                    class="text-primary">Prioridad: </span>{{ item_detail.priority }}
                </div>
              </div>
              <div class="w-full md:w-1/2">
                <div class="text-gray-3 text-body2"><span
                    class="text-primary">Atendida: </span>{{ item_detail.attended }}
                </div>
              </div>
            </div>
            <div class="w-full text-gray-2 text-body2 mx-4 my-2">
              <div class="text-primary">Descripcion novedad</div>
              {{ item_detail.description }}
            </div>
          </div>
          <v-card-actions class="mt-6">
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="view_detail_novelty = false"
            >
              Cerrar
            </v-btn>
            <v-btn
                color="primary"
                text
                @click="pdfNovelty"
                :disabled="loading"
            >
              Generar PDF
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          :filename="`InspectionPDF-${item_detail.plateVehicle}`"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="90%"
          ref="html2Pdf"
      >
        <section slot="pdf-content">
          <img src="../assets/images/Logo-270.png" alt="icon" class="">
          <div class="w-full mx-8">
            <div class="w-full h3 text-primary w-full text-center my-6">Detalle de novedad</div>
            <div class="flex mx-4 my-2">
              <div class="w-1/2">
                <div class="text-body2 text-primary">
                  {{ item_detail.plateVehicle }}
                </div>
              </div>
              <div class="w-1/2">
                <div :class="validateDate(item_detail.expirationDate) ? 'text-danger' : 'text-primary'"
                     class="text-body2">
                  {{ item_detail.expirationDate }}
                </div>
              </div>
            </div>
            <div class="flex mx-4 my-2">
              <div class="w-1/2">
                <div :class="getColorStatus(item_detail.priority)" class="text-body2"><span
                    class="text-primary">Prioridad: </span>{{ item_detail.priority }}
                </div>
              </div>
              <div class="w-1/2">
                <div class="text-gray-3 text-body2"><span
                    class="text-primary">Atendida: </span>{{ item_detail.attended }}
                </div>
              </div>
            </div>
            <div class="w-full text-gray-2 text-body2 mx-4 my-2">
              {{ item_detail.description }}
            </div>
            <div class="flex mx-4 my-2">
              <div class="w-1/2 pr-2">
                <div class="text-primary text-body1 mb-4">
                  Novedad
                </div>
                <img :src="item_detail.imageUrl" style="max-height: 370px">
              </div>
              <div class="w-1/2">
                <div class="text-primary text-body1 mb-4">
                  Solucion
                </div>
                <img :src="item_detail.novelty_image" style="max-height: 370px">
              </div>
            </div>
          </div>
        </section>
      </vue-html2pdf>
    </div>
    <v-dialog
        v-model="view_attended_novelty"
        max-width="800px"
    >
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline text-primary">Atender novedad</span>
        </v-card-title>
        <div class="mx-6">
          <v-textarea
              name="comment"
              :rules="rules"
              label="Comentario"
              v-model="comment_attended"
          ></v-textarea>
          <div class="my-6">
            <div class="text-caption text-primary mb-4">
              Foto de la novedad resuelta
            </div>
            <input type="file" id="file" accept="image/*" capture @change="previewFiles">
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="view_attended_novelty = false"
            >
              {{ $t('cancel') }}
            </v-btn>
            <v-btn color="primary"
                   @click="attendNovelty"
                   :disabled="loading || !comment_attended || !novelty_image"
            >
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
    <div v-if="list">
      <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="Novelities-list"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="90%"
          ref="html2Pdf"
      >
        <section slot="pdf-content">
          <div class="w-full m-8">
            <div class="w-full h3 text-primary w-full text-center my-6">Lista de novedades</div>
            <div class="w-full p-2  border-b border-primary flex text-center">
              <div class="w-1/5 text-body-2 text-secondary">
                Placa
              </div>
              <div class="w-1/5 text-body-2 text-secondary">
                Fecha expiracion
              </div>
              <div class="w-1/5 text-body-2 text-secondary">
                Descripción
              </div>
              <div class="w-1/5 text-body-2 text-secondary">
                Prioridad
              </div>
              <div class="w-1/5 text-body-2 text-secondary">
                Atendida
              </div>
            </div>
            <div class="w-full p-2  border-b border-gray-2 flex text-center" :key="index" v-for="(item,index) in data">
              <div class="w-1/5">
                {{ item.plateVehicle }}
              </div>
              <div class="w-1/5">
                {{ item.expirationDate }}
              </div>
              <div class="w-1/5">
                {{ item.description }}
              </div>
              <div class="w-1/5">
                {{ item.priority }}
              </div>
              <div class="w-1/5">
                {{ item.attended }}
              </div>
            </div>
          </div>
        </section>
      </vue-html2pdf>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import VueHtml2pdf from 'vue-html2pdf'

export default {
  name: "Novelties",
  components: {
    VueHtml2pdf
  },
  data() {
    return {
      loading: false,
      data: [],
      priority_items: ['ALTA', 'MEDIA', 'BAJA'],
      status_items: [
        {label: 'Atendidas', value: true},
        {label: 'No Atendidas', value: false},
      ],
      search_status: null,
      search_priority: null,
      search_plate: null,
      dates: [],
      menu: false,
      search_fleet: null,
      item_detail: null,
      view_detail_novelty: false,
      comment_attended: null,
      view_attended_novelty: false,
      item_selected: null,
      rules: [
        value => !!value || 'Este campo es obligatorio',
      ],
      headers: [
        {
          text: 'Placa',
          align: 'start',
          value: 'plateVehicle',
        },
        {text: 'Fecha expiración', value: 'expirationDate'},
        {text: 'Descripción', value: 'description'},
        {text: 'Prioridad', value: 'priority'},
        {text: 'Atendida', value: 'attended'},
        {text: 'Acciones', value: 'actions', sortable: false},
      ],
      list: true,
      json_fields: {
        Placa: "plateVehicle",
        "Fecha expiracion": "expirationDate",
        Descripcion: "description",
        Prioridad: "priority",
        Atendida: "attended",
      },
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      novelty_image: null,
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    newRegister() {
    },
    loadData() {
      this.loading = true

      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }

      let url = `${process.env.VUE_APP_API_URL}/api/sutech/getNoveltiesByFilters?`


      if (this.search_status !== null && this.search_status !== undefined) url = `${url}state=${this.search_status}`
      if (this.search_priority) url = `${url}&priority=${this.search_priority}`
      if (this.search_plate) url = `${url}&plateVehicle=${this.search_plate}`

      if (this.dates.length) {
        const date_start = this.dates[0]
        const date_end = this.dates.length > 1 ? this.dates[1] : null

        if (date_start) url = `${url}&startDate=${date_start}`
        if (date_end) url = `${url}&endDate=${date_end}`

      }

      this.data = []

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.ok) {
              data.noveltiesDB.forEach(item => {
                item.expirationDate = moment(item.expirationDate).utc().format('DD/MM/YYYY')
                this.data.push(item)
              })
              this.loading = false
            } else {
              this.loading = false
            }
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    getColorStatus(item) {
      switch (item) {
        case 'BAJA':
          return 'text-success'
        case 'MEDIA':
          return 'text-warning'
        case 'ALTA':
          return 'text-danger'
      }
    },
    clearSearch() {
      this.dates = []
      this.search_status = false
      this.search_priority = null
      this.search_plate = null
      this.dates = []
      this.search_fleet = null
    },
    attendNoveltyShow(item) {
      this.view_attended_novelty = true
      this.item_selected = item
    },
    attendNovelty() {
      if (this.comment_attended) {
        this.loading = true

        let config = {
          headers: {
            token: localStorage.sutech_token,
          }
        }

        const body = this.$qs.stringify({
          attended: this.comment_attended,
          novelty_image: this.novelty_image,
        })

        let url = `${process.env.VUE_APP_API_URL}/api/sutech/attendedNoveltiesById/${this.item_selected._id}`

        this.$axios.put(url, body, config)
            .then(({data}) => {
              if (data.ok) {
                this.loading = false
                this.view_attended_novelty = false
                this.item_selected = null
                this.comment_attended = null
              }
              this.loadData()
            })
            .catch((error) => {
              this.loading = false
              console.log('error ' + error)
            })
      }
    },
    viewDetail(item) {
      this.item_detail = item
      this.view_detail_novelty = true
    },
    pdfNovelty() {
      this.view_detail_novelty = false
      this.loading = true
      this.generateReport()
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
      this.loading = false
      this.list = true
      this.item_detail = null
    },
    validateDate(date) {
      return moment(date, 'DD/MM/YYYY', true).format() < moment(Date.now(), true).format()
    },
    exportPdf() {
      this.list = true
      this.item_detail = null
      this.loading = true
      this.generateReport()
    },
    loadExcelData() {
      if (this.data.length) {
        this.data.forEach(item => {
          const row = {
            plateVehicle: item.plateVehicle,
            expirationDate: item.expirationDate,
            description: item.description,
            priority: item.priority,
            attended: item.attended,
          }
          this.json_data.push(row)
        });
      }
    },
    previewFiles(event) {
      this.loading = true
      const formData = new FormData()
      formData.append('image', event.target.files[0])

      let config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          token: localStorage.sutech_token,
        }
      }

      this.$axios.post(`${process.env.VUE_APP_API_URL}/api/sutech/uploadNoveltiesFleetCheck`, formData, config)
          .then(({data}) => {
            if (data.ok) {
              this.novelty_image = data.urlImage
            }
          })
          .catch((error) => {
            console.log('error ' + error)
          })
          .finally(() => this.loading = false)
    },
  },
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ')
    },
  },
}
</script>
