<template>
  <div>
    <div v-if="loading">
      <loading></loading>
    </div>
    <div v-else class="container">
      <div class="text-subtitle text-secondary my-8 border-b border-solid border-secondary">Datos por semana</div>
      <div class="w-full flex flex-wrap my-6">
        <div class="w-full md:w-1/3 py-2 pr-4">
          <v-card shadow="always" class="relative rounded-md bg-primary border-none">
            <div class="flex py-2 pr-2">
              <div class="w-1/3 text-center self-center text-subtitle">
                <v-icon x-large color="#ffffff">mdi-counter</v-icon>
              </div>
              <div class="w-2/3 self-center text-center">
                <div class="text-white text-body">Novedades Semana</div>
                <div class="text-white text-subtitle">{{ priorityWeek }}</div>
              </div>
            </div>
          </v-card>
        </div>
        <div class="w-full md:w-1/3 py-2 pr-4">
          <v-card shadow="always" class="relative rounded-md bg-success border-none">
            <div class="flex py-2 pr-2">
              <div class="w-1/3 text-center self-center text-subtitle">
                <v-icon x-large color="#ffffff">mdi-check-circle-outline</v-icon>
              </div>
              <div class="w-2/3 self-center text-center">
                <div class="text-white text-body">Novedades atendidas</div>
                <div class="text-white text-subtitle">{{ priorityWeekAttended }}</div>
              </div>
            </div>
          </v-card>
        </div>
        <div class="w-full md:w-1/3 py-2 pr-4">
          <v-card shadow="always" class="relative rounded-md bg-danger-2 border-none">
            <div class="flex py-2 pr-2">
              <div class="w-1/3 text-center self-center text-subtitle">
                <v-icon x-large color="#ffffff">mdi-alert-remove</v-icon>
              </div>
              <div class="w-2/3 self-center text-center">
                <div class="text-white text-body">Novedades sin atender</div>
                <div class="text-white text-subtitle">{{ priorityWeekNoAttended }}</div>
              </div>
            </div>
          </v-card>
        </div>
      </div>
      <div class="text-primary text-body w-full mb-4">Novedades de la semana sin atender</div>
      <v-data-table
          :headers="headers"
          :items="priorityWeekPending"
          class="elevation-1 w-full mx-4 my-4"
      >
        <template v-slot:item.priority="{ item }">
          <v-chip
              :color="getColor(item.priority)"
              dark
          >
            {{ item.priority }}
          </v-chip>
        </template>
        <template v-slot:item.state="{ item }">
          <div :class="item.state ? 'text-success' : 'text-danger'">
            {{ item.state ? 'Activo' : 'Inactivo' }}
          </div>
        </template>
      </v-data-table>
      <div class="text-subtitle text-secondary my-8 border-b border-solid border-secondary">Datos por Mes</div>
      <line-chart :chartdata="chartdataLine" class="bg-gray"></line-chart>
      <div class="w-full flex my-6">
        <div class="w-full md:w-1/2 px-2">
          <v-card
              class="mx-auto"
              max-width="500"
          >
            <v-toolbar
                color="primary"
                dark
            >
              <v-toolbar-title>Inspecciones del mes por vehiculos</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>

            <v-list>
              <v-list-group
                  v-for="item in items_inspection"
                  :key="item.title"
                  v-model="item.active"
                  :prepend-icon="item.action"
                  no-action
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item
                    class="overflow-y-auto"
                    v-for="(child,index) in item.items"
                    :key="index"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="child.title"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list>
          </v-card>
        </div>
        <div class="w-full md:w-1/2 px-2">
          <doughnut-chart :chartdata="chartdataDoughnut" class="bg-gray"></doughnut-chart>
        </div>
      </div>
      <div class="text-primary text-body w-full mb-4">Novedades del mes sin atender</div>
      <v-data-table
          :headers="headers"
          :items="priorityMonthPending"
          class="elevation-1 w-full mx-4 my-4"
      >
        <template v-slot:item.priority="{ item }">
          <v-chip
              :color="getColor(item.priority)"
              dark
          >
            {{ item.priority }}
          </v-chip>
        </template>
        <template v-slot:item.state="{ item }">
          <div :class="item.state ? 'text-success' : 'text-danger'">
            {{ item.state ? 'Activo' : 'Inactivo' }}
          </div>
        </template>
      </v-data-table>
      <div class="text-subtitle text-secondary my-8 border-b border-solid border-secondary">Datos consolidados de novedades</div>
      <div class="w-full flex flex-wrap my-6">
        <div class="w-full md:w-1/3 py-2 pr-4">
          <v-card shadow="always" class="relative rounded-md bg-danger border-none">
            <div class="flex py-2 pr-2">
              <div class="w-1/3 text-center self-center text-subtitle">
                <v-icon x-large color="#ffffff">mdi-close-octagon</v-icon>
              </div>
              <div class="w-2/3 self-center text-center">
                <div class="text-white text-body">Prioridad Alta</div>
                <div class="text-white text-subtitle">{{ priorityHigh }}</div>
              </div>
            </div>
          </v-card>
        </div>
        <div class="w-full md:w-1/3 py-2 pr-4">
          <v-card shadow="always" class="relative rounded-md bg-warning border-none">
            <div class="flex py-2 pr-2">
              <div class="w-1/3 text-center self-center text-subtitle">
                <v-icon x-large color="#ffffff">mdi-alert</v-icon>
              </div>
              <div class="w-2/3 self-center text-center">
                <div class="text-white text-body">Prioridad Media</div>
                <div class="text-white text-subtitle">{{ priorityMedium }}</div>
              </div>
            </div>
          </v-card>
        </div>
        <div class="w-full md:w-1/3 py-2">
          <v-card shadow="always" class="relative rounded-md bg-info border-none">
            <div class="flex py-2 pr-2">
              <div class="w-1/3 text-center self-center text-subtitle">
                <v-icon x-large color="#ffffff">mdi-information</v-icon>
              </div>
              <div class="w-2/3 self-center text-center">
                <div class="text-white text-body">Prioridad baja</div>
                <div class="text-white text-subtitle">{{ priorityLow }}</div>
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from '../components/charts/Line'
import DoughnutChart from '../components/charts/Doughnut'

export default {
  name: "FleetCheckHome",
  components: {LineChart, DoughnutChart},
  data() {
    return {
      chartdataLine: {
        labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        data_low: [],
        data_medium: [],
        data_high: [],
      },
      chartdataDoughnut: [],
      loading: false,
      priorityHigh: null,
      priorityMedium: null,
      priorityLow: null,
      priorityWeek: null,
      priorityWeekAttended: null,
      priorityWeekNoAttended: null,
      headers: [
        {
          text: 'Placa',
          align: 'start',
          value: 'plateVehicle',
        },
        {text: 'Prioridad', value: 'priority'},
        {text: 'Estado', value: 'state'},
        {text: 'Atendida', value: 'attended'},
      ],
      priorityWeekPending: [],
      data_high: [],
      data_medium: [],
      data_low: [],
      items_inspection: [
        {
          action: 'mdi-check-all',
          items: [],
          title: 'Hechas',
        },
        {
          action: 'mdi-close-octagon',
          items: [],
          title: 'No hechas',
        },
      ],
      priorityMonthPending: [],
    }
  },
  mounted() {
    this.getDataDoughnut()
    this.getDataNovelitiesPriority()
    this.getDataNovelitiesWeek()
    this.getDataNovelitiesWeekAttended()
    this.getDataNovelitiesWeekPending()
    this.getDataLine()
    this.getInspectionsDone()
    this.$store.commit('getNotification')
    this.getDataNovelitiesMonthPending()
  },
  methods: {
    getDataLine() {
      this.loading = true

      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }
      let url = `${process.env.VUE_APP_API_URL}/api/sutech/newsByMonthOfTheYearByBrands/`

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.data) {
              data.data.forEach(item => {
                this.chartdataLine.data_high.push(item.ALTA)
                this.chartdataLine.data_medium.push(item.MEDIA)
                this.chartdataLine.data_low.push(item.BAJA)

                this.chartdataLine
              });
              this.loading = false
            } else {
              this.loading = false
            }
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    getDataDoughnut() {
      this.loading = true

      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }
      let url = `${process.env.VUE_APP_API_URL}/api/sutech/lastMonthsNoveltiesByBrands/`

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.data) {
              Object.entries(data.data).forEach(([key, value]) => {
                this.chartdataDoughnut.push([this.$t(key), value])
              });
              this.loading = false
            } else {
              this.loading = false
            }
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    getDataNovelitiesPriority() {
      this.loading = true

      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }
      let url = `${process.env.VUE_APP_API_URL}/api/sutech/noveltiesByPriorityAndBrands/`

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.data) {
              this.priorityHigh = data.data.PriorityAlta
              this.priorityMedium = data.data.PriorityMedia
              this.priorityLow = data.data.PriorityBaja
              this.loading = false
            } else {
              this.loading = false
            }
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    getDataNovelitiesWeek() {
      this.loading = true

      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }
      let url = `${process.env.VUE_APP_API_URL}/api/sutech/lastWeekNoveltiesByBrands/`

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.data) {
              this.priorityWeek = data.data.noveltiesLast7Days
              this.loading = false
            } else {
              this.loading = false
            }
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    getDataNovelitiesWeekAttended() {
      this.loading = true

      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }
      let url = `${process.env.VUE_APP_API_URL}/api/sutech/lastWeeksNoveltiesAttendedByBrands/`

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.data) {
              this.priorityWeekAttended = data.data.noveltiesAttended
              this.priorityWeekNoAttended = data.data.noveltiesNotAttended
              this.loading = false
            } else {
              this.loading = false
            }
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    getDataNovelitiesWeekPending() {
      this.loading = true

      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }
      let url = `${process.env.VUE_APP_API_URL}/api/sutech/noveltiesEarringsAndPlatesByBrands/`

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.plates) {
              this.priorityWeekPending = data.plates
              this.loading = false
            } else {
              this.loading = false
            }
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    getDataNovelitiesMonthPending() {
      this.loading = true

      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }
      let url = `${process.env.VUE_APP_API_URL}/api/sutech/noveltiesEarringsAndPlatesByBrandsMonth/`

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.plates) {
              this.priorityMonthPending = data.plates
              this.loading = false
            } else {
              this.loading = false
            }
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    getColor(priority) {
      if (priority == 'ALTA') return 'red'
      else if (priority == 'MEDIA') return 'orange'
      else return 'green'
    },
    getInspectionsDone() {
      this.loading = true

      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }
      let url = `${process.env.VUE_APP_API_URL}/api/sutech/platesWithMonthlyInspectionsByBrands/`

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.ok) {
               data.result.inspectionsTrue.forEach( item => {
                 this.items_inspection[0].items.push({title: item})
               })
              data.result.inspectionsFalse.forEach( item => {
                this.items_inspection[1].items.push({title: item})
              })
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
  }
}
</script>