<template>
  <div>
    <sub-header :name="$t('control_distance')" :is_master="false"></sub-header>
    <div v-if="loading">
      <loading></loading>
    </div>
    <div v-else class="flex flex-wrap">
      <div class="w-full md:flex mx-4 my-4 items-center border border-primary border-solid">
        <div class="w-full md:w-5/6 px-4">
          <v-row>
            <v-text-field
                class="px-4 w-full md:w-3/12"
                label="Placa"
                v-model="search_plate"
            >
              <v-icon
                  slot="prepend"
              >
                mdi-ab-testing
              </v-icon>
            </v-text-field>
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="dates"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateRangeText"
                    label="Fecha"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="dates"
                  no-title
                  scrollable
                  range
              >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(dates)"
                >
                  Aceptar
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-select
                class="px-2 w-full md:w-3/12"
                :items="fleet_items"
                clearable
                label="Sede"
                v-model="selected_fleet"
            ></v-select>
            <v-select
                class="mx-2"
                :items="region_items"
                clearable
                label="Region"
                v-model="selected_region"
            ></v-select>
          </v-row>
        </div>
        <v-btn
            dark
            color="primary"
            text
            @click="loadData"
            class="mx-2"
        >
          Consultar
        </v-btn>
      </div>
      <div class="my-4 mx-4 flex">
        <download-excel
            v-if="data.length"
            class="btn btn-default"
            :data="json_data"
            :fields="json_fields"
            :before-generate="loadExcelData"
            worksheet="My Worksheet"
            name="ExportDistances.xls"
        >
          <v-btn
              class="mx-2"
              dark
              large
              color="success"
          >
            Exportar
            <v-icon dark class="mx-2">
              mdi-file-excel
            </v-icon>
          </v-btn>
        </download-excel>
      </div>
      <v-data-table
          v-if="data.length"
          :headers="headers"
          :items="data"
          class="elevation-1 w-full mx-4 my-4"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="detailSpeeds(item)"
          >
            mdi-information-outline
          </v-icon>
          <v-icon
              small
              class="mr-2"
              @click="getPDFModal(item)"
          >
            mdi-file-pdf-box
          </v-icon>
        </template>
        <template v-slot:item.totalDistance="{ item }">
          {{ Math.round(item.totalDistance) }}
        </template>
      </v-data-table>
      <div v-if="data.length" class="w-full my-8">
        <div class="px-6 w-full">
          <bar-chart
              v-if="chart_data_fleet"
              :chartdata="chart_data_fleet"
              :data_text="{title: 'Consolidado por flota',ytitle: 'Recorridos',xtitle: 'Flotas'}"
              class="mx-4 mb-4 w-full">
          </bar-chart>
        </div>
        <div class="mx-4 md:flex mt-4">
          <div class="px-2 w-full md:w-1/2">
            <v-card>
              <v-list-item>
                <v-list-item-content class="border-b border-primary">
                  <v-list-item-title class="text-primary">Detalle de recorridos por sede</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list>
                <v-list-group
                    v-for="(item, key) in issues_cars"
                    :key="key"
                    no-action
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title class="text-secondary text-overline" v-text="key"></v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <div v-for="(child, key) in item" :key="key">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="text-primary text-overline">{{ child[0] }}</v-list-item-title>
                        <v-list-item-subtitle class="text-caption text-gray-2 text-right">{{
                            child[1]
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                  </div>
                </v-list-group>
              </v-list>
            </v-card>
          </div>
          <div class="px-2 w-full md:w-1/2">
            <v-card
                tile
            >
              <v-list-item>
                <v-list-item-content class="border-b border-primary">
                  <v-list-item-title class="text-primary">Detalle de maximos y minimos por sede</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list>
                <v-list-group
                    v-for="(item, key) in issues_fleet"
                    :key="key"
                    no-action
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title class="text-secondary text-overline" v-text="key"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-primary text-overline">Maximo</v-list-item-title>
                      <v-list-item-subtitle class="text-caption text-gray-2 text-right">
                        {{ Math.max(...item.max) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-primary text-overline">Minimo</v-list-item-title>
                      <v-list-item-subtitle class="text-caption text-gray-2 text-right">
                        {{ Math.min(...item.min) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list>
            </v-card>
          </div>

        </div>
        <div class="px-6 w-full">
          <bar-chart
              v-if="chart_data_region"
              :chartdata="chart_data_region"
              :data_text="{title: 'Consolidado por region',ytitle: 'Recorrido',xtitle: 'Regiones'}"
              class="mx-4 mb-4 w-full mt-4">
          </bar-chart>
        </div>
      </div>
    </div>
    <div v-if="itemSelected">
      <v-dialog
          v-model="viewDetailForm"
          max-width="800px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Detalle de recorridos</span>
          </v-card-title>
          <div :key="index" class="mx-6 border-b border-gray-3 border-solid py-4"
               v-for="(item, index) in itemSelected.distances">
            <div class="md:flex">
              <div class="w-full md:w-1/2">
                <div class="text-gray-3 text-body2"><span
                    class="text-primary">Fecha: </span>{{ item.date }}
                </div>
              </div>
              <div class="w-full md:w-1/2">
                <div class="text-gray-3 text-body2"><span
                    class="text-primary">Recorrido: </span>{{ Math.round(item.odometer) }}
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>
    <div v-if="itemSelectedPDF">
      <v-dialog
          v-model="viewPDFForm"
          max-width="600px"
      >
        <v-card>
          <div v-if="!pdf_actions">
            <div class="flex justify-center mt-16">
              <img src="@/assets/images/Cube_loading.svg" alt="Triangle with three unequal sides"/>
            </div>
            <div class="text-body text-gray-2 my-4 w-full text-center">Generando PDF, Por favor espere</div>
          </div>
          <div v-else>
            <div class="flex justify-center mt-16">
              <img src="@/assets/images/done.png" alt="Triangle with three unequal sides" width="300"
                   height="300"/>
            </div>
            <div class="text-body text-gray-2 my-4 w-full text-center">PDF Listo</div>
          </div>
          <v-card-actions v-if="pdf_actions" class="mt-6">
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="viewPDFForm = false"
            >
              Cancelar
            </v-btn>
            <v-btn
                color="primary"
                text
                @click="getPDF"
                :disabled="loading"
            >
              Generar PDF
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="10000"
          :filename="pdf_title"
          :pdf-quality="2"
          :manual-pagination="true"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="90%"
          ref="html2Pdf"
      >
        <section slot="pdf-content">
          <section class="pdf-item border border-gray-3 flex mt-2">
            <div class="w-1/4 border-r border-gray-3">
              <!--                <img :src="$store.state.user.brands.urlLogo" alt="icon" class=""/>-->
            </div>
            <div class="w-2/4 border-r border-gray-3 h5 text-primary w-full text-center my-4">
              Detalle de recorridos
            </div>
            <div class="text-caption text-primary w-1/2 text-center w-1/4 my-4">
              <p>{{ currentDate }}</p>
            </div>
          </section>
          <section class="pdf-item mt-4">
            <div class="w-full bg-primary text-white text-overline text-center py-2">INFORMACIÓN GENERAL
            </div>
            <div class="flex border border-gray-3 px-4">
              <div class="w-1/2 border-r border-gray-3">
                <p class="text-overline text-primary">Placa:</p>
                <p class="text-body2 text-gray-2">{{ itemSelectedPDF.plate }}</p>
              </div>
              <div class="w-1/2 px-4">
                <p class="text-overline text-primary">Distancia total:</p>
                <p class="text-body2 text-gray-2">{{ itemSelectedPDF.totalDistance.toFixed(2) }}</p>
              </div>
            </div>
            <div class="border border-gray-3 px-4">
              <p class="text-overline text-primary">Flota:</p>
              <p class="text-body2 text-gray-2">{{ itemSelectedPDF.distances[0].fleet }}</p>
            </div>
          </section>

          <section class="pdf-item mt-4">
            <div class="w-full bg-primary text-white text-overline text-center py-2">INFORMACIÓN DETALLADA
            </div>
            <div class="flex mt-2 bg-primary text-white px-4">
              <div class="w-6/12">
                Fecha
              </div>
              <div class="w-6/12">
                Kilometraje
              </div>
            </div>
          </section>
          <section class="pdf-item">
            <div :key="index" v-for="(item,index) in itemSelectedPDF.distances">
              <section class="pdf-item">
                <div :key="index" class="my-2 border-b border-primary flex text-gray-2 px-4 text-caption">
                  <div class="w-6/12">
                    {{ item.date }}
                  </div>
                  <div class="w-6/12">
                    {{ parseFloat(item.odometer).toFixed(2) }}
                  </div>
                </div>
              </section>
            </div>
          </section>
        </section>
      </vue-html2pdf>
    </div>
  </div>
</template>

<script>

import BarChart from '../components/charts/Bar'
import moment from "moment";
import VueHtml2pdf from 'vue-html2pdf'

export default {
  name: "ControlDistance",
  components: {
    BarChart,
    VueHtml2pdf,
  },
  data() {
    return {
      data: [],
      loading: false,
      selected_region: null,
      region_items: [],
      region: [],
      fleet_items: [],
      selected_fleet: null,
      menu: false,
      dates: [],
      date_start: null,
      date_end: null,
      search_plate: null,
      json_fields: {
        Placa: "plateVehicle",
        Flota: "fleet",
        Fecha: "date",
        Recorrido: "distance",
        "Total recorrido": "total"
      },
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      headers: [
        {
          text: 'Placa',
          align: 'start',
          value: 'plate',
        },
        {text: 'Flota', value: 'distances[0].fleet'},
        {text: 'Total recorrido', value: 'totalDistance'},
        {text: 'Acciones', value: 'actions', sortable: false},
      ],
      chart_data_fleet: null,
      issues_region: {},
      chart_data_region: null,
      issues_fleet: {},
      issues_cars: {},
      itemSelected: null,
      viewDetailForm: false,
      itemSelectedPDF: null,
      viewPDFForm: false,
      pdf_title: null,
      pdf_actions: false,
      currentDate: moment(new Date(Date.now())).utc().format('DD/MM/YYYY'),
    }
  },
  mounted() {
    this.$store.commit('getNotification')
    this.loadFleets()
    this.loadRegions()
    this.loadFleetsRegions()
  },
  methods: {
    loadData() {
      this.$store.commit('setMessage', {message: null, type: null})
      this.loading = true
      this.data = []
      this.chart_data_fleet = null
      this.issues_region = {}
      this.chart_data_region = null
      this.issues_fleet = {}
      this.issues_cars = {}
      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }

      let url = null
      let body = null
      if (this.dates.length) {
        this.date_start = this.dates[0]
        this.date_end = this.dates.length > 1 ? this.dates[1] : null

        if (this.search_plate) {
          body = this.$qs.stringify({
            initDate: this.date_start,
            finalDate: this.date_end,
            plate: this.search_plate,
          })

          url = `${process.env.VUE_APP_API_URL}/api/sutech/getDistanceByPlate/`

        } else {
          if (this.selected_fleet) {
            body = this.$qs.stringify({
              initDate: this.date_start,
              finalDate: this.date_end,
              fleet: this.selected_fleet,
            })
          } else {
            body = this.$qs.stringify({
              initDate: this.date_start,
              finalDate: this.date_end,
            })
          }
          url = `${process.env.VUE_APP_API_URL}/api/sutech/getDistanceAllPlates/`
        }
        this.$axios.post(url, body, config)
            .then(({data}) => {
              if (data.ok) {
                if (this.search_plate) this.data.push(data.distances)
                else this.data = data.distances

                if (this.selected_region) {
                  let filter_data = this.data.filter(item => {
                    if (item.distances && item.distances.length){
                      let region_item = this.region.filter(region => {
                        if (region.region === this.selected_region)
                          return region
                      })
                      let searchs_fleets = region_item.map(i => i.fleet)
                      if (searchs_fleets.includes(item.distances[0].fleet)) return item
                    }
                  })
                  this.data = filter_data
                }

                this.data.forEach(item => {
                  let fleet = null
                  if (item.distances && item.distances.length) fleet = item.distances[0].fleet
                  if (fleet) {
                    const arr = item.distances.map(o => Math.round(o.odometer));
                    const max = Math.max(...arr)
                    const min = Math.min(...arr)

                    if (this.issues_fleet[fleet]) {
                      this.issues_fleet[fleet].y += Math.round(item.totalDistance)
                      this.issues_fleet[fleet].max.push(max)
                      this.issues_fleet[fleet].min.push(min)
                    } else {
                      this.issues_fleet[fleet] = {
                        name: fleet,
                        y: Math.round(item.totalDistance),
                        max: [max],
                        min: [min]
                      }
                    }
                    if (this.issues_cars[fleet]) {
                      this.issues_cars[fleet].push([item.plate, Math.round(item.totalDistance)])
                    } else {
                      this.issues_cars[fleet] = [[item.plate, Math.round(item.totalDistance)]]
                    }
                  }
                })

                this.chart_data_fleet = Object.values(this.issues_fleet)

                this.chart_data_fleet.forEach(item => {
                  let region_item = this.region.find(region => {
                    if (region.fleet === item.name)
                      return region
                  })
                  if (region_item) {
                    let region = region_item.region
                    if (this.issues_region[region]) {
                      this.issues_region[region].y += item.y
                    } else {
                      this.issues_region[region] = {name: region, y: item.y}
                    }
                  }
                })

                this.chart_data_region = Object.values(this.issues_region)
              } else {
                this.$store.commit('setMessage', {
                  message: data.response,
                  type: 'info'
                })
              }
              this.loading = false
            })
            .catch((error) => {
              this.loading = false
              console.log('error ' + error)
            })
      } else {
        this.$store.commit('setMessage', {
          message: 'El rango de fechas es obligatorios',
          type: 'info'
        })

        this.loading = false
      }
    },
    loadExcelData() {
      this.json_data = []
      if (this.data.length) {
        this.data.forEach(item => {
          item.distances.forEach(distance => {
            const row = {
              plateVehicle: item.plate,
              fleet: distance.fleet,
              date: moment(new Date(distance.date)).utc().format('DD/MM/YYYY'),
              distance: Math.round(distance.odometer),
              total: Math.round(item.totalDistance),
            }
            this.json_data.push(row)
          })
        });
      }
    },
    detailSpeeds(item) {
      this.itemSelected = []
      this.itemSelected = item
      this.viewDetailForm = true

      const arr = item.distances.map(o => o.odometer);
      const max = Math.max(...arr);
      const distance_info = item.distances.filter((item, index) => {
        if (item.odometer == max) {
          item.index = index
          return item
        }
      })
      this.itemSelected.distances.splice(distance_info[0].index, 1)
      this.itemSelected.distances.unshift(distance_info[0])
    },
    loadFleets() {
      this.loading = true
      this.fleet_items = []
      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }
      const url = `${process.env.VUE_APP_API_URL}/api/sutech/sutechGetCampusFiltersByBrand/`

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.ok) {
              this.fleet_items = data.fleets.map(fleet => fleet.name)
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    loadRegions() {
      this.loading = true
      this.region_items = []
      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }
      const url = `${process.env.VUE_APP_API_URL}/api/sutech/sutechGetZonesFiltersByBrand/`

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.ok) {
              this.region_items = data.zones.map(zone => zone.name)
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    loadFleetsRegions() {
      this.loading = true
      this.region = []
      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }
      const url = `${process.env.VUE_APP_API_URL}/api/sutech/getZonesAndFleetByBrands/`

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.ok) {
              this.fleet_items = data.relations
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    getPDFModal(item) {
      this.itemSelectedPDF = null
      this.itemSelectedPDF = item
      this.viewPDFForm = true
      setTimeout(() => {
        const today = new Date(Date.now());
        this.pdf_title = `${moment(today).utc().format('DDMMYYYY')} - ${this.itemSelectedPDF.plate} - ${this.itemSelectedPDF.distances[0].fleet}`
        this.pdf_actions = true
        window.scroll(0, 0)
      }, 3000)
    },
    async getPDF() {
      this.pdf_actions = false
      this.viewPDFForm = false
      await this.$refs.html2Pdf.generatePdf()
    },
  },
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ')
    },
  },
}
</script>
