<template>
  <div>
    <sub-header :name="$t('limit_speed')" :is_master="false"></sub-header>
    <div v-if="loading">
      <loading></loading>
    </div>
    <div v-else class="flex flex-wrap">
      <div class="w-full md:flex mx-4 my-4 p-5 items-center border border-primary border-solid">
        <div class="w-full md:w-5/6 px-4">
          <v-row>
            <v-text-field
                class="px-4 w-full md:w-3/12"
                label="Intervalo de tiempo"
                v-model="time_interval"
            >
              <v-icon
                  slot="prepend"
              >
                mdi-ab-testing
              </v-icon>
            </v-text-field>
            <v-text-field
                class="px-4 w-full md:w-3/12"
                label="Limite de velocidad"
                v-model="limit_speed"
            >
              <v-icon
                  slot="prepend"
              >
                mdi-ab-testing
              </v-icon>
            </v-text-field>
            <v-select
                class="px-2 w-full md:w-3/12"
                :items="fleet_items"
                clearable
                label="Sede"
                v-model="selected_fleet"
            ></v-select>
            <v-text-field
                class="px-4 w-full md:w-3/12"
                label="Placa"
                v-model="search_plate"
            >
              <v-icon
                  slot="prepend"
              >
                mdi-ab-testing
              </v-icon>
            </v-text-field>
          </v-row>
          <v-row>
            <v-select
                class="mx-2"
                :items="region_items"
                clearable
                label="Region"
                v-model="selected_region"
            ></v-select>
            <v-menu
                v-model="menu_date_start"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date_start_select"
                    label="Fecha inicial"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="date_start_select"
                  @input="menu_date_start = false"
              ></v-date-picker>
            </v-menu>
            <v-menu
                v-model="menu_date_end"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date_end_select"
                    label="Fecha final"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="date_end_select"
                  @input="menu_date_end = false"
              ></v-date-picker>
            </v-menu>
            <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time_start"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="time_start"
                    label="Hora inicial"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                  v-if="menu2"
                  v-model="time_start"
                  full-width
                  format="24hr"
                  @click:minute="$refs.menu2.save(time_start)"
              ></v-time-picker>
            </v-menu>
            <v-menu
                ref="menu3"
                v-model="menu3"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time_end"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="time_end"
                    label="Hora final"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                  v-if="menu3"
                  v-model="time_end"
                  full-width
                  format="24hr"
                  @click:minute="$refs.menu3.save(time_end)"
              ></v-time-picker>
            </v-menu>
          </v-row>
        </div>
        <v-btn
            dark
            color="primary"
            text
            @click="loadData"
            class="mx-2"
        >
          Consultar
        </v-btn>
      </div>
      <div class="my-4 mx-4 flex">
        <download-excel
            v-if="data.length"
            class="btn btn-default"
            :data="json_data"
            :fields="json_fields"
            :before-generate="loadExcelData"
            worksheet="My Worksheet"
            name="ExportSpeeds.xls">
          <v-btn
              class="mx-2"
              dark
              large
              color="success"
          >
            Exportar
            <v-icon dark class="mx-2">
              mdi-file-excel
            </v-icon>
          </v-btn>
        </download-excel>
      </div>
      <v-data-table
          v-if="data.length"
          :headers="headers"
          :items="data"
          class="elevation-1 w-full mx-4 my-4"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="detailSpeeds(item)"
          >
            mdi-information-outline
          </v-icon>
          <v-icon
              small
              class="mr-2"
              @click="getPDFModal(item)"
          >
            mdi-file-pdf-box
          </v-icon>
        </template>
      </v-data-table>
      <div v-if="data.length" class="w-full my-8">
        <div class="px-6 w-full">
          <bar-chart
              v-if="chart_data_fleet"
              :chartdata="chart_data_fleet"
              :data_text="{title: 'Consolidado por flota',ytitle: 'Cantidad de excesos',xtitle: 'Flotas'}"
              class="mx-4 mb-4 w-full">
          </bar-chart>
        </div>
        <div class="mx-4 md:flex mt-4">
          <div class="px-2 w-full md:w-1/2">
            <v-card>
              <v-list-item>
                <v-list-item-content class="border-b border-primary">
                  <v-list-item-title class="text-primary">Detalle de Excesos por sede</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list>
                <v-list-group
                    v-for="(item, key) in issues_cars"
                    :key="key"
                    no-action
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title class="text-secondary text-overline" v-text="key"></v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <div v-for="(child, key) in item" :key="key">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="text-primary text-overline">{{ child[0] }}</v-list-item-title>
                        <v-list-item-subtitle class="text-caption text-gray-2 text-right">{{
                            child[1]
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                  </div>
                </v-list-group>
              </v-list>
            </v-card>
          </div>
          <div class="px-2 w-full md:w-1/2">
            <v-card
                tile
            >
              <v-list-item>
                <v-list-item-content class="border-b border-primary">
                  <v-list-item-title class="text-primary">Detalle de maximos y minimos por sede</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list>
                <v-list-group
                    v-for="(item, key) in issues_fleet"
                    :key="key"
                    no-action
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title class="text-secondary text-overline" v-text="key"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-primary text-overline">Maximo</v-list-item-title>
                      <v-list-item-subtitle class="text-caption text-gray-2 text-right">
                        {{ Math.max(...item.max) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-primary text-overline">Minimo</v-list-item-title>
                      <v-list-item-subtitle class="text-caption text-gray-2 text-right">
                        {{ Math.min(...item.min) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </v-list>
            </v-card>
          </div>

        </div>
        <div class="px-6 w-full">
          <bar-chart
              v-if="chart_data_region"
              :chartdata="chart_data_region"
              :data_text="{title: 'Consolidado por region',ytitle: 'Cantidad de excesos',xtitle: 'Regiones'}"
              class="mx-4 mb-4 w-full mt-4">
          </bar-chart>
        </div>
      </div>
      <div v-if="itemSelected">
        <v-dialog
            v-model="viewDetailForm"
            max-width="800px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Detalle de limites de velocidad</span>
            </v-card-title>
            <div :key="index" class="mx-6 border-b border-gray-3 border-solid py-4"
                 v-for="(item, index) in itemSelected.speeds">
              <div class="w-full">
                <div class="text-gray-3 text-body2">
                  <span class="text-primary">Localización inicial: </span>{{ item.locationStart }}
                </div>
              </div>
              <div class="w-full my-2">
                <div class="text-gray-3 text-body2">
                  <span class="text-primary">Localización final: </span>{{ item.locationEnd }}
                </div>
              </div>
              <div class="md:flex">
                <div class="w-full md:w-1/2">
                  <div class="text-gray-3 text-body2"><span
                      class="text-primary">Velocidad inicial: </span>{{ item.speedStart }}
                  </div>
                  <div class="text-gray-3 text-body2 my-2"><span
                      class="text-primary">Velocidad maxima: </span>{{ item.speedMax }}
                  </div>
                  <div class="text-gray-3 text-body2"><span
                      class="text-primary">Fecha inicial: </span>{{ item.startDate }}
                  </div>
                  <div class="text-gray-3 text-body2 my-2"><span
                      class="text-primary">Fecha final: </span>{{ item.endDate }}
                  </div>
                  <div class="text-gray-3 text-body2">
                    <span class="text-primary">Total segundos: </span>{{ item.totalSeconds }}
                  </div>
                </div>
                <div class="w-full md:w-1/2">
                  <map-box :index="`${itemSelected.plate}_${index}`" :lat="item.latitude"
                           :long="item.longitude"></map-box>
                </div>
              </div>
            </div>
          </v-card>
        </v-dialog>
      </div>
      <div v-if="itemSelectedPDF">
        <v-dialog
            v-model="viewPDFForm"
            max-width="600px"
        >
          <v-card>
            <div v-if="!pdf_actions">
              <div class="flex justify-center mt-16">
                <img src="@/assets/images/Cube_loading.svg" alt="Triangle with three unequal sides"/>
              </div>
              <div class="text-body text-gray-2 my-4 w-full text-center">Generando PDF, Por favor espere</div>
            </div>
            <div v-else>
              <div class="flex justify-center mt-16">
                <img src="@/assets/images/done.png" alt="Triangle with three unequal sides" width="300"
                     height="300"/>
              </div>
              <div class="text-body text-gray-2 my-4 w-full text-center">PDF Listo</div>
            </div>
            <v-card-actions v-if="pdf_actions" class="mt-6">
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  text
                  @click="viewPDFForm = false"
              >
                Cancelar
              </v-btn>
              <v-btn
                  color="primary"
                  text
                  @click="getPDF"
                  :disabled="loading"
              >
                Generar PDF
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="true"
            :paginate-elements-by-height="10000"
            :filename="pdf_title"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="90%"
            ref="html2Pdf"
        >
          <section slot="pdf-content">
            <section class="pdf-item border border-gray-3 flex mt-2">
              <div class="w-1/4 border-r border-gray-3">
                <img :src="$store.state.user.brands.urlLogo !== 'N/A' ? $store.state.user.brands.urlLogo : 'https://gps.sutech.co/img/logo_menu.60c4bcdd.png'" alt="icon" class=""/>
              </div>
              <div class="w-2/4 border-r border-gray-3 h5 text-primary w-full text-center">
                REQUERIMIENTO DE INCUMPLIMIENTO DE NORMAS DE TRANSITO
              </div>
              <div class="text-caption text-primary w-1/2 text-center w-1/4">
                <p class="text-gray">Talento Humano</p>
                <p>{{ currentDate }}</p>
              </div>
            </section>
            <section class="pdf-item mt-4">
              <div class="w-full bg-primary text-white text-overline text-center py-2">INFORMACIÓN DEL GENERAL
              </div>
              <div class="flex border border-gray-3 px-4">
                <div class="w-2/3 border-r border-gray-3">
                  <p class="text-min p-1 text-primary">Nombre de quien elabora el informe:</p>
                  <p class="text-gray-2 text-min p-1">{{ $store.state.user.name }}</p>
                </div>
                <div class="w-1/3 px-4">
                  <p class="text-min p-1 text-primary">Cargo:</p>
                  <p class="text-gray-2 text-min p-1">{{ $store.state.user.position }}</p>
                </div>
              </div>
              <div class="flex border border-t-0 border-gray-3 px-4">
                <div class="w-2/3 border-r border-gray-3">
                  <p class="text-min p-1 text-primary">Nombre del Trabajador a quien se le realiza el
                    informe:</p>
                  <p class="text-min p-1 text-gray-2">{{
                      `${itemSelectedPDF.speed_info.name} - ${itemSelectedPDF.plate} - ${itemSelectedPDF.speed_info.fleet}`
                    }}</p>
                </div>
                <div class="w-1/3 px-4">
                  <p class="text-min p-1 text-primary">Cargo:</p>
                  <p class="text-min p-1 text-gray-2">{{ $store.state.user.brands.name === 'INGETRANS SA' ? 'CONDUCTOR' : 'LECTOR' }}</p>
                </div>
              </div>
            </section>

            <section class="pdf-item mt-4">
              <div class="w-full bg-primary text-white text-min p-1 text-center py-2">INFORMACIÓN DEL EVENTO
              </div>
              <div class="w-full border border-gray-3 px-4">
                <p class="text-min p-1 text-primary">Indique exactamente el lugar y fecha en que ocurrieron los
                  hechos:</p>
                <p class="text-min p-1 text-gray-2">{{ itemSelectedPDF.speed_info.locationStart }}</p>
              </div>
              <div class="w-full border border-gray-3 border-t-0 px-4">
                <p class="text-min p-1 text-primary">Describa detalladamente los hechos que dan lugar a este
                  informe:</p>
                <p class="text-min p-1 text-gray-2">EXCESO DE VELOCIDAD O EVENTO.</p>
              </div>
              <div class="flex border border-t-0 border-gray-3 px-4">
                <div class="w-1/3 border-r border-gray-3">
                  <div class="my-2">
                    <p class="text-min p-1 text-primary">Fecha - hora:</p>
                    <p class="text-min p-1 text-gray-2">{{ itemSelectedPDF.speed_info.startDate }}</p>
                  </div>
                  <div class="my-2">
                    <p class="text-min p-1 text-primary">Velocidad:</p>
                    <p class="text-min p-1 text-gray-2">{{ `${itemSelectedPDF.speed_info.speedMax} KM/H` }}</p>
                  </div>
                </div>
                <div class="w-2/3 px-4">
                  <p class="text-overline text-primary">MAPA:</p>
                  <img
                      :src="`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/geojson(%7B%22type%22%3A%22FeatureCollection%22%2C%22features%22%3A%5B%7B%22type%22%3A%22Feature%22%2C%22properties%22%3A%7B%22marker-color%22%3A%22%23462eff%22%2C%22marker-size%22%3A%22medium%22%7D%2C%22geometry%22%3A%7B%22type%22%3A%22Point%22%2C%22coordinates%22%3A%5B${itemSelectedPDF.speed_info.longitude},${itemSelectedPDF.speed_info.latitude}%5D%7D%7D%5D%7D)/${itemSelectedPDF.speed_info.longitude},${itemSelectedPDF.speed_info.latitude},13/500x300?access_token=${mapbox_token}`">
                </div>
              </div>
              <div class="w-full border border-gray-3 border-t-0 px-4">
                <p class="text-min p-1 text-gray-2">De acuerdo lo mostrado en el detalle del reporte de
                  comportamientos de conducción se ha evidenciado un comportamiento que pone en riesgo su
                  integridad
                  y la de otros actores viales y que puede tener como consecuencia un desenlace indeseado. Por
                  este
                  comportamiento que no se alinea con lo establecido por el Plan estrategico de seguridad vial y
                  sus
                  politicas de regulación se inicia el proceso de investigación a fin de aclarar las
                  circunstancias
                  que dieron origen a este reporte</p>
              </div>
              <div class="flex border border-t-0 border-gray-3 px-4">
                <div class="w-1/2 border-r border-gray-3">
                  <p class="text-min p-1 text-primary">Firma Jefe inmediato:</p>
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                </div>
                <div class="w-1/2 px-4">
                  <p class="text-min p-1 text-primary">Firma trabajador:</p>
                </div>
              </div>
            </section>
            <div class="html2pdf__page-break"/>
            <section class="pdf-item">
              <div class="flex mt-2 bg-primary text-white px-4">
                <div class="w-3/12 p-1">
                  Fecha
                </div>
                <div class="w-7/12 p-1">
                  Localizacion
                </div>
                <div class="w-2/12 text-center p-1">
                  Velocidad
                </div>
              </div>
            </section>
            <section class="pdf-item">
              <div :key="index" v-for="(item,index) in itemSelectedPDF.speeds">
                <section class="pdf-item">
                  <div :key="index" class="my-2 border-b border-primary flex text-gray-2 px-4 text-min py-2">
                    <div class="w-3/12">
                      {{ item.startDate }}
                    </div>
                    <div class="w-7/12">
                      {{ item.locationStart }}
                    </div>
                    <div class="w-2/12 text-center">
                      {{ item.speedMax }}
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </section>
        </vue-html2pdf>
      </div>
    </div>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import moment from "moment";
import BarChart from '../components/charts/Bar'


export default {
  name: "SpeedLimit",
  components: {
    VueHtml2pdf,
    BarChart,
  },
  data() {
    return {
      proccesing_data: false,
      loading: false,
      menu: false,
      dates: [],
      time_interval: null,
      limit_speed: null,
      data: [],
      headers: [
        {
          text: 'Placa',
          align: 'start',
          value: 'plate',
        },
        {text: 'Flota', value: 'speeds[0].fleet'},
        {text: 'Conductor', value: 'speeds[0].name'},
        {text: 'Acciones', value: 'actions', sortable: false},
      ],
      itemSelected: null,
      itemSelectedPDF: null,
      viewDetailForm: false,
      date_start: null,
      date_end: null,
      id_search: null,
      pdf_title: null,
      pdf_actions: false,
      viewPDFForm: false,
      currentDate: moment(new Date(Date.now())).utc().format('DD/MM/YYYY'),
      menu2: false,
      menu3: false,
      time_start: null,
      time_end: null,
      issues_fleet: {},
      new_array: [],
      issues_cars: {},
      selected_region: null,
      region_items: [],
      region: [],
      fleet_items: [],
      selected_fleet: null,
      json_fields: {
        Placa: "plateVehicle",
        Flota: "fleet",
        Conductor: "name",
        Region: "group",
        Inicio: "startDate",
        Fin: "endDate",
        "Localización inicial": "locationStart",
        "Localización final": "locationEnd",
        "Total segundos": "totalSeconds",
        "Velocidad inicial": "speedStart",
        "Velocidad maxima": "speedMax",
      },
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      search_plate: null,
      chart_data_fleet: null,
      issues_region: {},
      chart_data_region: null,
      mapbox_token: process.env.VUE_APP_MAPBOX_TOKEN,
      date_start_select: null,
      menu_date_start: false,
      date_end_select: null,
      menu_date_end: false,
    }
  },
  mounted() {
    this.$store.commit('getNotification')
    this.loadFleets()
    this.loadRegions()
  },
  methods: {
    backToHome() {
      this.$router.push('/')
    },
    loadData() {
      this.$store.commit('setMessage', {message: null, type: null})
      this.issues_region = {}
      this.issues_fleet = {}
      this.issues_cars = {}
      this.chart_data_region = null
      this.chart_data_fleet = null
      this.loading = true
      this.data = []
      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }

      let url = null
      let body = null
      if (this.date_start_select && this.date_end_select && this.time_start && this.time_end && this.time_interval && this.limit_speed) {
        this.date_start = `${this.date_start_select} ${this.time_start}`
        this.date_end = `${this.date_end_select} ${this.time_end}`

        if (this.search_plate) {
          body = this.$qs.stringify({
            timeInterval: this.time_interval,
            limitSpeed: this.limit_speed,
            initDate: this.date_start,
            finalDate: this.date_end,
            plate: this.search_plate,
          })

          url = `${process.env.VUE_APP_API_URL}/api/sutech/getSpeedLimitOnePlate/`

        } else {
          if (this.selected_fleet) {
            body = this.$qs.stringify({
              timeInterval: this.time_interval,
              limitSpeed: this.limit_speed,
              initDate: this.date_start,
              finalDate: this.date_end,
              fleet: this.selected_fleet,
            })
          } else {
            body = this.$qs.stringify({
              timeInterval: this.time_interval,
              limitSpeed: this.limit_speed,
              initDate: this.date_start,
              finalDate: this.date_end,
            })
          }
          url = `${process.env.VUE_APP_API_URL}/api/sutech/getSpeedLimitAllPlates/`
        }
        this.$axios.post(url, body, config)
            .then(({data}) => {
              if (data.ok) {
                if (this.search_plate) this.data.push(data.plate)
                else this.data = data.speeds

                if (this.selected_region) {
                  let filter_data = this.data.filter(item => {
                    let region_item = this.region.filter(region => {
                      if (region.region === this.selected_region)
                        return region
                    })
                    let searchs_fleets = region_item.map(i => i.fleet)
                    if (searchs_fleets.includes(item.speeds[0].fleet)) return item
                  })
                  this.data = filter_data
                }

                this.data.forEach(item => {
                  let fleet = item.speeds[0].fleet

                  const arr = item.speeds.map(o => o.speedMax);
                  const max = Math.max(...arr)
                  const min = Math.min(...arr)

                  if (this.issues_fleet[fleet]) {
                    this.issues_fleet[fleet].y += item.speeds.length
                    this.issues_fleet[fleet].max.push(max)
                    this.issues_fleet[fleet].min.push(min)
                  } else {
                    this.issues_fleet[fleet] = {name: fleet, y: item.speeds.length, max: [max], min: [min]}
                  }
                  if (this.issues_cars[fleet]) {
                    this.issues_cars[fleet].push([item.plate, item.speeds.length])
                  } else {
                    this.issues_cars[fleet] = [[item.plate, item.speeds.length]]
                  }
                })

                this.chart_data_fleet = Object.values(this.issues_fleet)

                this.chart_data_fleet.forEach(item => {
                  let region_item = this.region.find(region => {
                    if (region.fleet === item.name)
                      return region
                  })
                  if (region_item) {
                    let region = region_item.region
                    if (this.issues_region[region]) {
                      this.issues_region[region].y += item.y
                    } else {
                      this.issues_region[region] = {name: region, y: item.y}
                    }
                  }
                })

                this.chart_data_region = Object.values(this.issues_region)
              }
              this.loading = false
            })
            .catch((error) => {
              this.loading = false
              console.log('error ' + error)
            })
      } else {
        this.$store.commit('setMessage', {
          message: 'Intervalo, Limite, fecha y Horas son obligatorios',
          type: 'info'
        })

        this.loading = false
      }
    },
    detailSpeeds(item) {
      this.itemSelected = []
      this.itemSelected = item
      this.viewDetailForm = true

      const arr = item.speeds.map(o => o.speedMax);
      const max = Math.max(...arr);
      const speed_info = item.speeds.filter((item, index) => {
        if (item.speedMax == max) {
          item.index = index
          return item
        }
      })
      this.itemSelected.speeds.splice(speed_info[0].index, 1)
      this.itemSelected.speeds.unshift(speed_info[0])
    },
    getPDFModal(item) {
      this.itemSelectedPDF = null
      const arr = item.speeds.map(o => o.speedMax);
      const max = Math.max(...arr);
      const speed_info = item.speeds.filter(item => {
        return item.speedMax == max
      })
      this.itemSelectedPDF = item
      this.itemSelectedPDF.speed_info = speed_info[0]
      this.viewPDFForm = true
      setTimeout(() => {
        const today = new Date(Date.now());
        this.pdf_title = `${moment(today).utc().format('DDMMYYYY')} - ${this.itemSelectedPDF.speed_info.name} - ${this.itemSelectedPDF.speed_info.fleet}`
        this.pdf_actions = true
        window.scroll(0, 0)
      }, 5000)
    },
    async getPDF() {
      this.pdf_actions = false
      this.viewPDFForm = false
      await this.$refs.html2Pdf.generatePdf()
    },
    loadExcelData() {
      this.json_data = []
      if (this.data.length) {
        this.data.forEach(item => {
          item.speeds.forEach(item_speed => {
            const row = {
              plateVehicle: item.plate,
              fleet: item_speed.fleet,
              name: item_speed.name,
              group: item_speed.group,
              startDate: item_speed.startDate,
              endDate: item_speed.endDate,
              locationStart: item_speed.locationStart,
              locationEnd: item_speed.locationEnd,
              totalSeconds: Math.round(item_speed.totalSeconds),
              speedStart: Math.round(item_speed.speedStart),
              speedMax: Math.round(item_speed.speedMax),
            }
            this.json_data.push(row)
          })
        });
      }
    },
    loadFleets() {
      this.loading = true
      this.fleet_items = []
      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }
      const url = `${process.env.VUE_APP_API_URL}/api/sutech/sutechGetCampusFiltersByBrand/`

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.ok) {
              this.fleet_items = data.fleets.map(fleet => fleet.name)
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    loadRegions() {
      this.loading = true
      this.region_items = []
      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }
      const url = `${process.env.VUE_APP_API_URL}/api/sutech/sutechGetZonesFiltersByBrand/`

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.ok) {
              this.region_items = data.zones.map(zone => zone.name)
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    loadFleetsRegions() {
      this.loading = true
      this.region = []
      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }
      const url = `${process.env.VUE_APP_API_URL}/api/sutech/getZonesAndFleetByBrands/`

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.ok) {
              this.fleet_items = data.relations
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
  },
  watch: {
    viewDetailForm: function (val) {
      if (!val) {
        this.itemSelected = null
      }
    }
  }
}
</script>
