import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
  en: {
    active: 'Active',
    admin: 'Management',
    cancel: 'Cancel',
    fleet_check: 'Fleet Check',
    home: 'Home',
    inactive: 'Inactive',
    login: 'Login',
    massive_search: 'Massive search',
    new_register: 'New register',
    no_register: 'There are not registers',
    password: 'Password',
    save: 'Save',
    search: 'Search',
    searchs: 'Searchs',
    status: 'Status',
    user: 'User',
    check_types: 'Check types',
    vehicle_types: 'Vehicle type',
  },
  es: {
    active: 'Activo',
    admin: 'Administracion',
    cancel: 'Cancelar',
    check_types: 'Items de chequeo',
    control_distance: 'Control de recorrido',
    covid_poll: ' Encuesta Covid',
    fleet_check: 'Fleet Check',
    fleet_control: 'Control de flota',
    fuel_control: 'Control de tanqueo',
    home: 'Inicio',
    inactive: 'Inactivo',
    limit_speed: 'Control de velocidad',
    login: 'Ingresar',
    massive_inquiries: 'Consultas',
    massive_search: 'Consulta masiva',
    new_register: 'Nuevo registro',
    no_register: 'No hay registros disponibles',
    password: 'Contraseña',
    PriorityAlta: 'Prioridad Alta',
    PriorityMedia: 'Prioridad Media',
    PriorityBaja: 'Prioridad Baja',
    roles: 'Roles',
    save: 'Guardar',
    search: 'Consultar',
    status: 'Estado',
    user: 'Usuario',
    users: 'Usuario',
    vehicles: 'Vehiculos',
    vehicle_types: 'Tipos de vehículo',
  }
}

// eslint-disable-next-line no-unused-vars
const i18n = new VueI18n({
  locale: 'es', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages // set locale messages
})

export default i18n
