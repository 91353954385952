<template>
  <div>
    <div class="md:flex my-6">
      <div class="w-full md:w-1/2">
        <bar-chart :chartdata="chartdataBar" :data_text="data_text" class="mx-4 mb-4"></bar-chart>
      </div>
      <div class="w-full md:w-1/2">
        <v-card
            class="mx-auto overflow-y-auto"
            max-width="500"
            max-height="400"
            v-scroll.self="onScroll"
        >
          <v-toolbar
              color="primary"
              dark
          >
            <v-toolbar-title>Vehiculos no han hecho tanqueos</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>

          <v-list>
            <v-list-item-content
                v-for="(item,index) in data_vehicles"
                :key="index"
                no-action
            >
              <v-list-item-title class="text-primary text-body2 mx-6" v-text="item"></v-list-item-title>
            </v-list-item-content>
          </v-list>
        </v-card>
      </div>
    </div>
    <div class="w-full md:flex justify-center">
      <v-card shadow="always" class="relative rounded-md bg-primary border-none w-3/12">
        <div class="flex py-2 pr-2">
          <div class="w-1/3 text-center self-center text-subtitle">
            <v-icon x-large color="#ffffff">mdi-gas-station</v-icon>
          </div>
          <div class="w-2/3 self-center text-center">
            <div class="text-white text-body">Tanqueos de la semana</div>
            <div class="text-white text-subtitle">{{ data_week }}</div>
          </div>
        </div>
      </v-card>
      <v-card shadow="always" class="relative rounded-md bg-success border-none w-3/12 ml-6">
        <div class="flex py-2 pr-2">
          <div class="w-1/3 text-center self-center text-subtitle">
            <v-icon x-large color="#ffffff">mdi-fuel</v-icon>
          </div>
          <div class="w-2/3 self-center text-center">
            <div class="text-white text-body">Tanqueos del mes</div>
            <div class="text-white text-subtitle">{{ data_mounth }}</div>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import BarChart from '../components/charts/Bar'

export default {
  components: {BarChart},
  name: "FuelControlHome",
  data() {
    return {
      chartdataBar: [],
      data_text: {
        title: 'Tanqueos por mes',
        ytitle: 'Cantidad de tanqueos',
        xtitle: 'Meses'
      },
      data_week: 0,
      data_mounth: 0,
      data_vehicles: [],
      scrollInvoked: 0,
    }
  },
  mounted() {
    this.getDataBar()
    this.getDataWeek()
    this.getDataNoFuel()
  },
  methods: {
    getDataBar() {
      this.loading = true

      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }
      let url = `${process.env.VUE_APP_API_URL}/api/sutech/fuelsByMonthOfTheYearByBrands/`

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.data) {
              Object.entries(data.data).forEach(([key, value]) => {
                this.chartdataBar.push({name: key, y: value})
              });
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    getDataWeek() {
      this.loading = true

      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }
      let url = `${process.env.VUE_APP_API_URL}/api/sutech/lastWeekFuelsByBrands/`

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.ok) {
              this.data_week = data.count
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    getDataMonth() {
      this.loading = true

      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }
      let url = `${process.env.VUE_APP_API_URL}/api/sutech/lastMonthsFuelsByBrands/`

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.ok) {
              this.data_mounth = data.count
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    getDataNoFuel() {
      this.loading = true

      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }
      let url = `${process.env.VUE_APP_API_URL}/api/sutech/platesWithMonthlyFuelsByBrands/`

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.ok) {
              this.data_vehicles = data.result.inspectionsFalse
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    onScroll () {
      this.scrollInvoked++
    },
  }
}
</script>
