<template>
  <div>
    <sub-header :name="$t('roles')" @newRegister="newRegister"></sub-header>
    <div v-if="loading">
      <loading></loading>
    </div>
    <div v-else class="flex flex-wrap">
      <div v-if="data.length" class="w-full flex flex-wrap">
        <div :key="index" class="w-full md:w-1/3 px-2 my-2 md:my-0 cursor-pointer"
             v-for="(item,index) in data">
          <v-card shadow="always" class="relative rounded-md border-none">
            <v-menu v-if="item.name !== 'Administrador de marca'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    class="absolute top-0 right-0 pt-2 pr-1"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item link>
                  <v-list-item-title @click="assignPermissions(item)">Asignar permisos</v-list-item-title>
                </v-list-item>
                <v-list-item link>
                  <v-list-item-title @click="editRole(item, index)">Editar</v-list-item-title>
                </v-list-item>
                <v-list-item link>
                  <v-list-item-title @click="deleteItemConfirm(item, index)">Eliminar</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <div class="flex py-2 pr-2">
              <div class="w-1/3 text-center self-center text-subtitle">
                <v-icon x-large color="primary">{{ getIcon(item.name) }}</v-icon>
              </div>
              <div class="w-2/3 self-center text-center">
                <div class="text-body text-gray-2">{{ item.name }}</div>
              </div>
            </div>
          </v-card>
        </div>
      </div>
      <div class="w-full" v-else>
        <empty-state @newRegister="newRegister"/>
      </div>
    </div>
    <v-dialog
        v-model="viewCreateForm"
        max-width="600px"
    >
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline">Nuevo Rol</span>
        </v-card-title>
        <div class="mx-6">
          <v-text-field
              :rules="rules"
              label="Rol"
              v-model="role_name"
          >
            <v-icon
                slot="prepend"
            >
              mdi-account-hard-hat
            </v-icon>
          </v-text-field>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="viewCreateForm = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
              v-if="item_edit"
              color="primary"
              text
              :disabled="loading"
              @click="updateRole"
          >
            {{ $t('save') }}
          </v-btn>
          <v-btn v-else
                 color="primary"
                 text
                 :disabled="loading"
                 @click="saveRole"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="delete_dialog"
        persistent
        max-width="500"
    >
      <v-card class="w-full">
        <div class="headline m-6 text-gray-2 text-body">
          Esta seguro que desea eliminar este elemento ?
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="delete_dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
              color="primary"
              @click="deleteRole()"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="permissions_dialog"
        max-width="500"
    >
      <v-card class="w-full p-4">
        <div class="w-full flex">
          <div class="w-full md:w-1/3">
          </div>
          <div class="w-full md:w-2/3 flex mb-4">
            <div class="w-1/3 text-body2 text-gray-2 font-bold">Ver</div>
            <div class="w-1/3 text-body2 text-gray-2 font-bold">Crear</div>
            <div class="w-1/3 text-body2 text-gray-2 font-bold">Borrar</div>
          </div>
        </div>
        <div class="w-full flex text-primary text-body-1 py-4 border-b border-solid border-primary">
          <v-icon class="text-primary mx-2">mdi-table-search</v-icon>
          Consultas
        </div>
        <div class="w-full flex border-b border-gray-3 border-solid">
          <div class="w-full md:w-1/3 text-caption text-gray-2 self-center">
            Consultas
          </div>
          <div class="w-full md:w-2/3 flex">
            <div class="w-1/3">
              <v-checkbox
                  disabled
                  v-model="consultingView"
              ></v-checkbox>
            </div>
            <div class="w-1/3">
              <v-checkbox
                  v-model="consultingCreate"
              ></v-checkbox>
            </div>
            <div class="w-1/3">
              <v-checkbox
                  disabled
                  v-model="consultingDelete"
              ></v-checkbox>
            </div>
          </div>
        </div>
        <div class="w-full flex text-primary text-body-1 py-4 border-b border-solid border-primary">
          <v-icon class="text-primary mx-2">mdi-order-bool-ascending-variant</v-icon>
          Chequeo de flota
        </div>
        <div class="w-full flex border-b border-gray-3 border-solid">
          <div class="w-full md:w-1/3 text-caption text-gray-2 self-center">
            Items chequeo
          </div>
          <div class="w-full md:w-2/3 flex">
            <div class="w-1/3">
              <v-checkbox
                  v-model="fleetItemsView"
              ></v-checkbox>
            </div>
            <div class="w-1/3">
              <v-checkbox
                  v-model="fleetItemsCreate"
              ></v-checkbox>
            </div>
            <div class="w-1/3">
              <v-checkbox
                  v-model="fleetItemsDelete"
              ></v-checkbox>
            </div>
          </div>
        </div>
        <div class="w-full flex border-b border-gray-3 border-solid">
          <div class="w-full md:w-1/3 text-caption text-gray-2 self-center">
            Inspecciones
          </div>
          <div class="w-full md:w-2/3 flex">
            <div class="w-1/3">
              <v-checkbox
                  v-model="fleetInspectionView"
              ></v-checkbox>
            </div>
            <div class="w-1/3">
              <v-checkbox
                  v-model="fleetInspectionCreate"
              ></v-checkbox>
            </div>
            <div class="w-1/3">
              <v-checkbox
                  v-model="fleetInspectionDelete"
              ></v-checkbox>
            </div>
          </div>
        </div>
        <div class="w-full flex border-b border-gray-3 border-solid">
          <div class="w-full md:w-1/3 text-caption text-gray-2 self-center">
            Novedades
          </div>
          <div class="w-full md:w-2/3 flex">
            <div class="w-1/3">
              <v-checkbox
                  v-model="fleetNoveltiesView"
              ></v-checkbox>
            </div>
            <div class="w-1/3">
              <v-checkbox
                  v-model="fleetNoveltiesCreate"
              ></v-checkbox>
            </div>
            <div class="w-1/3">
              <v-checkbox
                  v-model="fleetNoveltiesDelete"
              ></v-checkbox>
            </div>
          </div>
        </div>
        <div class="w-full flex text-primary text-body-1 py-4 border-b border-solid border-primary">
          <v-icon class="text-primary mx-2">mdi-tools</v-icon>
          Control de flota
        </div>
        <div class="w-full flex border-b border-gray-3 border-solid">
          <div class="w-full md:w-1/3 text-caption text-gray-2 self-center">
            Control Velocidad
          </div>
          <div class="w-full md:w-2/3 flex">
            <div class="w-1/3">
              <v-checkbox
                  disabled
              ></v-checkbox>
            </div>
            <div class="w-1/3">
              <v-checkbox
                  v-model="speedLimitView"
              ></v-checkbox>
            </div>
            <div class="w-1/3">
              <v-checkbox
                  disabled
              ></v-checkbox>
            </div>
          </div>
        </div>
        <div class="w-full flex border-b border-gray-3 border-solid">
          <div class="w-full md:w-1/3 text-caption text-gray-2 self-center">
            Control Recorrido
          </div>
          <div class="w-full md:w-2/3 flex">
            <div class="w-1/3">
              <v-checkbox
                  disabled
              ></v-checkbox>
            </div>
            <div class="w-1/3">
              <v-checkbox
                  v-model="speedLimitView"
              ></v-checkbox>
            </div>
            <div class="w-1/3">
              <v-checkbox
                  disabled
              ></v-checkbox>
            </div>
          </div>
        </div>
        <div class="w-full flex border-b border-gray-3 border-solid">
          <div class="w-full md:w-1/3 text-caption text-gray-2 self-center">
            Control Tanqueo
          </div>
          <div class="w-full md:w-2/3 flex">
            <div class="w-1/3">
              <v-checkbox
                  v-model="fuelControlView"
              ></v-checkbox>
            </div>
            <div class="w-1/3">
              <v-checkbox
                  v-model="fuelControlCreate"
              ></v-checkbox>
            </div>
            <div class="w-1/3">
              <v-checkbox
                  v-model="fuelControlDelete"
              ></v-checkbox>
            </div>
          </div>
        </div>
        <div class="w-full flex text-primary text-body-1 py-4 border-b border-solid border-primary">
          <v-icon class="text-primary mx-2">mdi-tools</v-icon>
          Administracion
        </div>
        <div class="w-full flex border-b border-gray-3 border-solid">
          <div class="w-full md:w-1/3 text-caption text-gray-2 self-center">
            Usuarios
          </div>
          <div class="w-full md:w-2/3 flex">
            <div class="w-1/3">
              <v-checkbox
                  v-model="adminUsersView"
              ></v-checkbox>
            </div>
            <div class="w-1/3">
              <v-checkbox
                  v-model="adminUsersCreate"
              ></v-checkbox>
            </div>
            <div class="w-1/3">
              <v-checkbox
                  v-model="adminUsersDelete"
              ></v-checkbox>
            </div>
          </div>
        </div>
        <div class="w-full flex border-b border-gray-3 border-solid">
          <div class="w-full md:w-1/3 text-caption text-gray-2 self-center">
            Roles
          </div>
          <div class="w-full md:w-2/3 flex">
            <div class="w-1/3">
              <v-checkbox
                  v-model="adminBrandsView"
              ></v-checkbox>
            </div>
            <div class="w-1/3">
              <v-checkbox
                  v-model="adminBrandsCreate"
              ></v-checkbox>
            </div>
            <div class="w-1/3">
              <v-checkbox
                  v-model="adminBrandsDelete"
              ></v-checkbox>
            </div>
          </div>
        </div>
        <div class="w-full flex border-b border-gray-3 border-solid">
          <div class="w-full md:w-1/3 text-caption text-gray-2 self-center">
            Vehiculos
          </div>
          <div class="w-full md:w-2/3 flex">
            <div class="w-1/3">
              <v-checkbox
                  disabled
              ></v-checkbox>
            </div>
            <div class="w-1/3">
              <v-checkbox
                  v-model="adminTypeVehicle"
              ></v-checkbox>
            </div>
            <div class="w-1/3">
              <v-checkbox
                  disabled
              ></v-checkbox>
            </div>
          </div>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="permissions_dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
              color="primary"
              @click="assignPermissionsUpdate()"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Roles",
  data() {
    return {
      viewCreateForm: false,
      rules: [
        value => !!value || 'Este campo es obligatorio',
      ],
      loading: false,
      data: [],
      role_name: null,
      item_edit: null,
      item_edit_index: null,
      delete_dialog: false,
      item_delete_index: null,
      permissions: this.$store.state.permissions,
      role_select: null,
      role_permission: null,
      permissions_dialog: false,
      consultingCreate: false,
      consultingView: false,
      consultingDelete: false,
      fuelControlCreate: false,
      fuelControlView: false,
      fuelControlDelete: false,
      fleetItemsCreate: false,
      fleetItemsView: false,
      fleetItemsDelete: false,
      fleetInspectionCreate: false,
      fleetInspectionView: false,
      fleetInspectionDelete: false,
      fleetNoveltiesCreate: false,
      fleetNoveltiesView: false,
      fleetNoveltiesDelete: false,
      adminBrandsCreate: false,
      adminBrandsView: false,
      adminBrandsDelete: false,
      adminProcess: false,
      adminTypeVehicle: false,
      adminUsersCreate: false,
      adminUsersView: false,
      adminUsersDelete: false,
      speedLimitView: false,
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    newRegister() {
      this.role_name = null
      this.item_edit = null
      this.item_edit_index = null
      this.viewCreateForm = true;
    },
    loadData() {
      this.loading = true

      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }

      let url = `${process.env.VUE_APP_API_URL}/api/sutech/getRolesByBrands/`
      this.data = []
      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.ok) {
              this.data = data.roles
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    saveRole() {
      this.loading = true
      const body = this.$qs.stringify({
        name: this.role_name,
      })

      let config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          token: localStorage.sutech_token,
        }
      }

      this.$axios.post(`${process.env.VUE_APP_API_URL}/api/sutech/addRole/`, body, config)
          .then(({data}) => {
            if (data.ok) {
              this.viewCreateForm = false
              this.loadData()
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            this.viewCreateForm = false
            console.log('error ' + error)
          })
    },
    updateRole() {
      this.loading = true
      const body = this.$qs.stringify({
        name: this.role_name,
      })

      let config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          token: localStorage.sutech_token,
        }
      }
      this.$axios.put(`${process.env.VUE_APP_API_URL}/api/sutech/updateRoleById/${this.item_edit}`, body, config)
          .then(({data}) => {
            if (data.ok) {
              this.viewCreateForm = false
              this.loadData()
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            this.viewCreateForm = false
            console.log('error ' + error)
          })
    },
    editRole(item, index) {
      this.item_edit = item._id
      this.item_edit_index = index
      this.role_name = item.name
      this.viewCreateForm = true;
    },
    deleteItemConfirm(item, index) {
      this.item_delete = item
      this.item_delete_index = index
      this.delete_dialog = true
    },
    deleteRole() {
      this.loading = true
      let config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          token: localStorage.sutech_token,
        }
      }

      this.$axios.delete(`${process.env.VUE_APP_API_URL}/api/sutech/deleteRoleById/${this.item_delete._id}`, config)
          .then(({data}) => {
            if (data.ok) {
              this.data.splice(this.item_delete_index, 1);
              this.item_delete = null
              this.item_delete_index = null
              this.delete_dialog = false
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    getIcon(name) {
      switch (name) {
        case 'Administrador':
          return 'mdi-account-star'
        case 'Usuario':
          return 'mdi-account'
        case 'Supervisor':
          return 'mdi-account-tie'
        default:
          return 'mdi-account-hard-hat'
      }
    },
    async assignPermissions(item) {
      this.clearRoles()
      this.loading = true
      this.role_select = item

      this.role_permission = await this.getPermissionsByRole()

      this.consultingCreate = this.role_permission[0].consultingCreate
      this.consultingView = this.role_permission[0].consultingView
      this.consultingDelete = this.role_permission[0].consultingDelete
      this.fleetItemsCreate = this.role_permission[0].fleetItemsCreate
      this.fleetItemsView = this.role_permission[0].fleetItemsView
      this.fleetItemsDelete = this.role_permission[0].fleetItemsDelete
      this.fleetInspectionCreate = this.role_permission[0].fleetInspectionCreate
      this.fleetInspectionView = this.role_permission[0].fleetInspectionView
      this.fleetInspectionDelete = this.role_permission[0].fleetInspectionDelete
      this.fleetNoveltiesCreate = this.role_permission[0].fleetNoveltiesCreate
      this.fleetNoveltiesView = this.role_permission[0].fleetNoveltiesView
      this.fleetNoveltiesDelete = this.role_permission[0].fleetNoveltiesDelete
      this.adminBrandsView = this.role_permission[0].adminBrandsView
      this.adminBrandsCreate = this.role_permission[0].adminBrandsCreate
      this.adminBrandsDelete = this.role_permission[0].adminBrandsDelete
      this.adminProcess = this.role_permission[0].adminProcess
      this.adminTypeVehicle = this.role_permission[0].adminTypeVehicle
      this.adminUsersView = this.role_permission[0].adminUsersView
      this.adminUsersCreate = this.role_permission[0].adminUsersCreate
      this.adminUsersDelete = this.role_permission[0].adminUsersDelete
      this.speedLimitView = this.role_permission[0].speedLimitView
      this.permissions_dialog = true
      this.fuelControlView = this.role_permission[0].fuelControlView
      this.fuelControlCreate = this.role_permission[0].fuelControlCreate
      this.fuelControlDelete = this.role_permission[0].fuelControlDelete
    },
    async assignPermissionsUpdate() {
      this.loading = true
      const body = this.$qs.stringify({
        consultingCreate: this.consultingCreate,
        consultingView: this.consultingView,
        consultingDelete: this.consultingDelete,
        fleetItemsCreate: this.fleetItemsCreate,
        fleetItemsView: this.fleetItemsView,
        fleetItemsDelete: this.fleetItemsDelete,
        fleetInspectionCreate: this.fleetInspectionCreate,
        fleetInspectionView: this.fleetInspectionView,
        fleetInspectionDelete: this.fleetInspectionDelete,
        fleetNoveltiesCreate: this.fleetNoveltiesCreate,
        fleetNoveltiesView: this.fleetNoveltiesView,
        fleetNoveltiesDelete: this.fleetNoveltiesDelete,
        adminBrandsView: this.adminBrandsView,
        adminBrandsCreate: this.adminBrandsCreate,
        adminBrandsDelete: this.adminBrandsDelete,
        adminProcess: this.adminProcess,
        adminTypeVehicle: this.adminTypeVehicle,
        adminUsersView: this.adminUsersView,
        adminUsersCreate: this.adminUsersCreate,
        adminUsersDelete: this.adminUsersDelete,
        speedLimitView: this.speedLimitView,
        fuelControlView: this.fuelControlView,
        fuelControlCreate: this.fuelControlCreate,
        fuelControlDelete: this.fuelControlDelete,
      })

      let config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          token: localStorage.sutech_token,
          role: this.role_select._id
        }
      }
      this.$axios.put(`${process.env.VUE_APP_API_URL}/api/sutech/updatePermisionsByRole`, body, config)
          .then(({data}) => {
            if (data.ok) {
              this.permissions_dialog = false
              this.loadData()
              this.clearRoles()
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    clearRoles() {
      this.consultingCreate = false
      this.consultingView = false
      this.consultingDelete = false
      this.fleetItemsCreate = false
      this.fleetItemsView = false
      this.fleetItemsDelete = false
      this.fleetInspectionCreate = false
      this.fleetInspectionView = false
      this.fleetInspectionDelete = false
      this.fleetNoveltiesCreate = false
      this.fleetNoveltiesView = false
      this.fleetNoveltiesDelete = false
      this.adminBrandsView = false
      this.adminBrandsCreate = false
      this.adminBrandsDelete = false
      this.adminProcess = false
      this.adminTypeVehicle = false
      this.adminUsersView = false
      this.adminUsersCreate = false
      this.adminUsersDelete = false
      this.speedLimitView = false
      this.fuelControlView = false
      this.fuelControlCreate = false
      this.fuelControlDelete = false
    },

    getPermissionsByRole() {
      return new Promise(resolve => {
        let config = {
          headers: {
            token: localStorage.sutech_token,
            role: this.role_select._id
          }
        }
        let url = `${process.env.VUE_APP_API_URL}/api/sutech/getPermisionByRole/`
        this.$axios.get(url, config)
            .then(({data}) => {
              if (data.ok) {
                resolve(data.permisions);
              }
              this.loading = false
            })
            .catch((error) => {
              this.loading = false
              console.log('error ' + error)
            })
      });
    },
  },
}
</script>
