<template>
  <div>
    <sub-header name="Encuestas Covid" :is_master="false"></sub-header>
    <div v-if="loading">
      <loading></loading>
    </div>
    <div v-else class="flex flex-wrap">
      <div class="w-full border border-primary border-solid mx-4 my-4">
        <div class="md:flex items-center">
          <v-text-field
              class="px-4"
              label="Nombre"
              v-model="search_name"
          >
            <v-icon
                slot="prepend"
            >
              mdi-user
            </v-icon>
          </v-text-field>
          <v-text-field
              class="px-4"
              label="Documento"
              v-model="search_document"
          >
            <v-icon
                slot="prepend"
            >
              mdi-card
            </v-icon>
          </v-text-field>
          <v-btn
              dark
              color="primary"
              text
              @click="loadData"
          >
            Buscar
          </v-btn>
          <v-btn
              dark
              color="primary"
              text
              @click="clearSearch"
          >
            Limpiar
          </v-btn>
        </div>
      </div>
      <v-data-table
          v-if="data.length"
          :headers="headers"
          :items="data"
          class="elevation-1 w-full mx-4 my-4"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="detailInspection(item)"
          >
            mdi-information-outline
          </v-icon>
        </template>
      </v-data-table>
      <div class="w-full" v-else>
        <empty-state/>
      </div>
    </div>
    <div v-if="itemSelected">
      <v-dialog
          v-model="viewDetailForm"
          max-width="800px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Detalle de la prueba</span>
          </v-card-title>
          <div class="mx-6">
            <div class="w-full">
              <div class="text-gray-3 text-body2"><span
                  class="text-primary">Nombre: </span>{{ itemSelected.inspection.driverName }}
              </div>
            </div>
          </div>
          <div class="mx-6 md:flex">
            <div class="w-full md:w-1/2">
              <div class="text-gray-3 text-body2"><span
                  class="text-primary">Documento: </span>{{ itemSelected.inspection.driverDocument }}
              </div>
            </div>
            <div class="w-full md:w-1/2">
              <div class="text-gray-3 text-body2"><span
                  class="text-primary">Fecha: </span>{{ itemSelected.inspection.currentDate }}
              </div>
            </div>
          </div>
          <div
              class="bg-secondary text-center text-body2 text-gray-4 mx-6 my-4 border-b border-solid border-gray p-2">
            Sintomas
          </div>
          <div class="border-b border-gray-3 mt-4 mx-6" :key="index" v-for="(item,index) in itemSelected.items">
            <div class="md:flex">
              <div class="w-full md:w-1/2">
                <div class="text-gray-3 text-body2"><span
                    class="text-primary">Sintoma: </span>{{ item.name }}
                </div>
              </div>
              <div class="w-full md:w-1/2">
                <div :class="getColorStatus(item.state)" class="text-body2"><span
                    class="text-primary">Estado: </span>{{ clasification[item.state] }}
                </div>
              </div>
            </div>
          </div>
          <div class="mx-6">
            <div class="w-full my-3">
              <div class="text-gray-3 text-body2">
                  ¿ESTUVO EN CONTACTO CON PERSONAS CATALOGADAS COMO CASOS SOSPECHOSOS PARA COVID-19 EN LOS ÚLTIMOS 30 DÍAS (FAMILIARES,AMIGOS,CONOCIDO)
              </div>
              <span :class="getColorStatus(itemSelected.contact_covid)" class="text-body2 mx-4">{{ itemSelected.contact_covid }}</span>
            </div>
            <div class="w-full my-3">
              <div class="text-gray-3 text-body2">
                ALGUNA DE LAS PERSONAS CON LA CUAL CONVIVE HA SIDO DIAGNOSTICADO CON COVID-19
              </div>
              <span :class="getColorStatus(itemSelected.tests_covid)" class="text-body2 mx-4">{{ itemSelected.diagnose_covid }}</span>
            </div>
            <div class="w-full my-3">
              <div class="text-gray-3 text-body2">
                ¿LE HAN PRACTICADO PRUEBAS DE COVID-19 EN LOS ÚLTIMOS 40 DÍAS?
              </div>
              <span :class="getColorStatus(itemSelected.tests_covid)" class="text-body2 mx-4">{{ itemSelected.tests_covid }}</span>
            </div>
            <div class="w-full my-3">
              <div class="text-gray-3 text-body2">
                Resultado
              </div>
              <span :class="getColorStatus(itemSelected.test_result_covid)" class="text-body2 mx-4">{{ itemSelected.test_result_covid }}</span>
            </div>
            <div class="w-full my-3">
              <div class="text-gray-3 text-body2">
                ¿ACTUALMENTE SE ENCUENTRA CON INCAPACIDAD?
              </div>
              <span :class="getColorStatus(itemSelected.incapacity_covid)" class="text-body2 mx-4">{{ itemSelected.incapacity_covid }}</span>
            </div>
            <div class="w-full my-3">
              <div class="text-gray-3 text-body2">
                DIAGNOSTICO Y TIEMPO DE INCAPACIDAD
              </div>
              <span class="text-body2 mx-4">{{ itemSelected.incapacity_details_covid }}</span>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "CovidPoll",
  data() {
    return {
      loading: false,
      search_name: null,
      search_document: null,
      data: [],
      headers: [
        {
          text: 'Nombre',
          align: 'start',
          value: 'driverName',
        },
        {text: 'Documento', value: 'driverDocument'},
        {text: 'Fecha', value: 'currentDate'},
        {text: 'Acciones', value: 'actions', sortable: false},
      ],
      itemSelected: null,
      viewDetailForm: false,
      clasification: {
        B: 'Bueno',
        R: 'Regular',
        M: 'Malo',
        S: 'Si',
        N: 'No',
        pending: 'Pendiente',
      },
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.loading = true

      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }

      let url = `${process.env.VUE_APP_API_URL}/api/sutech/getInspectionsByFilters/`

      if (this.search_name || this.search_document) {
        if (this.search_name) url = `${url}?driverName=${this.search_name}`
        if (this.search_document && !this.search_name) url = `${url}?driverDocument=${this.search_document}`
        else if (this.search_name && this.search_document) url = `${url}&driverDocument=${this.search_document}`
      }

      this.data = []

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.ok) {
              if (data.inspections.length) {
                data.inspections.forEach(item => {
                  item.currentDate = moment(item.currentDate).utc().format('DD/MM/YYYY')
                  if (item.typeInspections === 'Encuesta Covid') this.data.push(item)
                })
              }
              this.loading = false
            } else {
              this.loading = false
            }
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    clearSearch() {
      this.search_document = null
      this.search_name = null
    },
    async detailInspection(item) {
      this.itemSelected = null
      await this.getInspection(item)
      this.viewDetailForm = true
    },
    getInspection(item) {
      return new Promise(resolve => {
        this.loading = true

        let config = {
          headers: {
            token: localStorage.sutech_token,
            id: item._id
          }
        }

        this.$axios.get(`${process.env.VUE_APP_API_URL}/api/sutech/getInspetionsById/`, config)
            .then(({data}) => {
              if (data.ok) {
                this.itemSelected = {
                  inspection: item,
                  items: data.reportsDB[0].items,
                  incapacity_details_covid: data.reportsDB[0].incapacity_details_covid,
                  incapacity_covid: data.reportsDB[0].incapacity_covid,
                  test_result_covid: data.reportsDB[0].test_result_covid,
                  tests_covid: data.reportsDB[0].tests_covid,
                  diagnose_covid: data.reportsDB[0].diagnose_covid,
                  contact_covid: data.reportsDB[0].contact_covid,
                }
                this.loading = false
                resolve(true);
              }
            })
            .catch((error) => {
              this.loading = false
              console.log('error ' + error)
            })
      })
    },
    getColorStatus(item) {
      switch (item) {
        case 'B':
          return 'text-success'
        case 'R':
          return 'text-warning'
        case 'M':
          return 'text-danger'
        case 'S':
          return 'text-danger'
        case 'N':
          return 'text-success'
        default:
          'text-gray-3'
      }
    },
  },
}
</script>
