import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import '@/assets/css/tailwind.css'
import i18n from './plugins/i18n.js'
import axios from 'axios'
import querystring from 'querystring'
import HighchartsVue from "highcharts-vue";
import moment from "moment";

// Our Style
import './assets/sass/utils.scss'
import JsonExcel from "vue-json-excel";

Vue.config.productionTip = false

Vue.prototype.$axios = axios
Vue.prototype.$qs = querystring

Vue.component('sub-header', require('@/components/layout/SubHeader').default)
Vue.component('empty-state', require('@/components/layout/EmptyState').default)
Vue.component('loading', require('@/components/layout/Loading').default)
Vue.component("downloadExcel", JsonExcel);
Vue.component('notifications-dropdown', require('@/components/layout/NotificationsDropDown').default)
Vue.component('map-box', require('@/components/maps/MapBox').default)

Vue.use(HighchartsVue);
Vue.use(moment);

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app')
