<template>
  <div>
    <sub-header :name="$t('check_types')" @newRegister="newRegister" :is_master="$store.state.user_permissions.includes('fleetItemsCreate')"></sub-header>
    <div v-if="loading">
      <loading></loading>
    </div>
    <div v-else class="flex flex-wrap">
      <div class="w-full md:flex mx-4 my-4 items-center border border-primary border-solid">
        <v-text-field
            class="px-4"
            label="Nombre / Descripcion"
            v-model="search_name_description"
        >
          <v-icon
              slot="prepend"
          >
            mdi-ab-testing
          </v-icon>
        </v-text-field>
        <v-select
            class="px-4"
            v-model="search_priority"
            :items="priority_items"
            label="Prioridad"
            clearable
        >
          <v-icon
              slot="prepend"
          >
            mdi-sort-bool-descending-variant
          </v-icon>
        </v-select>
        <v-btn
            dark
            color="primary"
            text
            @click="loadData"
        >
          Buscar
        </v-btn>
      </div>
      <v-data-table
          v-if="data.length"
          :headers="headers"
          :items="data"
          class="elevation-1 w-full mx-4 my-4"
      >
        <template v-slot:item.priority="{ item }">
          <v-chip
              :color="getColor(item.priority)"
              dark
          >
            {{ item.priority }}
          </v-chip>
        </template>
        <template v-slot:item.state="{ item }">
          <div :class="item.state ? 'text-success' : 'text-danger'">
            {{ item.state ? 'Activo' : 'Inactivo' }}
          </div>
        </template>
        <template v-if="$store.state.user_permissions.includes('fleetItemsCreate') || $store.state.user_permissions.includes('fleetItemsUpate')" v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
              v-if="$store.state.user_permissions.includes('fleetItemsCreate')"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              @click="deleteItemConfirm(item)"
              v-if="$store.state.user_permissions.includes('fleetItemsUpate')"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <div v-else class="w-full">
        <empty-state @newRegister="newRegister"/>
      </div>
    </div>
    <v-dialog
        v-model="viewCreateForm"
        max-width="600px"
    >
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline">Nuevo tipo de chequeo</span>
        </v-card-title>
        <div class="mx-6">
          <v-text-field
              :rules="rules"
              label="Nombre"
              v-model="check_type_name"
          >
            <v-icon
                slot="prepend"
            >
              mdi-check-box-multiple-outline
            </v-icon>
          </v-text-field>
          <v-text-field
              :rules="rules"
              label="Descripción"
              v-model="check_type_description"
          >
            <v-icon
                slot="prepend"
            >
              mdi-text-box-check-outline
            </v-icon>
          </v-text-field>
          <v-select
              :rules="rules"
              v-model="check_type_priority"
              :items="priority_items"
              label="Prioridad"
          >
            <v-icon
                slot="prepend"
            >
              mdi-sort-bool-descending-variant
            </v-icon>
          </v-select>
          <v-select
              :rules="rules"
              v-model="check_type_type"
              :items="type_items"
              label="Tipo"
          >
            <v-icon
                slot="prepend"
            >
              mdi-format-list-bulleted-type
            </v-icon>
          </v-select>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="viewCreateForm = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
              v-if="item_edit"
              color="primary"
              text
              @click="updateCheckType"
              :disabled="loading"
          >
            {{ $t('save') }}
          </v-btn>
          <v-btn v-else
                 color="primary"
                 text
                 @click="saveCheckType"
                 :disabled="loading"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="delete_dialog"
        persistent
        max-width="500"
    >
      <v-card class="w-full">
        <div class="headline m-6 text-gray-2 text-body">
          Esta seguro que desea eliminar este elemento ?
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="delete_dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
              color="primary"
              @click="deleteItem(item_delete)"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "CheckType",
  mounted() {
    this.loadData()
  },
  data() {
    return {
      loading: false,
      viewCreateForm: false,
      data: [],
      rules: [
        value => !!value || 'Este campo es obligatorio',
      ],
      headers: [
        {
          text: 'Nombre',
          align: 'start',
          value: 'name',
        },
        {text: 'Prioridad', value: 'priority'},
        {text: 'Estado', value: 'state'},
        {text: 'Descripcion', value: 'description'},
        {text: 'Acciones', value: 'actions', sortable: false},
      ],
      priority_items: ['ALTA', 'MEDIA', 'BAJA'],
      type_items: ['select', 'text'],
      check_type_name: null,
      check_type_description: null,
      check_type_priority: null,
      check_type_id: null,
      check_type_type: null,
      item_edit: false,
      search_name_description: null,
      search_priority: null,
      item_delete: null,
      delete_dialog: false
    }
  },
  methods: {
    getColor(priority) {
      if (priority == 'ALTA') return 'red'
      else if (priority == 'MEDIA') return 'orange'
      else return 'green'
    },
    newRegister() {
      this.viewCreateForm = true;
    },
    loadData() {
      this.loading = true

      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }

      let url = `${process.env.VUE_APP_API_URL}/api/sutech/getItemsCheckByFilters?start=0&limit=1000`

      if (this.search_name_description || this.search_priority) {

        if (this.search_name_description) url = `${url}?name=${this.search_name_description}`
        if (this.search_priority && !this.search_name_description) url = `${url}?priority=${this.search_priority}`
        else if (this.search_priority && this.search_name_description) url = `${url}&priority=${this.search_priority}`

      }

      this.data = []
      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.ok) {
              data.checkItems.forEach(item => {
                this.data.push(item)
              })
              this.loading = false
            } else {
              this.loading = false
            }
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    editItem(item) {
      this.check_type_name = item.name
      this.check_type_description = item.description
      this.check_type_priority = item.priority
      this.check_type_id = item._id
      this.check_type_type = item.type
      this.item_edit = true
      this.viewCreateForm = true
    },
    deleteItem(item) {
      this.loading = true
      let config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          token: localStorage.sutech_token,
        }
      }

      this.$axios.delete(`${process.env.VUE_APP_API_URL}/api/sutech/deleteItemsById/id=${item._id}`, config)
          .then(({data}) => {
            if (data.ok) {
              this.loading = false
              this.data = this.data.filter(value => {
                return value._id !== item._id
              })
              this.delete_dialog = false
              this.item_delete = null
            } else {
              this.loading = false
            }
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    updateCheckType() {
      if (this.check_type_name && this.check_type_description && this.check_type_priority) {
        this.loading = true
        const body = this.$qs.stringify({
          name: this.check_type_name,
          description: this.check_type_description,
          priority: this.check_type_priority,
          state: true,
          type: this.check_type_type,
        })
        let config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            token: localStorage.sutech_token,
          }
        }

        this.$axios.put(`${process.env.VUE_APP_API_URL}/api/sutech/updateItemsById/id=${this.check_type_id}`, body, config)
            .then(({data}) => {
              if (data.ok) {
                this.viewCreateForm = false
                this.loading = false
                this.loadData()
              } else {
                this.loading = false
              }
            })
            .catch((error) => {
              this.loading = false
              this.viewCreateForm = false
              console.log('error ' + error)
            })
      }
    },
    saveCheckType() {
      if (this.check_type_name && this.check_type_description && this.check_type_priority) {
        this.loading = true
        const body = this.$qs.stringify({
          name: this.check_type_name,
          description: this.check_type_description,
          priority: this.check_type_priority,
          type: this.check_type_type,
        })

        let config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            token: localStorage.sutech_token,
          }
        }

        this.$axios.post(`${process.env.VUE_APP_API_URL}/api/sutech/addItemsCheck/`, body, config)
            .then(({data}) => {
              if (data.ok) {
                this.data.push(data.checkItemsDB)
                this.viewCreateForm = false
                this.loading = false
              } else {
                this.loading = false
              }
            })
            .catch((error) => {
              this.loading = false
              this.viewCreateForm = false
              console.log('error ' + error)
            })
      }
    },
    deleteItemConfirm(item){
      this.item_delete = item
      this.delete_dialog = true
    }
  },
  watch: {
    viewCreateForm: function (val) {
      if (!val) {
        this.check_type_name = null
        this.check_type_description = null
        this.check_type_priority = null
        this.check_type_id = null
        this.check_type_type = null
        this.item_edit = false
      }
    },
  }
}
</script>
