<script src="../utils.js"></script>
<template>
  <div>
    <sub-header name="Inspecciones" @newRegister="newRegister" :is_master="false"></sub-header>
    <div v-if="loading">
      <loading></loading>
    </div>
    <div v-else class="flex flex-wrap">
      <div class="w-full border border-primary border-solid mx-4 my-4">
        <div class="md:flex items-center">
          <v-text-field
              class="px-4"
              label="Placa"
              v-model="search_plate"
          >
            <v-icon
                slot="prepend"
            >
              mdi-car
            </v-icon>
          </v-text-field>
          <v-select
              class="px-4"
              v-model="search_vehicle_type"
              :items="vehicles_types"
              label="Tipo"
              item-text="name"
              item-value="name"
              clearable
          >
            <v-icon
                slot="prepend"
            >
              mdi-sort-bool-descending-variant
            </v-icon>
          </v-select>
          <v-col
              cols="12"
              sm="6"
              md="4"
          >
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="dates"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateRangeText"
                    label="Fecha"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="dates"
                  no-title
                  scrollable
                  range
              >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(dates)"
                >
                  Aceptar
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-btn
              dark
              color="primary"
              text
              @click="loadData"
          >
            Buscar
          </v-btn>
          <v-btn
              dark
              color="primary"
              text
              @click="clearSearch"
          >
            Limpiar
          </v-btn>
        </div>
      </div>
      <div class="my-4 mx-4 flex">
        <download-excel
            class="btn btn-default"
            :data="json_data"
            :fields="json_fields"
            :before-generate="loadExcelData"
            worksheet="My Worksheet"
            name="InspectionsData.xls"
        >
          <v-btn
              class="mx-2"
              dark
              large
              color="success"
          >
            <v-icon dark>
              mdi-file-excel
            </v-icon>
          </v-btn>
        </download-excel>
        <v-btn
            class="mx-2"
            dark
            large
            color="error"
            @click="exportPdf()"
        >
          <v-icon dark>
            mdi-file-pdf-box
          </v-icon>
        </v-btn>
        <v-btn
            v-if="selected_data_table.length"
            class="mx-2"
            dark
            large
            color="primary"
            @click="massivePDF"
        >
          Generar PDF Masivo
        </v-btn>
      </div>
      <div v-if="data.length">
        <v-data-table
            v-if="data.length && $store.state.user.brands.name === 'INGEOMEGA'"
            :headers="headers"
            :items="data"
            v-model="selected_data_table"
            class="elevation-1 w-full mx-4 my-4"
            item-key="plateVehicle"
            show-select
        >
          <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="detailInspection(item)"
            >
              mdi-information-outline
            </v-icon>
            <v-icon
                small
                class="mr-2"
                @click="pdfInspection(item)"
            >
              mdi-file-pdf-box
            </v-icon>
          </template>
        </v-data-table>
        <v-data-table
            v-if="data.length && $store.state.user.brands.name !== 'INGEOMEGA'"
            :headers="headers"
            :items="data"
            v-model="selected_data_table"
            class="elevation-1 w-full mx-4 my-4"
            item-key="plateVehicle"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="detailInspection(item)"
            >
              mdi-information-outline
            </v-icon>
            <v-icon
                small
                class="mr-2"
                @click="pdfInspection(item)"
            >
              mdi-file-pdf-box
            </v-icon>
          </template>
        </v-data-table>
      </div>
      <div class="w-full" v-else>
        <empty-state @newRegister="newRegister"/>
      </div>
    </div>
    <div v-if="itemSelected && !viewPDFdialog">
      <v-dialog
          v-model="viewDetailForm"
          max-width="800px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Detalle de inspección</span>
          </v-card-title>
          <div class="mx-6">
            <div class="md:flex">
              <div class="w-full md:w-1/2">
                <div class="text-gray-3 text-body2"><span
                    class="text-primary">Placa: </span>{{ itemSelected.inspection.plateVehicle }}
                </div>
              </div>
              <div class="w-full md:w-1/2">
                <div class="text-gray-3 text-body2"><span
                    class="text-primary">Tipo: </span>{{ itemSelected.inspection.typeInspections }}
                </div>
              </div>
            </div>
            <div class="md:flex">
              <div class="w-full md:w-1/2">
                <div class="text-gray-3 text-body2"><span
                    class="text-primary">Fecha: </span>{{ itemSelected.inspection.currentDate }}
                </div>
              </div>
              <div class="w-full md:w-1/2">
                <div class="text-gray-3 text-body2"><span
                    class="text-primary">Flota: </span>{{ itemSelected.inspection.nameFleet }}
                </div>
              </div>
            </div>
            <div class="w-full">
              <div class="text-gray-3 text-body2"><span
                  class="text-primary">Conductor: </span>{{ itemSelected.inspection.driverName }}
              </div>
            </div>
            <div class="w-full">
              <div class="text-gray-3 text-body2"><span
                  class="text-primary">Inspector: </span>{{ itemSelected.inspection.inspectorName }}
              </div>
            </div>
          </div>
          <div
              class="bg-secondary text-center text-body2 text-gray-4 mx-6 my-4 border-b border-solid border-gray p-2">
            Revisión
          </div>
          <div class="border-b border-gray-3 mt-4 mx-6" :key="index" v-for="(item,index) in itemSelected.items">
            <div class="text-primary text-subtitle2 my-4">{{ item.name }}</div>
            <div class="md:flex">
              <div class="w-full md:w-1/2">
                <div class="text-gray-3 text-body2"><span
                    class="text-primary">Prioridad: </span>{{ item.priority }}
                </div>
              </div>
              <div class="w-full md:w-1/2">
                <div :class="getColorStatus(item.state)" class="text-body2"><span
                    class="text-primary">Estado: </span>{{ clasification[item.state] }}
                </div>
              </div>
            </div>
            <div class="w-full">
              <div class="text-gray-3 text-body2"><span
                  class="text-primary">Novedad: </span>{{ item.novelty }}
              </div>
            </div>
          </div>
          <v-card-actions class="mt-6">
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="viewDetailForm = false"
            >
              Cerrar
            </v-btn>
            <v-btn
                color="primary"
                text
                @click="pdfInspection(itemSelected.inspection)"
                :disabled="loading"
            >
              Generar PDF
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          :filename="`InspectionPDF-${itemSelected.inspection.plateVehicle}`"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="90%"
          ref="html2Pdf"
      >
        <section slot="pdf-content">
          <img src="../assets/images/Logo-270.png" alt="icon" class="">
          <div class="w-full mx-8">
            <div class="w-full h3 text-primary w-full text-center my-6">Detalle de inspección</div>
            <div class="w-full p-1 text-white bg-primary text-body2 border border-secondary">Información del
              vehiculo
            </div>
            <div class="w-full border border-gray-2 flex">
              <div class="mx-4 flex">
                <div class="text-primary text-body2 py-1">Placa:</div>
                <div class="text-gray-2 text-body2 py-1 ml-2">{{
                    itemSelected.inspection.plateVehicle
                  }}
                </div>
              </div>
              <div class="mx-4 flex">
                <div class="text-primary text-body2 p-1">Tipo:</div>
                <div class="text-gray-2 text-body2 p-1 ml-2">{{
                    itemSelected.inspection.typeInspections
                  }}
                </div>
              </div>
              <div class="mx-4 flex">
                <div class="text-primary text-body2 p-1">Fecha:</div>
                <div class="text-gray-2 text-body2 p-1 ml-2">{{
                    itemSelected.inspection.currentDate
                  }}
                </div>
              </div>
            </div>
            <div class="w-full border-l border-r border-b border-gray-2 flex">
              <div class="mx-4 flex">
                <div class="text-primary text-body2 py-1">Flota:</div>
                <div class="text-gray-2 text-body2 py-1 ml-2">{{
                    itemSelected.inspection.nameFleet
                  }}
                </div>
              </div>
              <div class="mx-4 flex">
                <div class="text-primary text-body2 py-1">Conductor:</div>
                <div class="text-gray-2 text-body2 py-1 ml-2">{{
                    itemSelected.inspection.driverName
                  }}
                </div>
              </div>
              <div class="mx-4 flex">
                <div class="text-primary text-body2 py-1">Inspector:</div>
                <div class="text-gray-2 text-body2 py-1 ml-2">{{
                    itemSelected.inspection.inspectorName
                  }}
                </div>
              </div>
            </div>
            <div class="w-full p-2 text-white bg-primary text-body2 border border-secondary">Revisiones</div>
            <div class=" border-l border-r border-b border-gray-2" :key="index"
                 v-for="(item,index) in itemSelected.items">
              <div class="bg-secondary text-white text-overline text-center p-1">{{ item.name }}</div>
              <div class="md:flex px-4">
                <div class="w-1/2">
                  <div class="text-gray-3 text-body2"><span
                      class="text-primary">Prioridad: </span>{{ item.priority }}
                  </div>
                </div>
                <div class="w-1/2">
                  <div :class="getColorStatus(item.state)" class="text-body2"><span
                      class="text-primary">Estado: </span>{{ clasification[item.state] }}
                  </div>
                </div>
              </div>
              <div class="w-full px-4">
                <div class="text-gray-3 text-body2"><span
                    class="text-primary">Novedad: </span>{{ item.novelty }}
                </div>
              </div>
            </div>
          </div>
        </section>
      </vue-html2pdf>
      <div v-if="vehicle_inspection_info">
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            :filename="`InspectionPDF-${itemSelected.inspection.plateVehicle}`"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="98%"
            ref="html2PdfIngeo"
        >
          <section slot="pdf-content">
            <section class="pdf-item mt-4 ml-2">
              <div class="flex w-full">
                <div class="w-3/12 p-1 text-gray-2 text-caption border border-secondary text-center">INGEOMEGA S.A.S
                </div>
                <div class="w-6/12 p-1 text-gray-2 text-caption border border-secondary text-center">
                  INSPECCIÓN MENSUAL DE MOTOCICLETAS
                </div>
                <div class="w-6/12 p-1 text-gray-2 text-caption border border-secondary text-center">
                  SF-2-291-0322
                </div>
              </div>
              <div class="w-full p-1 bg-primary text-white text-caption text-center border border-secondary">1. DATOS
                DEL
                CONDUCTOR DEL VEHICULO
              </div>
              <div class="flex w-full">
                <div class="w-2/6 text-gray-2 text-min border border-secondary p-1">Fecha:
                  {{ formatDate(itemSelected.inspection.currentDate) }}
                </div>
                <div class="w-3/6 text-gray-2 text-min border border-secondary p-1">Nombre:
                  {{ itemSelected.inspection.driverName }}
                </div>
                <div class="w-1/6 text-gray-2 text-min border border-secondary p-1">C.C:
                  {{ itemSelected.inspection.driverDocument }}
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-2/6 text-gray-2 text-min border border-secondary p-1">No. Licencia:
                  {{ vehicle_inspection_info.driver.driver_licence }}
                </div>
                <div class="w-2/6 text-gray-2 text-min border border-secondary p-1">Vence:
                  {{ vehicle_inspection_info.driver.driver_licence_date }}
                </div>
                <div class="w-1/6 text-gray-2 text-min border border-secondary p-1">Categoria:
                  {{ vehicle_inspection_info.driver.driver_licence_category }}
                </div>
                <div class="w-1/6 text-gray-2 text-min border border-secondary p-1">Placa:
                  {{ itemSelected.inspection.plateVehicle }}
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-2/6 text-gray-2 text-min border border-secondary p-1">
                  Restricciones de la licencia:
                </div>
                <div class="w-2/6 text-gray-2 text-min border border-secondary p-1">
                  Marca: {{ itemSelected.inspection.vehicleBrand }}
                </div>
                <div class="w-2/6 text-gray-2 text-min flex">
                  <div class="w-1/2 border border-r border-secondary p-1">Modelo:
                    {{ itemSelected.inspection.vehicleModel }}
                  </div>
                  <div class="w-1/2 border border border-secondary p-1">Kilometraje:
                    {{ itemSelected.inspection.currentMileage }}
                  </div>
                </div>
              </div>
              <div class="w-full p-1 bg-primary text-white text-min text-center border border-secondary">2.
                VERIFICACIÓN DOCUMENTOS
              </div>
              <div class="flex w-full">
                <div class="w-2/6 bg-primary text-white text-min border border-secondary p-1">
                  Documentos
                </div>
                <div class="w-2/6 bg-primary text-white text-min border border-secondary p-1">
                  Número
                </div>
                <div class="w-2/6 bg-primary text-white text-min border border-secondary p-1">
                  Vence
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-2/6 text-gray-2 text-min border border-secondary p-1">
                  Licencia de transito
                </div>
                <div class="w-2/6 text-gray-2 text-min border border-secondary p-1">
                </div>
                <div class="w-2/6 text-gray-2 text-min border border-secondary p-1">
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-2/6 text-gray-2 text-min border border-secondary p-1">
                  SOAT
                </div>
                <div class="w-2/6 text-gray-2 text-min border border-secondary p-1">
                  {{ vehicle_inspection_info.vehicle.vehicle_soat_number }}
                </div>
                <div class="w-2/6 text-gray-2 text-min border border-secondary p-1">
                  {{ vehicle_inspection_info.vehicle.vehicle_soat }}
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-2/6 text-gray-2 text-min border border-secondary p-1">
                  Tecnomecanica y de gases
                </div>
                <div class="w-2/6 text-gray-2 text-min border border-secondary p-1">
                  {{ vehicle_inspection_info.vehicle.vehicle_tecno_number }}
                </div>
                <div class="w-2/6 text-gray-2 text-min border border-secondary p-1">
                  {{ vehicle_inspection_info.vehicle.vehicle_tecno }}
                </div>
              </div>
              <div class="w-full p-1 bg-primary text-white text-min text-center border border-secondary">
                3.VERIFICACIÓN MOTOCICLETA
              </div>
              <div class="flex w-full text-center">
                <div class="w-5/12 bg-primary text-white text-min border border-secondary p-1">
                  CRITERIOS
                </div>
                <div class="w-1/12 bg-primary text-white text-min border border-secondary text-center">
                  <div class="w-full flex">
                    <div class="w-1/3 border border-secondary p-1">B</div>
                    <div class="w-1/3 border border-secondary p-1">M</div>
                    <div class="w-1/3 border border-secondary p-1">P</div>
                  </div>
                </div>
                <div class="w-4/12 bg-primary text-white text-min border border-secondary p-1">
                  OBSERVACIONES
                </div>
                <div class="w-2/12 bg-primary text-white text-min border border-secondary p-1">
                  CORRECCION
                </div>
              </div>
              <div
                  v-for="(item, index) in itemSelected.items.filter( item_section => item_section.section === 0 && item_section._id !== '62543f470477845d6114fdde' && item_section._id !== '62543fd30477845d6114fddf')"
                  :key="index" class="flex w-full text-center">
                <div class="w-5/12 text-gray-2 text-min border border-secondary flex">
                  <div class="bg-primary text-white text-min w-1/12">{{ index + 1 }}</div>
                  <div class="p-1">{{ item.name }}</div>
                </div>
                <div class="w-1/12 text-gray-2 text-min border border-secondary flex">
                  <div v-if="item.type === 'text'" class="w-full">
                    {{ item.state }}
                  </div>
                  <div v-else class="w-full flex">
                    <div class="w-1/3 border-r border-secondary text-center">{{ item.state === 'B' ? 'X' : '' }}</div>
                    <div class="w-1/3 border-r border-secondary text-center">{{ item.state === 'M' ? 'X' : '' }}</div>
                    <div class="w-1/3 text-center bg-primary text-white">{{ priorityLetter(item.priority) }}</div>
                  </div>
                </div>
                <div class="w-4/12 text-gray-2 text-min border border-secondary p-1 flex">
                  {{ item.novelty }}
                </div>
                <div class="w-2/12 text-gray-2 text-min border border-secondary p-1 flex text-center">
                  {{ item.date_novelty }}
                </div>
              </div>
            </section>
            <div class="html2pdf__page-break"/>
            <section class="pdf-item mt-4 ml-2">
              <div class="w-full p-1 bg-primary text-white text-min text-center border border-secondary">
                4.ESTADO Y USO EFICIENTE DE LOS EQUIPOS DE PROTECCION PARA LA CONDUCCION DE UNA MOTOCICLETA
              </div>
              <div class="flex w-full text-center">
                <div class="w-5/12 bg-primary text-white text-min border border-secondary p-1">
                  CRITERIOS
                </div>
                <div class="w-1/12 bg-primary text-white text-min border border-secondary text-center">
                  <div class="w-full flex">
                    <div class="w-1/3 border border-secondary p-1">B</div>
                    <div class="w-1/3 border border-secondary p-1">M</div>
                    <div class="w-1/3 border border-secondary p-1">P</div>
                  </div>
                </div>
                <div class="w-4/12 bg-primary text-white text-min border border-secondary p-1">
                  OBSERVACION
                </div>
                <div class="w-2/12 bg-primary text-white text-min border border-secondary p-1">
                  CORRECCION
                </div>
              </div>
              <div v-for="(item, index) in itemSelected.items.filter( item_section => item_section.section === 4)"
                   :key="`${index}_section4`" class="flex w-full text-center">
                <div class="w-5/12 text-gray-2 text-min border border-secondary flex">
                  <div class="bg-primary text-white text-min w-1/12">{{ index + 1 }}</div>
                  <div class="p-1">{{ item.name }}</div>
                </div>
                <div class="w-1/12 text-gray-2 text-min border border-secondary flex">
                  <div class="w-full flex">
                    <div class="w-1/3 border-r border-secondary text-center">{{ item.state === 'B' ? 'X' : '' }}</div>
                    <div class="w-1/3 border-r border-secondary text-center">{{ item.state === 'M' ? 'X' : '' }}</div>
                    <div class="w-1/3 bg-primary text-white text-center">{{ priorityLetter(item.priority) }}</div>
                  </div>
                </div>
                <div class="w-4/12 text-gray-2 text-min border border-secondary p-1 flex">
                  {{ item.novelty }}
                </div>
                <div class="w-2/12 text-gray-2 text-min border border-secondary p-1 flex text-center">
                  {{ item.date_novelty }}
                </div>
              </div>
            </section>
            <section class="pdf-item ml-2">
              <div class="w-full">
                <div class="flex">
                  <div class="w-4/6 border border-secondary">
                    <div class="w-full p-1 bg-primary text-white text-min text-center border border-secondary">
                      5. REFRENCIACIÓN VISUAL MOTOCICLETA
                    </div>
                    <img src="@/assets/images/moto_items.png" alt="Triangle with three unequal sides"/>
                  </div>
                  <div class="w-2/6 border border-secondary">
                    <div class="w-full p-1 bg-primary text-white text-min text-center border border-secondary mb-8">
                      6. REFRENCIACIÓN VISUAL CASCO
                    </div>
                    <img src="@/assets/images/casco_items.png" alt="Triangle with three unequal sides"/>
                    <div>
                      <p class="p-4">
                                    <span
                                        v-for="(item, index) in itemSelected.items.filter( item_section => item_section.section === 5)"
                                        :key="`${index}_section4`" class="px-1 text-gray-2 text-caption">
                                      <span class="text-primary text-caption px-1">{{ index + 1 }} - </span>
                                      {{ item.name }}: <span class="text-primary text-caption px-1">{{
                                        item.state
                                      }} </span>
                                    </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="flex w-full text-center">
                  <div class="w-5/6 bg-primary text-white text-min border border-secondary px-1">
                    CRITERIOS
                  </div>
                  <div class="w-1/6 bg-primary text-white text-min border border-secondary text-center">
                    <div class="w-full flex">
                      <div class="w-1/2 border border-secondary px-1">SI</div>
                      <div class="w-1/2 border border-secondary px-1">NO</div>
                    </div>
                  </div>
                </div>
                <div class="flex w-full text-center">
                  <div class="w-5/6 text-gray-2 text-min border border-secondary">
                    <div class="p-1">Luego de verificado todos los items anteriores, se determina el cumplimiento y
                      aprobación del vehículo
                    </div>
                  </div>
                  <div class="w-1/6 text-gray-2 text-min border border-secondary flex">
                    <div class="w-full flex">
                      <div class="w-1/2 border-r border-secondary text-center">
                        {{ itemSelected.pass_inspection === 'S' ? 'X' : '' }}
                      </div>
                      <div class="w-1/2 text-center">{{ itemSelected.pass_inspection === 'N' ? 'X' : '' }}</div>
                    </div>
                  </div>
                </div>
                <div class="flex w-full text-center">
                  <div class="w-5/6 text-gray-2 text-min border border-secondary">
                    <div class="p-1">El conductor ha recibido folletos ilustrativos de
                      apoyo: Politicas (coporativa, seguridad vial, salud, higiene, ambiente, alcohol y drogas) y
                      estandares
                      de seguridad.
                    </div>
                  </div>
                  <div class="w-1/6 text-gray-2 text-min border border-secondary flex">
                    <div class="w-full flex">
                      <div class="w-1/2 border-r border-secondary text-center">
                        {{ itemSelected.info_inspection === 'S' ? 'X' : '' }}
                      </div>
                      <div class="w-1/2 text-center">{{ itemSelected.info_inspection === 'N' ? 'X' : '' }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div class="html2pdf__page-break"/>
            <section class="pdf-item mt-6 ml-2">
              <div class="w-full">
                <div v-if="novelty_pending.length"
                     class="w-full p-1 bg-primary text-white text-min text-center border border-secondary">
                  7.COMPROMISOS PENDIENTES DEL CODUCTOR
                </div>
                <div v-if="novelty_pending.length" class="flex w-full text-center">
                  <div class="w-2/6 bg-primary text-white text-min border border-secondary p-1">
                    COMPROMISOS PENDIENTES
                  </div>
                  <div class="w-1/6 bg-primary text-white text-min border border-secondary p-1">
                    FECHA COMPROMISO
                  </div>
                  <div class="w-1/6 bg-primary text-white text-min border border-secondary p-1">
                    FECHA CIERRE COMPROMISO
                  </div>
                  <div class="w-2/6 bg-primary text-white text-min border border-secondary text-center">
                    <div class="w-full border border-secondary p-1">ESTADO</div>
                    <div class="w-full flex">
                      <div class="w-1/2 border border-secondary p-1">ABIERTO</div>
                      <div class="w-1/2 border border-secondary p-1">CERRADO</div>
                    </div>
                  </div>
                </div>
                <div v-show="novelty_pending.length" v-for="(item, index) in novelty_pending"
                     :key="`${index}_novelty_pend`" class="flex w-full text-center">
                  <div class="w-2/6 text-gray-2 text-min border border-secondary">
                    <div class="p-1">{{ item.description }}</div>
                  </div>
                  <div class="w-1/6 text-gray-2 text-min border border-secondary p-1 flex">
                    {{ item.expirationDate }}
                  </div>
                  <div class="w-1/6 text-gray-2 text-min border border-secondary p-1 flex text-center">
                    {{ item.attendedDate }}
                  </div>
                  <div class="w-2/6 text-gray-2 text-min border border-secondary flex">
                    <div class="w-full flex">
                      <div class="w-1/2 border-r border-secondary text-center">{{ !item.state ? 'X' : '' }}</div>
                      <div class="w-1/2 text-center">{{ item.state ? 'X' : '' }}</div>
                    </div>
                  </div>
                </div>
                <div v-show="novelty_pending.length" v-for="(item, index) in novelty_pending"
                     :key="`${index}_novelty_pend`" class="flex w-full text-center mt-6">
                  <div v-if="item.novelty_image" class="w-1/2">
                    <div class="text-gray-2 text-min mb-2">
                      {{ item.attended }}
                    </div>
                    <img :src="item.novelty_image" class="h-40">
                  </div>
                </div>
              </div>
              <section class="pdf-item ml-2">
                <div class="w-full flex mt-16">
                  <div v-if="itemSelected.inspection.usuario" class="w-1/2 pr-4 mt-16">
                    <img class="h-32" :src="itemSelected.inspection.usuario.urlFirm">
                    <div class="border-t border-secondary">
                      <div class="text-gray-2 text-min p-1">
                        {{ itemSelected.inspection.usuario.name }}
                      </div>
                    </div>
                  </div>
                  <div class="w-1/2 mt-16">
                    <img class="h-32" :src="itemSelected.url_firm">
                    <div class="border-t border-secondary">
                      <div class="text-gray-2 text-min p-1">
                        {{ itemSelected.inspection.driverName }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-full flex mt-16 text-gray-2 text-min p-1">
                  Los datos personales aquí consignados tienen carácter confidencial, razón por la cual es un deber y un
                  compromiso de los trabajadores de INGEOMEGA S.A.S. no divulgar información alguna en propósito
                  diferente
                  a la de este registro so pena de las sanciones legales a que haya lugar de acuerdo con la Ley 1581 de
                  2012
                  y Decreto 1377 de 2013.
                </div>
              </section>
            </section>
          </section>
        </vue-html2pdf>
      </div>
    </div>
    <div v-if="selected_process_items.length && print_massive">
      <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          :filename="`InspectionsPDF-Masive`"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="98%"
          ref="html2PdfIngeoMassive"
      >
        <section slot="pdf-content">
          <div v-for="(item_mass, index) in selected_process_items" :key="index">
            <section class="pdf-item mt-4 ml-2">
              <div class="flex w-full">
                <div class="w-3/12 p-1 text-gray-2 text-caption border border-secondary text-center">INGEOMEGA S.A.S
                </div>
                <div class="w-6/12 p-1 text-gray-2 text-caption border border-secondary text-center">
                  INSPECCIÓN MENSUAL DE MOTOCICLETAS
                </div>
                <div class="w-6/12 p-1 text-gray-2 text-caption border border-secondary text-center">
                  SF-2-291-0322
                </div>
              </div>
              <div class="w-full p-1 bg-primary text-white text-caption text-center border border-secondary">1. DATOS
                DEL
                CONDUCTOR DEL VEHICULO
              </div>
              <div class="flex w-full">
                <div class="w-2/6 text-gray-2 text-min border border-secondary p-1">Fecha:
                  {{ formatDate(item_mass.inspection.currentDate) }}
                </div>
                <div class="w-3/6 text-gray-2 text-min border border-secondary p-1">Nombre:
                  {{ item_mass.inspection.driverName }}
                </div>
                <div class="w-1/6 text-gray-2 text-min border border-secondary p-1">C.C:
                  {{ item_mass.inspection.driverDocument }}
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-2/6 text-gray-2 text-min border border-secondary p-1">No. Licencia:
                  {{ item_mass.vehicle_inspection_info.driver.driver_licence }}
                </div>
                <div class="w-2/6 text-gray-2 text-min border border-secondary p-1">Vence:
                  {{ item_mass.vehicle_inspection_info.driver.driver_licence_date }}
                </div>
                <div class="w-1/6 text-gray-2 text-min border border-secondary p-1">Categoria:
                  {{ item_mass.vehicle_inspection_info.driver.driver_licence_category }}
                </div>
                <div class="w-1/6 text-gray-2 text-min border border-secondary p-1">Placa:
                  {{ item_mass.inspection.plateVehicle }}
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-2/6 text-gray-2 text-min border border-secondary p-1">
                  Restricciones de la licencia:
                </div>
                <div class="w-2/6 text-gray-2 text-min border border-secondary p-1">
                  Marca: {{ item_mass.inspection.vehicleBrand }}
                </div>
                <div class="w-2/6 text-gray-2 text-min flex">
                  <div class="w-1/2 border border-r border-secondary p-1">Modelo:
                    {{ item_mass.inspection.vehicleModel }}
                  </div>
                  <div class="w-1/2 border border border-secondary p-1">Kilometraje:
                    {{ item_mass.inspection.currentMileage }}
                  </div>
                </div>
              </div>
              <div class="w-full p-1 bg-primary text-white text-min text-center border border-secondary">2.
                VERIFICACIÓN DOCUMENTOS
              </div>
              <div class="flex w-full">
                <div class="w-2/6 bg-primary text-white text-min border border-secondary p-1">
                  Documentos
                </div>
                <div class="w-2/6 bg-primary text-white text-min border border-secondary p-1">
                  Número
                </div>
                <div class="w-2/6 bg-primary text-white text-min border border-secondary p-1">
                  Vence
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-2/6 text-gray-2 text-min border border-secondary p-1">
                  Licencia de transito
                </div>
                <div class="w-2/6 text-gray-2 text-min border border-secondary p-1">
                </div>
                <div class="w-2/6 text-gray-2 text-min border border-secondary p-1">
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-2/6 text-gray-2 text-min border border-secondary p-1">
                  SOAT
                </div>
                <div class="w-2/6 text-gray-2 text-min border border-secondary p-1">
                  {{ item_mass.vehicle_inspection_info.vehicle.vehicle_soat_number }}
                </div>
                <div class="w-2/6 text-gray-2 text-min border border-secondary p-1">
                  {{ item_mass.vehicle_inspection_info.vehicle.vehicle_soat }}
                </div>
              </div>
              <div class="flex w-full">
                <div class="w-2/6 text-gray-2 text-min border border-secondary p-1">
                  Tecnomecanica y de gases
                </div>
                <div class="w-2/6 text-gray-2 text-min border border-secondary p-1">
                  {{ item_mass.vehicle_inspection_info.vehicle.vehicle_tecno_number }}
                </div>
                <div class="w-2/6 text-gray-2 text-min border border-secondary p-1">
                  {{ item_mass.vehicle_inspection_info.vehicle.vehicle_tecno }}
                </div>
              </div>
              <div class="w-full p-1 bg-primary text-white text-min text-center border border-secondary">
                3.VERIFICACIÓN MOTOCICLETA
              </div>
              <div class="flex w-full text-center">
                <div class="w-5/12 bg-primary text-white text-min border border-secondary p-1">
                  CRITERIOS
                </div>
                <div class="w-1/12 bg-primary text-white text-min border border-secondary text-center">
                  <div class="w-full flex">
                    <div class="w-1/3 border border-secondary p-1">B</div>
                    <div class="w-1/3 border border-secondary p-1">M</div>
                    <div class="w-1/3 border border-secondary p-1">P</div>
                  </div>
                </div>
                <div class="w-4/12 bg-primary text-white text-min border border-secondary p-1">
                  OBSERVACIONES
                </div>
                <div class="w-2/12 bg-primary text-white text-min border border-secondary p-1">
                  CORRECCION
                </div>
              </div>
              <div
                  v-for="(item, index) in item_mass.items.filter( item_section => item_section.section === 0 && item_section._id !== '62543f470477845d6114fdde' && item_section._id !== '62543fd30477845d6114fddf')"
                  :key="index" class="flex w-full text-center">
                <div class="w-5/12 text-gray-2 text-min border border-secondary flex">
                  <div class="bg-primary text-white text-min w-1/12">{{ index + 1 }}</div>
                  <div class="p-1">{{ item.name }}</div>
                </div>
                <div class="w-1/12 text-gray-2 text-min border border-secondary flex">
                  <div v-if="item.type === 'text'" class="w-full">
                    {{ item.state }}
                  </div>
                  <div v-else class="w-full flex">
                    <div class="w-1/3 border-r border-secondary text-center">{{ item.state === 'B' ? 'X' : '' }}</div>
                    <div class="w-1/3 border-r border-secondary text-center">{{ item.state === 'M' ? 'X' : '' }}</div>
                    <div class="w-1/3 text-center bg-primary text-white">{{ priorityLetter(item.priority) }}</div>
                  </div>
                </div>
                <div class="w-4/12 text-gray-2 text-min border border-secondary p-1 flex">
                  {{ item.novelty }}
                </div>
                <div class="w-2/12 text-gray-2 text-min border border-secondary p-1 flex text-center">
                  {{ item.date_novelty }}
                </div>
              </div>
            </section>
            <div class="html2pdf__page-break"/>
            <section class="pdf-item mt-4 ml-2">
              <div class="w-full p-1 bg-primary text-white text-min text-center border border-secondary">
                4.ESTADO Y USO EFICIENTE DE LOS EQUIPOS DE PROTECCION PARA LA CONDUCCION DE UNA MOTOCICLETA
              </div>
              <div class="flex w-full text-center">
                <div class="w-5/12 bg-primary text-white text-min border border-secondary p-1">
                  CRITERIOS
                </div>
                <div class="w-1/12 bg-primary text-white text-min border border-secondary text-center">
                  <div class="w-full flex">
                    <div class="w-1/3 border border-secondary p-1">B</div>
                    <div class="w-1/3 border border-secondary p-1">M</div>
                    <div class="w-1/3 border border-secondary p-1">P</div>
                  </div>
                </div>
                <div class="w-4/12 bg-primary text-white text-min border border-secondary p-1">
                  OBSERVACION
                </div>
                <div class="w-2/12 bg-primary text-white text-min border border-secondary p-1">
                  CORRECCION
                </div>
              </div>
              <div v-for="(item, index) in item_mass.items.filter( item_section => item_section.section === 4)"
                   :key="`${index}_section4`" class="flex w-full text-center">
                <div class="w-5/12 text-gray-2 text-min border border-secondary flex">
                  <div class="bg-primary text-white text-min w-1/12">{{ index + 1 }}</div>
                  <div class="p-1">{{ item.name }}</div>
                </div>
                <div class="w-1/12 text-gray-2 text-min border border-secondary flex">
                  <div class="w-full flex">
                    <div class="w-1/3 border-r border-secondary text-center">{{ item.state === 'B' ? 'X' : '' }}</div>
                    <div class="w-1/3 border-r border-secondary text-center">{{ item.state === 'M' ? 'X' : '' }}</div>
                    <div class="w-1/3 bg-primary text-white text-center">{{ priorityLetter(item.priority) }}</div>
                  </div>
                </div>
                <div class="w-4/12 text-gray-2 text-min border border-secondary p-1 flex">
                  {{ item.novelty }}
                </div>
                <div class="w-2/12 text-gray-2 text-min border border-secondary p-1 flex text-center">
                  {{ item.date_novelty }}
                </div>
              </div>
            </section>
            <section class="pdf-item ml-2">
              <div class="w-full">
                <div class="flex w-full text-center">
                  <div class="w-5/6 bg-primary text-white text-min border border-secondary px-1">
                    CRITERIOS
                  </div>
                  <div class="w-1/6 bg-primary text-white text-min border border-secondary text-center">
                    <div class="w-full flex">
                      <div class="w-1/2 border border-secondary px-1">SI</div>
                      <div class="w-1/2 border border-secondary px-1">NO</div>
                    </div>
                  </div>
                </div>
                <div class="flex w-full text-center">
                  <div class="w-5/6 text-gray-2 text-min border border-secondary">
                    <div class="p-1">Luego de verificado todos los items anteriores, se determina el cumplimiento y
                      aprobación del vehículo
                    </div>
                  </div>
                  <div class="w-1/6 text-gray-2 text-min border border-secondary flex">
                    <div class="w-full flex">
                      <div class="w-1/2 border-r border-secondary text-center">
                        {{ item_mass.pass_inspection === 'S' ? 'X' : '' }}
                      </div>
                      <div class="w-1/2 text-center">{{ item_mass.pass_inspection === 'N' ? 'X' : '' }}</div>
                    </div>
                  </div>
                </div>
                <div class="flex w-full text-center">
                  <div class="w-5/6 text-gray-2 text-min border border-secondary">
                    <div class="p-1">El conductor ha recibido folletos ilustrativos de
                      apoyo: Politicas (coporativa, seguridad vial, salud, higiene, ambiente, alcohol y drogas) y
                      estandares
                      de seguridad.
                    </div>
                  </div>
                  <div class="w-1/6 text-gray-2 text-min border border-secondary flex">
                    <div class="w-full flex">
                      <div class="w-1/2 border-r border-secondary text-center">
                        {{ item_mass.info_inspection === 'S' ? 'X' : '' }}
                      </div>
                      <div class="w-1/2 text-center">{{ item_mass.info_inspection === 'N' ? 'X' : '' }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="item_mass.novelty_pending.length" class="w-full">
                <div class="w-full p-1 bg-primary text-white text-min text-center border border-secondary">
                  7.COMPROMISOS PENDIENTES DEL CODUCTOR
                </div>
                <div class="flex w-full text-center">
                  <div class="w-2/6 bg-primary text-white text-min border border-secondary p-1">
                    COMPROMISOS PENDIENTES
                  </div>
                  <div class="w-1/6 bg-primary text-white text-min border border-secondary p-1">
                    FECHA COMPROMISO
                  </div>
                  <div class="w-1/6 bg-primary text-white text-min border border-secondary p-1">
                    FECHA CIERRE COMPROMISO
                  </div>
                  <div class="w-2/6 bg-primary text-white text-min border border-secondary text-center">
                    <div class="w-full border border-secondary p-1">ESTADO</div>
                    <div class="w-full flex">
                      <div class="w-1/2 border border-secondary p-1">ABIERTO</div>
                      <div class="w-1/2 border border-secondary p-1">CERRADO</div>
                    </div>
                  </div>
                </div>
                <div v-for="(item, index) in novelty_pending"
                     :key="`${index}_novelty_pend`" class="flex w-full text-center">
                  <div class="w-2/6 text-gray-2 text-min border border-secondary">
                    <div class="p-1">{{ item.description }}</div>
                  </div>
                  <div class="w-1/6 text-gray-2 text-min border border-secondary p-1 flex">
                    {{ item.expirationDate }}
                  </div>
                  <div class="w-1/6 text-gray-2 text-min border border-secondary p-1 flex text-center">
                    {{ item.attendedDate }}
                  </div>
                  <div class="w-2/6 text-gray-2 text-min border border-secondary flex">
                    <div class="w-full flex">
                      <div class="w-1/2 border-r border-secondary text-center">{{ !item.state ? 'X' : '' }}</div>
                      <div class="w-1/2 text-center">{{ item.state ? 'X' : '' }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <section class="pdf-item ml-2">
                <div class="w-full flex mt-16">
                  <div v-if="itemSelected.inspection.usuario" class="w-1/2 pr-4 mt-16">
                    <img class="h-32" :src="item_mass.inspection.usuario.urlFirm">
                    <div class="border-t border-secondary">
                      <div class="text-gray-2 text-min p-1">
                        {{ item_mass.inspection.usuario.name }}
                      </div>
                    </div>
                  </div>
                  <div class="w-1/2 mt-16">
                    <img class="h-32" :src="item_mass.url_firm">
                    <div class="border-t border-secondary">
                      <div class="text-gray-2 text-min p-1">
                        {{ item_mass.inspection.driverName }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-full flex mt-16 text-gray-2 text-min p-1">
                  Los datos personales aquí consignados tienen carácter confidencial, razón por la cual es un deber y un
                  compromiso de los trabajadores de INGEOMEGA S.A.S. no divulgar información alguna en propósito
                  diferente
                  a la de este registro so pena de las sanciones legales a que haya lugar de acuerdo con la Ley 1581 de
                  2012
                  y Decreto 1377 de 2013.
                </div>
              </section>
            </section>
          </div>
        </section>
      </vue-html2pdf>
    </div>
    <div v-if="list">
      <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="Inspections-list"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="90%"
          ref="html2Pdf"
      >
        <section slot="pdf-content">
          <div class="w-full m-8">
            <div class="w-full h3 text-primary w-full text-center my-6">Lista de inspecciones</div>
            <div class="w-full p-2  border-b border-primary flex text-center">
              <div class="w-1/5 text-body-2 text-secondary">
                Nombre
              </div>
              <div class="w-1/5 text-body-2 text-secondary">
                Tipo
              </div>
              <div class="w-1/5 text-body-2 text-secondary">
                Fecha
              </div>
              <div class="w-1/5 text-body-2 text-secondary">
                Flota
              </div>
              <div class="w-1/5 text-body-2 text-secondary">
                Inspector
              </div>
            </div>
            <div class="w-full p-2  border-b border-gray-2 flex text-center" :key="index" v-for="(item,index) in data">
              <div class="w-1/5">
                {{ item.plateVehicle }}
              </div>
              <div class="w-1/5">
                {{ item.typeInspections }}
              </div>
              <div class="w-1/5">
                {{ item.currentDate }}
              </div>
              <div class="w-1/5">
                {{ item.nameFleet }}
              </div>
              <div class="w-1/5">
                {{ item.inspectorName }}
              </div>
            </div>
          </div>
        </section>
      </vue-html2pdf>
    </div>
    <v-dialog
        v-model="viewPDFdialog"
        max-width="600px"
    >
      <v-card>
        <div class="mx-8">
          <div class="flex justify-center mt-16">
            <img src="@/assets/images/Cube_loading.svg" alt="Triangle with three unequal sides"/>
          </div>
          <div class="text-body text-gray-2 my-4 w-full text-center">Generando PDF, Por favor espere</div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import VueHtml2pdf from 'vue-html2pdf'
import {loadVehicleInfo} from "../utils";

export default {
  name: "Inspections",
  components: {
    VueHtml2pdf
  },
  data() {
    return {
      loading: false,
      viewCreateForm: false,
      data: [],
      headers: [
        {
          text: 'Placa',
          align: 'start',
          value: 'plateVehicle',
        },
        {text: 'Tipo', value: 'typeInspections'},
        {text: 'Fecha', value: 'currentDate'},
        {text: 'Flota', value: 'nameFleet'},
        {text: 'Conductor', value: 'driverName'},
        {text: 'Inspector', value: 'inspectorName'},
        {text: 'Acciones', value: 'actions', sortable: false},
      ],
      viewDetailForm: false,
      itemSelected: null,
      clasification: {
        B: 'Bueno',
        R: 'Regular',
        M: 'Malo',
        N: 'No Aplica'
      },
      vehicles_types: [],
      search_plate: null,
      search_vehicle_type: null,
      dates: [],
      menu: false,
      search_fleet: null,
      list: true,
      json_fields: {
        Placa: "plateVehicle",
        Conductor: "driverName",
        Fecha: "currentDate",
        Inspector: "inspectorName",
        Flota: "nameFleet",
        Cumple: "passInspection",
        Novedades: "novelties",
        Tipo: "typeInspections",
      },
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      vehicle_inspection_info: null,
      current_date: moment(new Date(Date.now())).utc().format('DD/MM/YYYY'),
      check_items_all: [],
      novelty_pending: [],
      selected_data_table: [],
      selected_process_items: [],
      viewPDFdialog: false,
      print_massive: false,
    }
  },
  mounted() {
    this.loadData()
    this.loadTypes()
    this.loadItems()
  },
  methods: {
    newRegister() {
    },
    loadData() {
      this.loading = true

      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }

      let url = `${process.env.VUE_APP_API_URL}/api/sutech/getInspectionsByFilters/`

      if (this.search_plate || this.search_vehicle_type || this.dates.length || this.search_fleet) {

        if (this.search_plate) url = `${url}?plateVehicle=${this.search_plate}`
        if (this.search_vehicle_type && !this.search_plate) url = `${url}?typeInspections=${this.search_vehicle_type}`
        else if (this.search_vehicle_type && this.search_plate) url = `${url}&typeInspections=${this.search_vehicle_type}`

        if (this.dates.length) {
          const date_start = this.dates[0]
          const date_end = this.dates.length > 1 ? this.dates[1] : null

          if (date_start && !this.search_plate && !this.search_vehicle_type) url = `${url}?startDate=${date_start}`
          else if (date_start && (this.search_plate || this.search_vehicle_type)) url = `${url}&startDate=${date_start}`

          if (date_end && !this.search_plate && !this.search_vehicle_type && !date_start) url = `${url}?endDate=${date_end}`
          else if (date_end && (this.search_plate || this.search_vehicle_type || date_start)) url = `${url}&endDate=${date_end}`
        }

      }

      this.data = []

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.ok) {
              if (data.inspections.length) {
                data.inspections.forEach(item => {
                  item.inspectorName = item.usuario ? item.usuario.name : ''
                  item.currentDate = moment(item.currentDate).utc().format('DD/MM/YYYY')
                  if (item.typeInspections !== 'Encuesta Covid') this.data.push(item)
                })
              }
              this.loading = false
            } else {
              this.loading = false
            }
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    getColorStatus(item) {
      switch (item) {
        case 'B':
          return 'text-success'
        case 'R':
          return 'text-warning'
        case 'M':
          return 'text-danger'
        default:
          'text-gray-3'
      }
    },
    async detailInspection(item) {
      this.itemSelected = null
      this.list = false
      await this.getInspection(item)
      this.viewDetailForm = true
    },
    async getPDF(item) {
      this.list = false
      this.itemSelected = null
      if (await this.getInspection(item)) this.generateReport()
    },
    getInspection(item) {
      return new Promise(resolve => {
        this.loading = true

        let config = {
          headers: {
            token: localStorage.sutech_token,
            id: item._id
          }
        }

        this.$axios.get(`${process.env.VUE_APP_API_URL}/api/sutech/getInspetionsById/`, config)
            .then(({data}) => {
              if (data.ok) {
                this.itemSelected = {
                  inspection: data.inspectionsDB,
                  items: data.reportsDB && data.reportsDB.length ? data.reportsDB[0].items : [],
                  pass_inspection: data.reportsDB && data.reportsDB.length ? data.reportsDB[0].pass_inspection : null,
                  info_inspection: data.reportsDB && data.reportsDB.length ? data.reportsDB[0].info_inspection : null,
                  url_firm: data.reportsDB && data.reportsDB.length ? data.reportsDB[0].url_firm : null,
                }

                this.itemSelected.items.forEach((item_chk, index) => {
                  if (item_chk.state === 'M') {
                    this.$axios.get(`${process.env.VUE_APP_API_URL}/api/sutech/getNoveltByitem/`, {
                      headers: {
                        token: localStorage.sutech_token,
                        inspections: item._id,
                        checkItem: item_chk.checkItem
                      }
                    })
                        .then(({data}) => {
                          if (data.ok) {
                            this.itemSelected.items[index].novelty = data.info[0].description
                            this.itemSelected.items[index].date_novelty = moment(new Date(data.info[0].expirationDate)).utc().format('DD/MM/YYYY')
                            this.itemSelected.items[index].novelty_state = data.info[0].state

                            this.novelty_pending.push(data.info[0])
                          }
                        })
                        .catch((error) => {
                          this.loading = false
                          console.log('error ' + error)
                        })
                  }

                  let find_item = this.check_items_all.find(item => item._id === item_chk.checkItem)
                  if (find_item.section) this.itemSelected.items[index].section = find_item.section
                  else this.itemSelected.items[index].section = find_item.section = 0
                })
                this.loading = false
                resolve(true);
              }
            })
            .catch((error) => {
              this.loading = false
              console.log('error ' + error)
            })
      })
    },

    getInspectionMassive(item) {
      return new Promise(resolve => {
        this.loading = true

        let config = {
          headers: {
            token: localStorage.sutech_token,
            id: item._id
          }
        }

        let item_response

        this.$axios.get(`${process.env.VUE_APP_API_URL}/api/sutech/getInspetionsById/`, config)
            .then(({data}) => {
              if (data.ok) {
                item_response = {
                  inspection: data.inspectionsDB,
                  items: data.reportsDB && data.reportsDB.length ? data.reportsDB[0].items : [],
                  pass_inspection: data.reportsDB && data.reportsDB.length ? data.reportsDB[0].pass_inspection : null,
                  info_inspection: data.reportsDB && data.reportsDB.length ? data.reportsDB[0].info_inspection : null,
                  url_firm: data.reportsDB && data.reportsDB.length ? data.reportsDB[0].url_firm : null,
                }

                item_response.items.forEach((item_chk, index) => {
                  if (item_chk.state === 'M') {
                    this.$axios.get(`${process.env.VUE_APP_API_URL}/api/sutech/getNoveltByitem/`, {
                      headers: {
                        token: localStorage.sutech_token,
                        inspections: item._id,
                        checkItem: item_chk.checkItem
                      }
                    })
                        .then(({data}) => {
                          if (data.ok) {
                            item_response.items[index].novelty = data.info[0].description
                            item_response.items[index].date_novelty = moment(new Date(data.info[0].expirationDate)).utc().format('DD/MM/YYYY')
                            item_response.items[index].novelty_state = data.info[0].state

                            this.novelty_pending.push(data.info[0])
                          }
                        })
                        .catch((error) => {
                          this.loading = false
                          console.log('error ' + error)
                        })
                  }

                  let find_item = this.check_items_all.find(item => item._id === item_chk.checkItem)
                  if (find_item.section) item_response.items[index].section = find_item.section
                  else item_response.items[index].section = find_item.section = 0
                })
                this.loading = false
                resolve(item_response);
              }
            })
            .catch((error) => {
              this.loading = false
              console.log('error ' + error)
            })
      })
    },

    async pdfInspection(item = null) {
      this.novelty_pending = []
      this.viewDetailForm = false
      this.itemSelected = null
      await this.getInspection(item)
      this.loading = true
      this.vehicle_inspection_info = await loadVehicleInfo(this.itemSelected.inspection.plateVehicle, this.itemSelected.inspection.driverDocument)
      console.log(this.vehicle_inspection_info)
      window.scroll(0, 0)
      this.generateReport()
    },
    async generateReport() {
      if (this.$store.state.user.brands.name === 'INGEOMEGA') {
        if (this.$refs.html2PdfIngeo) await this.$refs.html2PdfIngeo.generatePdf()
        else {
          setTimeout(async () => {
            await this.$refs.html2PdfIngeo.generatePdf()
          }, 5000)
        }
      } else await this.$refs.html2Pdf.generatePdf()
      this.loading = false
      this.list = true
      this.viewDetailForm = false
    },
    loadTypes() {
      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }

      this.$axios.get(`${process.env.VUE_APP_API_URL}/api/sutech/getModelsInspetionsAll/`, config)
          .then(({data}) => {
            if (data.ok) {
              this.vehicles_types = data.typesInspec
            }
          })
          .catch((error) => {
            console.log('error ' + error)
          })
    },
    clearSearch() {
      this.dates = []
      this.search_fleet = null
      this.search_vehicle_type = null
      this.search_plate = null
    },
    exportPdf() {
      this.list = true
      this.itemSelected = null
      this.loading = true
      this.generateReport()
    },
    async loadExcelData() {
      if (this.data.length) {
        for (const item of this.data) {

          this.novelty_pending = []
          this.itemSelected = null
          await this.getInspection(item)

          let novelties = ''

          if (this.novelty_pending.length) {
            this.novelty_pending.forEach(item => {
              novelties = `${novelties} ${item.description}: ${item.state ? 'Atendida' : 'No Atendida'},`
            })
          }

          const row = {
            plateVehicle: item.plateVehicle,
            driverName: item.driverName,
            currentDate: item.currentDate,
            inspectorName: item.inspectorName,
            nameFleet: item.nameFleet,
            passInspection: this.itemSelected.pass_inspection,
            novelties: novelties,
            typeInspections: item.typeInspections,
          }
          this.json_data.push(row)
        }
      }
    },
    loadItems() {
      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }

      this.$axios.get(`${process.env.VUE_APP_API_URL}/api/sutech/getItemsCheckByFilters?start=0&limit=1000`, config)
          .then(({data}) => {
            if (data.ok) {
              this.check_items_all = data.checkItems
            }
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    priorityLetter(priority) {
      switch (priority) {
        case 'ALTA':
          return 'A'
        case 'MEDIA':
          return 'M'
        case 'BAJA':
          return 'B'
      }
    },
    formatDate(date) {
      return moment(new Date(date)).utc().format('DD/MM/YYYY')
    },
    massivePDF() {
      this.viewPDFdialog = true
      this.selected_data_table.forEach(async (item) => {
        let process_item = this.getInspectionMassive(item)
        process_item.then( async (data) => {
          if (data){
            data.novelty_pending = this.novelty_pending
            data.vehicle_inspection_info = await loadVehicleInfo(data.inspection.plateVehicle, data.inspection.driverDocument)
            this.selected_process_items.push(data)
          }
        })
      })
      setTimeout(async () => {
        this.print_massive = true
      }, 25000)
      setTimeout(async () => {
        this.viewPDFdialog = false
        await this.$refs.html2PdfIngeoMassive.generatePdf()
        this.selected_process_items = []
      }, 30000)
    }
  },
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ')
    },
  },
  watch: {
    selected_data_table: function () {
      if (this.selected_data_table.length > 10) {
        let number_delete = this.selected_data_table.length - 10
        this.selected_data_table.splice(10, number_delete)
      }
    }
  }
}
</script>