<template>
  <div class="background-login w-full h-full flex">
    <v-card shadow="always"
            :loading="loading"
            class="relative rounded-md mx-auto my-auto w-5/6 md:w-1/3 self-center">
      <div class="mx-8 px-4">
        <img src="@/assets/images/Logo-270.png" class="mx-auto my-4">
        <div class="text-primary h4 text-center">Aplicación servicios integrados</div>
        <v-text-field
            class="mb-4"
            :label="$t('user')"
            :rules="rules"
            v-model="user"
            hide-details="auto"
            :error-messages=error_messages
        ></v-text-field>
        <v-text-field
            :label="$t('password')"
            :rules="rules"
            v-model="password"
            hide-details="auto"
            :error-messages=error_messages
            :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show_password ? 'text' : 'password'"
            @click:append="show_password = !show_password"
        ></v-text-field>
        <div class="flex justify-center mx-4 my-8">
          <v-btn
              class="rounded-md text-body-1"
              tile
              color="primary"
              @click="Login"
              :disabled="loading"
          >
            <v-icon left>
              mdi-login-variant
            </v-icon>
            {{ $t('login') }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      loading: false,
      user: null,
      password: null,
      rules: [
        value => !!value || 'Este campo es obligatorio',
      ],
      error_messages: [],
      show_password: false
    }
  },
  beforeCreate() {
    if (localStorage.sutech_token) {
      this.$router.push('/')
    }
  },
  methods: {
    Login() {
      this.error_messages = []
      if (this.user && this.password) {
        this.loading = true
        const body = this.$qs.stringify({
          usuario: this.user,
          password: this.password,
        })
        const url = `${process.env.VUE_APP_API_URL}/api/sutech/loginUsers/`
        this.$axios.post(url, body)
            .then(({data}) => {
              if (data.ok) {
                this.loading = false
                localStorage.sutech_token = data.token;
                this.$store.commit('setUser', data.usuario)
                this.$store.commit('setPermissions', data.permisions)

                this.$router.push('/')
              } else {
                this.error_messages.push(data.response)
                this.loading = false
              }
            })
            .catch((error) => {
              this.loading = false
              console.log('error ' + error)
            })
      }
    }
  }
}
</script>

<style scoped>
.background-login {
  background-image: url("../../src/assets/images/login_background.jpg") !important;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
</style>