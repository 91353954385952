<template>
  <div :id="`mapContainer_${index}`" style='width: 350px; height: 200px;'></div>
</template>

<script>

import mapboxgl from "mapbox-gl";

export default {
  name: "MapBox",
  props: {
    index: {
      default: 0
    },
    lat: null,
    long: null,
  },
  data() {
    return {
      accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
    }
  },
  async mounted() {
    mapboxgl.accessToken = this.accessToken;

    const map = await new mapboxgl.Map({
      container: `mapContainer_${this.index}`, // container id
      style: 'mapbox://styles/mapbox/streets-v11', // style URL
      center: [this.long, this.lat], // starting position [lng, lat]
      zoom: 15, // starting zoom
      attributionControl: false,
    });

    new mapboxgl.Marker()
        .setLngLat([this.long, this.lat])
        .addTo(map);

    map.addControl(new mapboxgl.NavigationControl());
  }
}
</script>
