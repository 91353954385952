<template>
  <div class="container flex flex-wrap">
    <div class="w-full md:w-1/3 px-2 py-4">
      <v-card
      >
        <v-img
            class="white--text align-end"
            height="250px"
            src="@/assets/images/consulting.png"
        >
        </v-img>
        <v-card-text class="text-primary">
          <div>Aplicación para consultar la informacion de personas en el simit</div>
        </v-card-text>
      </v-card>
    </div>
    <div class="w-full md:w-1/3 px-2 py-4">
      <v-card
      >
        <v-img
            class="white--text align-end"
            height="250px"
            src="@/assets/images/inspeccion.png"
        >
        </v-img>
        <v-card-text class="text-primary">
          <div>Aplicacion para realizar las inspecciones preoperativas de los vehiculos</div>
        </v-card-text>
      </v-card>
    </div>
    <div class="w-full md:w-1/3 px-2 py-4">
      <v-card
      >
        <v-img
            class="white--text align-end"
            height="250px"
            src="@/assets/images/speed_limit.png"
        >
        </v-img>
        <v-card-text class="text-primary">
          <div>Aplicacion para revisar los excesos de velocidad de la flota</div>
        </v-card-text>
      </v-card>
    </div>
    <div class="w-full md:w-1/3 px-2 py-4 cursor-pointer">
      <v-card
      >
        <v-img
            class="white--text align-end"
            height="250px"
            src="@/assets/images/fuel.png"
        >
        </v-img>
        <v-card-text class="text-primary">
          <div>Verifique en tiempo real los tanqueos de su flota</div>
        </v-card-text>
      </v-card>
    </div>
    <div class="w-full md:w-1/3 px-2 py-4" @click="goToExternUrl('http://sutech.com.co/')">
      <v-card
      >
        <v-img
            class="white--text align-end"
            height="250px"
            src="@/assets/images/Banner-1.png"
        >
        </v-img>
        <v-card-text class="text-primary">
          <div>soluciones de geolocalización,monitoreo remoto e innovación móvil.</div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  mounted() {
    this.$store.commit('setMenu', true)
    this.$store.commit('getNotification')
  },
  methods:{
    goToUrl(url){
      this.$router.push(url)
    },
    goToExternUrl(url){
      window.open(url,'_blank')
    }
  }
}
</script>
