<template>
  <div>
    <sub-header :name="$t('fuel_control')" :is_master="false"></sub-header>
    <div v-if="loading">
      <loading></loading>
    </div>
    <div v-else class="flex flex-wrap">
      <div class="w-full md:flex mx-4 my-4 items-center border border-primary border-solid">
        <div class="w-full md:w-5/6 px-4">
          <v-row>
            <v-text-field
                class="px-4 w-full md:w-3/12"
                label="Placa"
                v-model="search_plate"
            >
              <v-icon
                  slot="prepend"
              >
                mdi-ab-testing
              </v-icon>
            </v-text-field>
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="dates"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dateRangeText"
                    label="Fecha"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="dates"
                  no-title
                  scrollable
                  range
              >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(dates)"
                >
                  Aceptar
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-row>
        </div>
        <v-btn
            dark
            color="primary"
            text
            @click="loadData"
            class="mx-2"
        >
          Consultar
        </v-btn>
      </div>
      <div class="my-4 mx-4 flex">
        <download-excel
            class="btn btn-default"
            :data="json_data"
            :fields="json_fields"
            :before-generate="loadExcelData"
            worksheet="My Worksheet"
            name="ExportFuelControl.xls"
        >
          <v-btn
              class="mx-2"
              dark
              large
              color="success"
          >
            <v-icon dark>
              mdi-file-excel
            </v-icon>
          </v-btn>
        </download-excel>
      </div>
      <v-data-table
          v-if="data.length"
          :headers="headers"
          :items="data"
          class="elevation-1 w-full mx-4 my-4"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="detailInspection(item)"
          >
            mdi-information-outline
          </v-icon>
        </template>
      </v-data-table>
      <div class="w-full" v-else>
        <empty-state />
      </div>
    </div>
    <div v-if="itemSelected">
      <v-dialog
          v-model="viewDetailForm"
          max-width="800px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Detalle de tanqueo</span>
          </v-card-title>
          <div class="mx-6">
            <div class="md:flex">
              <div class="w-full md:w-1/2">
                <div class="text-gray-3 text-body2"><span
                    class="text-primary">Placa: </span>{{ itemSelected.plate }}
                </div>
              </div>
              <div class="w-full md:w-1/2">
                <div class="text-gray-3 text-body2"><span
                    class="text-primary">Fecha: </span>{{ itemSelected.correct_date }}
                </div>
              </div>
            </div>
            <div class="md:flex">
              <div class="w-full md:w-1/2">
                <div class="text-gray-3 text-body2"><span
                    class="text-primary">Kilometraje: </span>{{ itemSelected.currentMileage }}
                </div>
              </div>
              <div class="w-full md:w-1/2">
                <div class="text-gray-3 text-body2"><span
                    class="text-primary">Galones: </span>{{ itemSelected.totalGallons }}
                </div>
              </div>
            </div>
            <div class="w-full">
              <div class="text-gray-3 text-body2"><span
                  class="text-primary">Dirección: </span>{{ itemSelected.address }}
              </div>
            </div>
            <div class="md:flex">
              <div class="w-full md:w-1/2">
                <map-box :index="itemSelected._id" :lat="itemSelected.latitude"
                         :long="itemSelected.longitude"></map-box>
              </div>
              <div class="w-full md:w-1/2">
                <img alt="Foto" style="width: 350px; height: 200px;"
                    :src="itemSelected.urlImage">
              </div>
            </div>
          </div>
          <v-card-actions class="mt-6">
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="viewDetailForm = false"
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "FuelControl",
  data() {
    return {
      data: [],
      loading: false,
      menu: false,
      dates: [],
      date_start: null,
      date_end: null,
      search_plate: null,
      json_fields: {
        Placa: "plate",
        Fecha: "currentDate",
        "Valor pagado": "paidValue",
        "Total galones": "totalGallons",
        Kilometraje: "currentMileage",
        Dirección: "address",
      },
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      headers: [
        {
          text: 'Placa',
          align: 'start',
          value: 'plate',
        },
        {text: 'Fecha', value: 'currentDate'},
        {text: 'Kilometraje actual', value: 'currentMileage'},
        {text: 'Galones', value: 'totalGallons'},
        {text: 'Valor Pagado', value: 'paidValue'},
        {text: 'Acciones', value: 'actions', sortable: false},
      ],
      viewDetailForm: false,
      itemSelected: null
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.$store.commit('setMessage', {message: null, type: null})
      this.loading = true
      this.data = []
      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }

      let url = null

      url = `${process.env.VUE_APP_API_URL}/api/sutech/getFuelByFilters`

      if (this.search_plate) url = `${url}?plateVehicle=${this.search_plate}`

      if (this.dates.length) {
        const date_start = this.dates[0]
        const date_end = this.dates.length > 1 ? this.dates[1] : null

        if (date_start && !this.search_plate && !this.search_vehicle_type) url = `${url}?startDate=${date_start}`
        else if (date_start && (this.search_plate || this.search_vehicle_type)) url = `${url}&startDate=${date_start}`

        if (date_end && !this.search_plate && !this.search_vehicle_type && !date_start) url = `${url}?endDate=${date_end}`
        else if (date_end && (this.search_plate || this.search_vehicle_type || date_start)) url = `${url}&endDate=${date_end}`
      }

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.ok) {
              this.data = data.fuels
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })

      this.loading = false
    },
    loadExcelData() {
      if (this.data.length){
        this.data.forEach( item => {
          const row = {
            plate: item.plate,
            currentDate: item.currentDate,
            paidValue: item.paidValue,
            totalGallons: item.totalGallons,
            currentMileage: item.currentMileage,
            address: item.address,
          }
          this.json_data.push(row)
        });
      }
    },
    detailInspection(item) {
      this.itemSelected = null
      this.itemSelected = item
      this.itemSelected.correct_date = moment(item.currentDate).utc().format('DD/MM/YYYY')
      this.viewDetailForm = true
    },
  },
  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ')
    },
  },
}
</script>
