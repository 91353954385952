import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import SearchHome from '@/views/SearchHome.vue'
import Login from "@/views/Login";
import FleetCheckHome from "@/views/FleetCheckHome";
import VehicleType from "@/views/VehicleType";
import CheckType from "@/views/CheckType";
import Inspections from "@/views/Inspections";
import Novelties from "@/views/Novelties";
import AdminHome from "@/views/AdminHome";
import Users from "@/views/Users";
import Roles from "@/views/Roles";
import Vehicles from "@/views/Vehicles";
import SpeedLimit from "@/views/SpeedLimit";
import axios from 'axios'
import store from '../store';
import ControlDistance from "@/views/ControlDistance";
import FuelControlHome from "@/views/FuelControlHome";
import FuelControl from "@/views/FuelControl";
import CreateInspection from "@/views/CreateInspection";
import CovidPoll from "../views/CovidPoll";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/search-home',
        name: 'Search',
        component: SearchHome
    },
    {
        path: '/checklist-home',
        name: 'Fleet Check',
        component: FleetCheckHome
    },
    {
        path: '/vehicle-type-check',
        name: 'Vehicle Type Check',
        component: VehicleType
    },
    {
        path: '/items-check',
        name: 'Type Check',
        component: CheckType
    },
    {
        path: '/inspections-check',
        name: 'Inspections',
        component: Inspections
    },
    {
        path: '/novelties-check',
        name: 'Novelties',
        component: Novelties
    },
    {
        path: '/admin-home',
        name: 'Admin Home',
        component: AdminHome
    },
    {
        path: '/users',
        name: 'Users',
        component: Users
    },
    {
        path: '/roles',
        name: 'Roles',
        component: Roles
    },
    {
        path: '/vehicles',
        name: 'Vehicles',
        component: Vehicles
    },
    {
        path: '/limit-speed',
        name: 'Speed Limit',
        component: SpeedLimit
    },
    {
        path: '/control-distance',
        name: 'Control Distance',
        component: ControlDistance
    },
    {
        path: '/fuel-control-admin',
        name: 'Fuel Control Admin',
        component: FuelControlHome
    },
    {
        path: '/fuel-control',
        name: 'Fuel Control',
        component: FuelControl
    },
    {
        path: '/create-inspection',
        name: 'Create Inspection',
        component: CreateInspection
    },
    {
        path: '/covid-home',
        name: 'Covid Poll',
        component: CovidPoll
    },
]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    if (to.name !== 'Login') {
        let config = {
            headers: {
                token: localStorage.sutech_token,
            }
        }
        let url = `${process.env.VUE_APP_API_URL}/api/sutech/validToken/`

        axios.get(url, config)
            .then(({data}) => {
                if (!data.ok) {
                    localStorage.removeItem('sutech_token')
                    store.commit('setMenu', false)
                    router.push('login')
                } else {
                    if (! store.state.user_permissions.length ){
                        axios.get(`${process.env.VUE_APP_API_URL}/api/sutech/getPermissionsByUsuario`, config)
                            .then(({data}) => {
                                if (data.ok) {
                                    store.commit('setUser', data.usuario)
                                    store.commit('setPermissions', data.permisions)
                                } else {
                                    localStorage.removeItem('sutech_token')
                                    store.commit('setMenu', false)
                                    router.push('login')
                                }
                            })
                            .catch((error) => {
                                console.log('error ' + error)
                            })
                    }
                    next()
                }
            })
            .catch((error) => {
                console.log('error ' + error)
            })
    } else {
        next()
    }
})

export default router
