<template>
  <div class="relative mx-4">
    <a @click="isOpen = !isOpen" class="relative block">
      <v-icon>mdi-bell</v-icon>
      <span
          class="absolute top-0 text-white rounded-full h-5 w-5 mr-1 bg-secondary text-caption flex items-center justify-center text-center mt-0 sm:mt-4"
          style="right: 9px" v-if="$store.state.notifications_unread.length">{{
          $store.state.notifications_unread.length
        }}</span>
      <span class="absolute top-0 text-red font-bold" v-else></span>
    </a>
    <button @click="isOpen = false" class="fixed inset-0 bg-black opacity-50  h-full w-full" tabindex="-1"
            v-if="isOpen"></button>
    <div class="block absolute z-30 right-0 w-64 sm:w-128 bg-white rounded-lg shadow-xl" v-if="isOpen">
      <div v-if="$store.state.notifications_unread.length" class="p-2 cursor-pointer">
        <div v-for="notification in $store.state.notifications_unread" :key="notification._id"
             :class="notification.state ? 'bg-white' : 'bg-gray-3'"
             @click="readNotification(notification)">
          <div class="border-b border-gray-2 flex mx-4 my-1">
            <v-icon v-if="notification.type === 'CONSULTING'" class="text-primary">mdi-magnify</v-icon>
            <v-icon v-else class="text-primary">mdi-alert-circle-check-outline</v-icon>
            <div class="text-caption text-gray mx-6">{{ notification.description }}</div>
            <v-icon class="text-gray-2">mdi-chevron-right</v-icon>
          </div>
        </div>
      </div>
      <div class="text-center" v-else>
        <div class="text-lg text-black">No tienes notificaciones</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isOpen: false,
      notifications: [],
      user: [],
      unread: 0,
      read: true,
    }
  },
  mounted() {

  },
  methods: {
    readNotification(notification) {
      if (!notification.state) {
        let config = {
          headers: {
            token: localStorage.sutech_token,
            id: notification._id
          }
        }

        this.$axios.put(`${process.env.VUE_APP_API_URL}/api/sutech/changeStateNotification/`, {}, config)
            .then(({data}) => {
              if (data.ok) {
                this.goToLink(notification.type)
              }
            })
            .catch((error) => {
              this.loading = false
              this.viewCreateForm = false
              console.log('error ' + error)
            })
      } else {
        this.goToLink(notification.type)
      }
    },
    goToLink(type) {
      if (type === 'CONSULTING') this.$router.push('/search-home')
    },
  }
}
</script>
