<template>
  <div>
    <sub-header :name="$t('users')" @newRegister="newRegister"></sub-header>
    <div v-if="loading">
      <loading></loading>
    </div>
    <div v-else class="flex flex-wrap">
      <div class="w-full md:flex mx-4 my-4 items-center border border-primary border-solid">
        <v-text-field
            class="px-4"
            label="Nombre"
            v-model="search_name"
        >
          <v-icon
              slot="prepend"
          >
            mdi-account
          </v-icon>
        </v-text-field>
        <v-btn
            dark
            color="primary"
            text
            @click="loadData"
        >
          Buscar
        </v-btn>
      </div>
      <v-data-table
          v-if="data.length"
          :headers="headers"
          :items="data"
          class="elevation-1 w-full mx-4 my-4"
      >
        <template v-slot:item.state="{ item }">
          <div :class="item.state ? 'text-success' : 'text-danger'">
            {{ item.state ? 'Activo' : 'Inactivo' }}
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              @click="deleteItemConfirm(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <div v-else class="w-full">
        <empty-state @newRegister="newRegister"/>
      </div>
    </div>
    <v-dialog
        v-model="viewCreateForm"
        max-width="600px"
    >
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline">Nuevo Usuario</span>
        </v-card-title>
        <div class="mx-6">
          <v-text-field
              :rules="rules"
              label="Nombre"
              v-model="user_name"
          >
            <v-icon
                slot="prepend"
            >
              mdi-account
            </v-icon>
          </v-text-field>
          <v-select
              :rules="rules"
              v-model="role"
              :items="roles"
              label="Rol"
              item-text="name"
              item-value="_id"
          >
            <v-icon
                slot="prepend"
            >
              mdi-sort-bool-descending-variant
            </v-icon>
          </v-select>
          <v-text-field
              :rules="rules"
              label="Usuario"
              v-model="user"
          >
            <v-icon
                slot="prepend"
            >
              mdi-account-key
            </v-icon>
          </v-text-field>
          <v-text-field
              v-if="!item_edit"
              :rules="rules"
              label="Contraseña"
              v-model="user_password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              counter
              @click:append="show1 = !show1"
          >
            <v-icon
                slot="prepend"
            >
              mdi-lock
            </v-icon>
          </v-text-field>
          <v-text-field
              label="Teléfono"
              v-model="phone"
          >
            <v-icon
                slot="prepend"
            >
              mdi-cellphone-text
            </v-icon>
          </v-text-field>
          <v-text-field
              label="Correo electronico"
              v-model="email"
          >
            <v-icon
                slot="prepend"
            >
              mdi-at
            </v-icon>
          </v-text-field>
          <v-text-field
              label="Documento"
              v-model="document"
          >
            <v-icon
                slot="prepend"
            >
              mdi-card-account-details
            </v-icon>
          </v-text-field>
          <v-text-field
              label="Cargo"
              v-model="position"
          >
            <v-icon
                slot="prepend"
            >
              mdi-card-account-details
            </v-icon>
          </v-text-field>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="viewCreateForm = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
              v-if="item_edit"
              color="primary"
              text
              :disabled="loading"
              @click="updateUser"
          >
            {{ $t('save') }}
          </v-btn>
          <v-btn v-else
                 color="primary"
                 text
                 :disabled="loading"
                 @click="saveUser"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="delete_dialog"
        persistent
        max-width="500"
    >
      <v-card class="w-full">
        <div class="headline m-6 text-gray-2 text-body">
          Esta seguro que desea eliminar este elemento ?
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="delete_dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
              color="primary"
              @click="deleteItem(item_delete)"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Users",
  data() {
    return {
      loading: false,
      search_name: null,
      data: [],
      headers: [
        {
          text: 'Nombre',
          align: 'start',
          value: 'name',
        },
        {text: 'Usuario', value: 'usuario'},
        {text: 'Estado', value: 'state'},
        {text: 'Email', value: 'email'},
        {text: 'Telefono', value: 'phone'},
        {text: 'Rol', value: 'role.name'},
        {text: 'Acciones', value: 'actions', sortable: false},
      ],
      viewCreateForm: false,
      rules: [
        value => !!value || 'Este campo es obligatorio',
      ],
      show1: false,
      user_name: null,
      user: null,
      user_password: null,
      item_edit: false,
      delete_dialog: false,
      item_delete: null,
      id_edit: null,
      role: null,
      roles: [],
      position: null,
      document: null,
      email: null,
      phone: null,
    }
  },
  mounted() {
    this.loadData()
    this.loadRoles()
  },
  methods: {
    newRegister() {
      this.user_name = null
      this.user = null
      this.role = null
      this.item_edit = false
      this.id_edit = null
      this.user_password = null
      this.viewCreateForm = true;
    },
    loadData() {
      this.loading = true

      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }

      let url = `${process.env.VUE_APP_API_URL}/api/sutech/getUsersByBrands`
      if (this.search_name) url = `${url}?name=${this.search_name}`
      this.data = []
      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.ok) {
              this.data = data.usuarios
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    editItem(item) {
      this.user_name = item.name
      this.user = item.usuario
      this.role = item.role._id
      this.item_edit = true
      this.id_edit = item._id
      this.viewCreateForm = true;
    },
    deleteItemConfirm(item) {
      this.item_delete = item
      this.delete_dialog = true
    },
    updateUser() {
      if (this.user_name && this.user && this.role) {
        this.loading = true
        const body = this.$qs.stringify({
          name: this.user_name,
          usuario: this.user,
          role: this.role,
          position: this.position,
          document: this.document,
          email: this.email,
          phone: this.phone,
        })

        let config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            token: localStorage.sutech_token,
            id: this.id_edit
          }
        }

        this.$axios.put(`${process.env.VUE_APP_API_URL}/api/sutech/updateUserById`, body, config)
            .then(({data}) => {
              if (data.ok) {
                this.viewCreateForm = false
                this.loadData()
              }
              this.loading = false
            })
            .catch((error) => {
              this.loading = false
              this.viewCreateForm = false
              console.log('error ' + error)
            })
      }
    },
    saveUser() {
      if (this.user_name && this.user && this.user_password && this.role) {
        this.loading = true
        const body = this.$qs.stringify({
          name: this.user_name,
          usuario: this.user,
          password: this.user_password,
          role: this.role,
          position: this.position,
          document: this.document,
          email: this.email,
          phone: this.phone,
          brands: this.$store.state.user.brands._id,
        })

        let config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            token: localStorage.sutech_token,
          }
        }

        this.$axios.post(`${process.env.VUE_APP_API_URL}/api/sutech/AddUsers/`, body, config)
            .then(({data}) => {
              if (data.ok) {
                this.viewCreateForm = false
                this.loadData()
              }
              this.loading = false
            })
            .catch((error) => {
              this.loading = false
              this.viewCreateForm = false
              console.log('error ' + error)
            })
      }
    },
    deleteItem(item) {
      this.loading = true
      let config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          token: localStorage.sutech_token,
          id: item._id
        }
      }
      this.$axios.delete(`${process.env.VUE_APP_API_URL}/api/sutech/deleteUsuarioById`, config)
          .then(({data}) => {
            if (data.ok) {
              this.data = this.data.filter(value => {
                return value._id !== item._id
              })
              this.delete_dialog = false
              this.item_delete = null
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    loadRoles() {
      this.loading = true

      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }

      let url = `${process.env.VUE_APP_API_URL}/api/sutech/getRolesByBrands/`
      this.data = []
      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.ok) {
              this.roles = data.roles
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    }
  }
}
</script>
