<template>
  <div>
    <sub-header :name="$t('vehicles')" @newRegister="newRegister"></sub-header>
    <div v-if="loading">
      <loading></loading>
    </div>
    <div v-else class="flex flex-wrap">
      <div class="w-full md:flex mx-4 my-4 items-center border border-primary border-solid">
        <v-text-field
            class="px-4"
            label="Placa"
            v-model="search_plate"
        >
          <v-icon
              slot="prepend"
          >
            mdi-car
          </v-icon>
        </v-text-field>
        <v-btn
            dark
            color="primary"
            text
            @click="loadData"
        >
          Buscar
        </v-btn>
      </div>
      <v-data-table
          v-if="data.length"
          :headers="headers"
          :items="data"
          class="elevation-1 w-full mx-4 my-4"
      >
        <template v-slot:item.state="{ item }">
          <div :class="item.state ? 'text-success' : 'text-danger'">
            {{ item.state ? 'Activo' : 'Inactivo' }}
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="detailItem(item)"
          >
            mdi-car-info
          </v-icon>
          <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              @click="deleteItemConfirm(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <div v-else class="w-full">
        <empty-state @newRegister="newRegister"/>
      </div>
    </div>
    <v-dialog
        v-model="viewCreateForm"
        max-width="600px"
    >
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline">Nuevo vehículo</span>
        </v-card-title>
        <div class="mx-6">
          <v-text-field
              :rules="rules"
              label="Placa"
              v-model="plate"
          >
            <v-icon
                slot="prepend"
            >
              mdi-car
            </v-icon>
          </v-text-field>
          <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  :rules="rules"
                  v-model="dateFormattedSoat"
                  label="SOAT"
                  hint="MM/DD/YYYY"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  @blur="date_soat = parseDate(dateFormattedSoat)"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="date_soat"
                no-title
                @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  :rules="rules"
                  v-model="dateFormattedTechnoMechanics"
                  label="Tecno mecanica"
                  hint="MM/DD/YYYY"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  @blur="date_techno_mechanics = parseDate(dateFormattedTechnoMechanics)"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="date_techno_mechanics"
                no-title
                @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
              ref="menu4"
              v-model="menu4"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="dateEnroll"
                  label="Inscripción"
                  hint="MM/DD/YYYY"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  @blur="date_enroll = parseDate(dateEnroll)"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="date_enroll"
                no-title
                @input="menu4 = false"
            ></v-date-picker>
          </v-menu>
          <v-text-field
              label="Conductor"
              v-model="driver_name"
          >
            <v-icon
                slot="prepend"
            >
              mdi-car-seat
            </v-icon>
          </v-text-field>
          <v-text-field
              label="Documento conductor"
              v-model="driver_document"
          >
            <v-icon
                slot="prepend"
            >
              mdi-card-account-details
            </v-icon>
          </v-text-field>
          <v-menu
              ref="menu5"
              v-model="menu5"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="dateDriverLicense"
                  label="Expiración licencia"
                  hint="MM/DD/YYYY"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  @blur="date_driver_license = parseDate(dateDriverLicense)"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="date_driver_license"
                no-title
                @input="menu5 = false"
            ></v-date-picker>
          </v-menu>
          <v-text-field
              label="Modelo"
              v-model="model"
          >
            <v-icon
                slot="prepend"
            >
              mdi-numeric
            </v-icon>
          </v-text-field>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="viewCreateForm = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
              v-if="item_edit"
              color="primary"
              text
              :disabled="loading"
              @click="updateVehicle"
          >
            {{ $t('save') }}
          </v-btn>
          <v-btn v-else
                 color="primary"
                 text
                 :disabled="loading"
                 @click="saveVehicle"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="vehicle_selected">
      <v-dialog
          v-model="detail_form"
          max-width="600px"
      >
        <v-card :loading="loading">
          <v-card-title>
            <span class="headline">Detalle de vehiculo - {{ vehicle_selected.plate }}</span>
          </v-card-title>
          <div class="mx-6">
            <div class="flex flex-wrap">
              <div class="w-1/2 text-primary text-body2 pr-2">
                <div class="w-full text-secondary text-body border-b border-primary mt-6">
                  Fecha de inscripción
                </div>
                <div class="text-body2 text-gray-2 mt-2">
                  {{ vehicle_selected.enrollmentDate }}
                </div>
              </div>
              <div class="w-1/2 text-primary text-body2 pl-2">
                <div class="w-full text-secondary text-body border-b border-primary mt-6">
                  Estado
                </div>
                <div :class="vehicle_selected.state ? 'text-success' : 'text-danger'" class="mt-2">
                  {{ vehicle_selected.state ? 'Activo' : 'Inactivo' }}
                </div>
              </div>
            </div>
            <div class="flex flex-wrap">
              <div class="w-1/2 text-primary text-body2 pr-2">
                <div class="w-full text-secondary text-body border-b border-primary mt-6">
                  SOAT
                </div>
                <div class="text-body2 text-gray-2 mt-2">
                  {{ vehicle_selected.soat }}
                </div>
              </div>
              <div class="w-1/2 text-primary text-body2 pl-2">
                <div class="w-full text-secondary text-body border-b border-primary mt-6">
                  Tecno Mecanica
                </div>
                <div class="text-body2 text-gray-2 mt-2">
                  {{ vehicle_selected.technoMechanics }}
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>
    <v-dialog
        v-model="delete_dialog"
        persistent
        max-width="500"
    >
      <v-card class="w-full">
        <div class="headline m-6 text-gray-2 text-body">
          Esta seguro que desea eliminar este elemento ?
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="delete_dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
              color="primary"
              @click="deleteItem(item_delete)"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Vehicles",
  data() {
    return {
      loading: false,
      viewCreateForm: false,
      data: [],
      rules: [
        value => !!value || 'Este campo es obligatorio',
      ],
      item_edit: false,
      plate: null,
      date_soat: null,
      menu1: false,
      date_techno_mechanics: null,
      menu2: false,
      date_enroll: null,
      menu4: false,
      date_driver_license: null,
      menu5: false,
      search_plate: null,
      headers: [
        {
          text: 'Placa',
          align: 'start',
          value: 'plate',
        },
        {text: 'Conductor', value: 'driver.name'},
        {text: 'Estado', value: 'state'},
        {text: 'Acciones', value: 'actions', sortable: false},
      ],
      vehicle_selected: null,
      detail_form: false,
      id_edit: null,
      delete_dialog: false,
      item_delete: null,
      enrollmentDate: null,
      driverLicense: null,
      model: null,
      driver_name: null,
      driver_document: null,
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    newRegister() {
      this.viewCreateForm = true;
    },
    loadData() {
      this.loading = true

      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }

      let url = `${process.env.VUE_APP_API_URL}/api/sutech/getMovilesByfilters`
      if (this.search_plate) url = `${url}?plate=${this.search_plate}`
      this.data = []
      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.ok) {
              this.data = data.movil
              this.loading = false
            } else {
              this.loading = false
            }
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    saveVehicle() {
      this.loading = true
      const body = this.$qs.stringify({
        plate: this.plate,
        soat: this.date_soat,
        license: this.date_license,
        technoMechanics: this.date_techno_mechanics,
        enrollmentDate: this.date_enroll,
        driverName: this.driver_name,
        driverDocument: this.driver_document,
        driverLicense: this.date_driver_license,
        model: this.model,
      })

      let config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          token: localStorage.sutech_token,
        }
      }

      this.$axios.post(`${process.env.VUE_APP_API_URL}/api/sutech/sutAddMoviles/`, body, config)
          .then(({data}) => {
            if (data.ok) {
              this.viewCreateForm = false
              this.loading = false
              this.loadData()
            } else {
              this.loading = false
            }
          })
          .catch((error) => {
            this.loading = false
            this.viewCreateForm = false
            console.log('error ' + error)
          })
    },
    updateVehicle() {
      this.loading = true
      const body = this.$qs.stringify({
        plate: this.plate,
        soat: this.date_soat,
        license: this.date_license,
        technoMechanics: this.date_techno_mechanics,
        enrollmentDate: this.date_enroll,
        driverName: this.driver_name,
        driverDocument: this.driver_document,
        driverLicense: this.date_driver_license,
        model: this.model,
      })

      let config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          token: localStorage.sutech_token,
        }
      }

      this.$axios.put(`${process.env.VUE_APP_API_URL}/api/sutech/updateMovilesById/id=${this.id_edit}`, body, config)
          .then(({data}) => {
            if (data.ok) {
              this.viewCreateForm = false
              this.loadData()
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            this.viewCreateForm = false
            console.log('error ' + error)
          })
    },
    detailItem(item) {
      this.vehicle_selected = item
      this.detail_form = true
    },
    editItem(item) {
      console.log(item)
      this.plate = item.plate
      this.date_soat = item.soat.slice(0,10)
      this.date_enroll = item.enrollmentDate.slice(0,10)
      this.date_techno_mechanics = item.technoMechanics.slice(0,10)
      this.driver_name = item.driverDate.name
      this.driver_document = item.driverDate.document
      this.date_driver_license = item.driverDate.lisence
      this.item_edit = true
      this.model = item.model
      this.id_edit = item._id
      this.viewCreateForm = true;
    },
    deleteItemConfirm(item) {
      this.item_delete = item
      this.delete_dialog = true
    },
    deleteItem(item) {
      this.loading = true
      let config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          token: localStorage.sutech_token,
        }
      }
      this.$axios.delete(`${process.env.VUE_APP_API_URL}/api/sutech/sutDeleteMovilById/id=${item._id}`, config)
          .then(({data}) => {
            if (data.ok) {
              this.loading = false
              this.data = this.data.filter(value => {
                return value._id !== item._id
              })
              this.delete_dialog = false
              this.item_delete = null
            } else {
              this.loading = false
            }
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
  },
  computed: {
    dateFormattedSoat() {
      return this.formatDate(this.date_soat)
    },
    dateFormattedTechnoMechanics() {
      return this.formatDate(this.date_techno_mechanics)
    },
    dateEnroll(){
      return this.formatDate(this.date_enroll)
    },
    dateDriverLicense(){
      return this.formatDate(this.date_driver_license)
    }
  },
}
</script>
