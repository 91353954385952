<template>
  <div class="flex border-b border-secondary pb-2 m-4">
    <div class="text-secondary h4 w-1/2">{{ name }}</div>
    <div class="w-1/2 text-right" v-if="is_master">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              fab
              dark
              small
              v-bind="attrs"
              v-on="on"
              color="primary"
              @click="newRegister"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('new_register') }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubHeader",
  props: {
    name: null,
    is_master: {
      default: true
    },
  },
  methods:{
    newRegister(){
      this.$emit('newRegister')
    }
  }
}
</script>
