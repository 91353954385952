<template>
  <div>
    <div v-if="proccesing_data" class="w-full mt-16 flex flex-column justify-center">
      <div class="flex justify-center mt-16">
        <img src="@/assets/images/Cube_loading.svg" alt="Triangle with three unequal sides"/>
      </div>
      <div class="text-body text-gray-2 my-4 w-full text-center">Estamos procesando tu consulta, te notificaremos cuando
        este terminada
      </div>
      <div class="flex justify-center mt-6">
        <v-btn
            class="rounded-md w-1/5"
            tile
            color="primary"
            @click="backToHome">
          <v-icon left>
            mdi-keyboard-backspace
          </v-icon>
          Atras
        </v-btn>
      </div>
    </div>
    <div v-else>
      <v-switch
          v-model="massiveSearch"
          @change="switchSearch"
          class="py-2 mx-4"
          inset
          :label="$t('massive_search')"
      ></v-switch>
      <div class="md:flex">
        <div class="w-full md:w-1/2 md:flex" v-if="!massiveSearch">
          <div class="items-center border-b border-primary py-2 mx-2 my-4 w-full">
            <input
                class="appearance-none bg-transparent border-none w-full mr-3 py-1 px-2 leading-tight focus:outline-none"
                v-model="document"
                type="text"
                placeholder="Cedula"
                aria-label="Cedula">
          </div>
        </div>
        <div v-else class="w-full md:w-1/2">
          <v-file-input
              v-model="file"
              class="w-full m-2"
              accept=".xls,.xlsx"
              label="File input"
          ></v-file-input>
        </div>
        <div class="flex w-full md:w-1/2 self-center mx-6">
          <v-btn
              class="rounded-md"
              tile
              color="primary"
              :loading="loading"
              :disabled="loading"
              @click="search"
          >
            <v-icon left>
              mdi-magnify
            </v-icon>
            {{ $t('search') }}
            <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
            </template>
          </v-btn>
          <a v-if="massiveSearch"
             :href="`https://prod-sutechapi.azurewebsites.net/api/sutech/bringExcelAnswer/${user_id}`" target="_blank">
            <v-btn
                class="rounded-md mx-4"
                outlined
                color="teal"
            >
              <v-icon class="mx-2">mdi-microsoft-excel</v-icon>
              Exportar Excel
            </v-btn>
          </a>
          <a v-if="massiveSearch"
             :href="`https://prod-sutechapi.azurewebsites.net/api/sutech/getFormatExcel`" target="_blank">
            <v-btn
                class="rounded-md mx-4"
                color="success"
            >
              <v-icon class="mx-2">mdi-microsoft-excel</v-icon>
              Descargar plantilla
            </v-btn>
          </a>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div v-if="data.length" class="w-full fill-height">
          <div v-if="massiveSearch">
            <v-tabs
                v-model="tab"
                center-active
            >
              <v-tab v-for="(item,index) in data" :key="index">
                {{ item.namePage }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item
                  v-for="(item,index) in data" :key="index"
              >
                <div class="flex flex-wrap">
                  <div @click="viewItemDetail(item)" :key="index"
                       class="w-full md:w-1/3 px-2 my-2 md:my-0 cursor-pointer"
                       v-for="(item,index) in item.dataPage">
                    <v-card shadow="always" class="relative rounded-md bg-white border-none">
                      <div class="flex p-2">
                        <div class="w-1/3 text-center self-center">
                          <img class="h-24 mb-4 mx-auto" src="@/assets/images/avatar.png">
                        </div>
                        <div class="w-2/3">
                          <div class="text-primary text-body2">{{
                              item.NOMBRE ? item.NOMBRE : 'Nombre del sujeto'
                            }}
                          </div>
                          <div class="text-gray-3 text-body2">{{
                              item.CEDULA ? item.CEDULA : 'Documento del sujeto'
                            }}
                          </div>
                          <div v-if="item.DATOS" class="text-body2"
                               :class="!item.DATOS.Comparendos.length ? 'text-green' : 'text-red'"><span
                              class="text-gray-3">Comparendos: </span>
                            {{ item.DATOS.Comparendos.length ? 'Tiene' : 'No Tiene' }}
                          </div>
                          <div v-if="item.DATOS" class="text-body2"
                               :class="!item.DATOS.Resoluciones.length ? 'text-green' : 'text-red'"><span
                              class="text-gray-3">Resoluciones: </span>
                            {{ item.DATOS.Resoluciones.length ? 'Tiene' : 'No Tiene' }}
                          </div>
                          <div v-if="item.DATOS" class="text-body2"
                               :class="!item.DATOS.Acuerdos.length ? 'text-green' : 'text-red'"><span
                              class="text-gray-3">Acuerdos: </span>
                            {{ item.DATOS.Acuerdos.length ? 'Tiene' : 'No Tiene' }}
                          </div>
                        </div>
                      </div>
                    </v-card>
                  </div>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </div>
          <div v-else>
            <div @click="viewItemDetail(item)" :key="index" class="w-full md:w-1/3 px-2 my-2 md:my-0 cursor-pointer"
                 v-for="(item,index) in data">
              <v-card shadow="always" class="relative rounded-md bg-white border-none">
                <div class="flex p-2">
                  <div class="w-1/3 text-center self-center">
                    <img class="h-24 mb-4 mx-auto" src="@/assets/images/avatar.png">
                  </div>
                  <div class="w-2/3">
                    <div class="text-primary text-body2">{{ item.name ? item.name : 'Nombre del sujeto' }}</div>
                    <div class="text-gray-3 text-body2">{{
                        item.document ? item.document : 'Documento del sujeto'
                      }}
                    </div>
                    <div v-if="item.datos" class="text-body2"
                         :class="!item.datos.Comparendos.length ? 'text-green' : 'text-red'"><span
                        class="text-gray-3">Comparendos: </span>
                      {{ item.datos.Comparendos.length ? 'Tiene' : 'No Tiene' }}
                    </div>
                    <div v-if="item.datos" class="text-body2"
                         :class="!item.datos.Resoluciones.length ? 'text-green' : 'text-red'"><span
                        class="text-gray-3">Resoluciones: </span>
                      {{ item.datos.Resoluciones.length ? 'Tiene' : 'No Tiene' }}
                    </div>
                    <div v-if="item.datos" class="text-body2"
                         :class="!item.datos.Acuerdos.length ? 'text-green' : 'text-red'"><span
                        class="text-gray-3">Acuerdos: </span>
                      {{ item.datos.Acuerdos.length ? 'Tiene' : 'No Tiene' }}
                    </div>
                  </div>
                </div>
              </v-card>
            </div>
          </div>
        </div>
      </div>
      <div v-if="viewDetail">
        <v-dialog
            v-model="viewDetail"
            max-width="500"
        >
          <v-card v-if="massiveSearch">
            <div class="flex flex-wrap m-4">
              <div class="w-full text-body2 text-primary">{{ itemDetail.NOMBRE }}</div>
              <div class="w-full">
                <div class="text-gray-3 text-body2"><span
                    class="text-primary">N° Documento: </span>{{ itemDetail.CEDULA }}
                </div>
              </div>
            </div>
            <div v-if="itemDetail.DATOS.Comparendos.length">
              <div
                  class="bg-secondary text-center text-body2 text-gray-4 mx-4 my-4 border-b border-solid border-gray p-2">
                Comparendos
              </div>
              <div class="mx-4 mb-4 border-b border-solid border-gray-3 pb-2" :key="index"
                   v-for="(comparendo,index) in itemDetail.DATOS.Comparendos">
                <div class="w-full">
                  <div class="text-gray-3 text-body2"><span
                      class="text-primary">Comparendo: </span>{{ comparendo.Comparendo }}
                  </div>
                </div>
                <div class="w-full">
                  <div class="text-gray-3 text-body2"><span
                      class="text-primary">Secretaria: </span>{{ comparendo.Seretaria }}
                  </div>
                </div>
                <div class="md:flex">
                  <div class="w-full md:w-1/2">
                    <div class="text-gray-3 text-body2"><span
                        class="text-primary">Estado: </span>{{ comparendo.Estado }}
                    </div>
                  </div>
                  <div class="w-full md:w-1/2">
                    <div class="text-gray-3 text-body2"><span
                        class="text-primary">Infracción: </span>{{ comparendo.Infraccion }}
                    </div>
                  </div>
                </div>
                <div class="md:flex">
                  <div class="w-full md:w-1/2">
                    <div class="text-gray-3 text-body2"><span
                        class="text-primary">Fecha: </span>{{ comparendo.FechaComparendo }}
                    </div>
                  </div>
                  <div class="w-full md:w-1/2">
                    <div class="text-gray-3 text-body2"><span
                        class="text-primary">Valor Multa: </span>{{ comparendo.ValorPagar }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="itemDetail.DATOS.Resoluciones.length">
              <div
                  class="text-body2 text-gray-4 mx-4 my-4 border-b border-solid border-gray bg-secondary text-center p-2">
                Resoluciones
              </div>
              <div class="mx-4 mb-4 border-b border-solid border-gray-3 pb-2" :key="index"
                   v-for="(resolucion,index) in itemDetail.DATOS.Resoluciones">
                <div class="w-full">
                  <div class="text-gray-3 text-body2"><span
                      class="text-primary">Comparendo: </span>{{ resolucion.Comparendo }}
                  </div>
                </div>
                <div class="w-full">
                  <div class="text-gray-3 text-body2"><span
                      class="text-primary">Secretaria: </span>{{ resolucion.Secretaria }}
                  </div>
                </div>
                <div class="md:flex">
                  <div class="w-full md:w-1/2">
                    <div class="text-gray-3 text-body2"><span
                        class="text-primary">Fecha: </span>{{ resolucion.FechaComparendo }}
                    </div>
                  </div>
                  <div class="w-full md:w-1/2">
                    <div class="text-gray-3 text-body2"><span
                        class="text-primary">Resolucion: </span>{{ resolucion.Resolucion }}
                    </div>
                  </div>
                </div>
                <div class="md:flex">
                  <div class="w-full md:w-1/2">
                    <div class="text-gray-3 text-body2"><span
                        class="text-primary">Estado: </span>{{ resolucion.Estado }}
                    </div>
                  </div>
                  <div class="w-full md:w-1/2">
                    <div class="text-gray-3 text-body2"><span
                        class="text-primary">Valor Multa: </span>{{ resolucion.ValorPagar }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="itemDetail.DATOS.Acuerdos.length">
              <div
                  class="text-body2 text-gray-4 mx-4 my-4 border-b border-solid border-gray bg-secondary text-center p-2">
                Resoluciones
              </div>
              <div class="mx-4 mb-4 border-b border-solid border-gray-3 pb-2" :key="index"
                   v-for="(acuerdo,index) in itemDetail.DATOS.Acuerdos">
                <div class="w-full">
                  <div class="text-gray-3 text-body2"><span
                      class="text-primary">Comparendo: </span>{{ acuerdo.Comparendo }}
                  </div>
                </div>
                <div class="w-full">
                  <div class="text-gray-3 text-body2"><span
                      class="text-primary">Secretaria: </span>{{ acuerdo.Secretaria }}
                  </div>
                </div>
                <div class="md:flex">
                  <div class="w-full md:w-1/2">
                    <div class="text-gray-3 text-body2"><span
                        class="text-primary">Fecha: </span>{{ acuerdo.FechaComparendo }}
                    </div>
                  </div>
                  <div class="w-full md:w-1/2">
                    <div class="text-gray-3 text-body2"><span
                        class="text-primary">Resolucion: </span>{{ acuerdo.Resolucion }}
                    </div>
                  </div>
                </div>
                <div class="md:flex">
                  <div class="w-full md:w-1/2">
                    <div class="text-gray-3 text-body2"><span
                        class="text-primary">Estado: </span>{{ acuerdo.Estado }}
                    </div>
                  </div>
                  <div class="w-full md:w-1/2">
                    <div class="text-gray-3 text-body2"><span
                        class="text-primary">Valor Multa: </span>{{ acuerdo.ValorPagar }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
          <v-card v-else>
            <div class="flex flex-wrap m-4">
              <div class="w-full text-body2 text-primary">{{ itemDetail.name }}</div>
              <div class="w-full">
                <div class="text-gray-3 text-body2"><span
                    class="text-primary">N° Documento: </span>{{ itemDetail.document }}
                </div>
              </div>
            </div>
            <div v-if="itemDetail.datos.Comparendos.length">
              <div
                  class="bg-secondary text-center text-body2 text-gray-4 mx-4 my-4 border-b border-solid border-gray p-2">
                Comparendos
              </div>
              <div class="mx-4 mb-4 border-b border-solid border-gray-3 pb-2" :key="index"
                   v-for="(comparendo,index) in itemDetail.datos.Comparendos">
                <div class="w-full">
                  <div class="text-gray-3 text-body2"><span
                      class="text-primary">Comparendo: </span>{{ comparendo.Comparendo }}
                  </div>
                </div>
                <div class="w-full">
                  <div class="text-gray-3 text-body2"><span
                      class="text-primary">Secretaria: </span>{{ comparendo.Seretaria }}
                  </div>
                </div>
                <div class="md:flex">
                  <div class="w-full md:w-1/2">
                    <div class="text-gray-3 text-body2"><span
                        class="text-primary">Estado: </span>{{ comparendo.Estado }}
                    </div>
                  </div>
                  <div class="w-full md:w-1/2">
                    <div class="text-gray-3 text-body2"><span
                        class="text-primary">Infracción: </span>{{ comparendo.Infraccion }}
                    </div>
                  </div>
                </div>
                <div class="md:flex">
                  <div class="w-full md:w-1/2">
                    <div class="text-gray-3 text-body2"><span
                        class="text-primary">Fecha: </span>{{ comparendo.FechaComparendo }}
                    </div>
                  </div>
                  <div class="w-full md:w-1/2">
                    <div class="text-gray-3 text-body2"><span
                        class="text-primary">Valor Multa: </span>{{ comparendo.ValorPagar }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="itemDetail.datos.Resoluciones.length">
              <div
                  class="text-body2 text-gray-4 mx-4 my-4 border-b border-solid border-gray bg-secondary text-center p-2">
                Resoluciones
              </div>
              <div class="mx-4 mb-4 border-b border-solid border-gray-3 pb-2" :key="index"
                   v-for="(resolucion,index) in itemDetail.datos.Resoluciones">
                <div class="w-full">
                  <div class="text-gray-3 text-body2"><span
                      class="text-primary">Comparendo: </span>{{ resolucion.Comparendo }}
                  </div>
                </div>
                <div class="w-full">
                  <div class="text-gray-3 text-body2"><span
                      class="text-primary">Secretaria: </span>{{ resolucion.Secretaria }}
                  </div>
                </div>
                <div class="md:flex">
                  <div class="w-full md:w-1/2">
                    <div class="text-gray-3 text-body2"><span
                        class="text-primary">Fecha: </span>{{ resolucion.FechaComparendo }}
                    </div>
                  </div>
                  <div class="w-full md:w-1/2">
                    <div class="text-gray-3 text-body2"><span
                        class="text-primary">Resolucion: </span>{{ resolucion.Resolucion }}
                    </div>
                  </div>
                </div>
                <div class="md:flex">
                  <div class="w-full md:w-1/2">
                    <div class="text-gray-3 text-body2"><span
                        class="text-primary">Estado: </span>{{ resolucion.Estado }}
                    </div>
                  </div>
                  <div class="w-full md:w-1/2">
                    <div class="text-gray-3 text-body2"><span
                        class="text-primary">Valor Multa: </span>{{ resolucion.ValorPagar }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="itemDetail.datos.Acuerdos.length">
              <div
                  class="text-body2 text-gray-4 mx-4 my-4 border-b border-solid border-gray bg-secondary text-center p-2">
                Resoluciones
              </div>
              <div class="mx-4 mb-4 border-b border-solid border-gray-3 pb-2" :key="index"
                   v-for="(acuerdo,index) in itemDetail.datos.Acuerdos">
                <div class="w-full">
                  <div class="text-gray-3 text-body2"><span
                      class="text-primary">Comparendo: </span>{{ acuerdo.Comparendo }}
                  </div>
                </div>
                <div class="w-full">
                  <div class="text-gray-3 text-body2"><span
                      class="text-primary">Secretaria: </span>{{ acuerdo.Secretaria }}
                  </div>
                </div>
                <div class="md:flex">
                  <div class="w-full md:w-1/2">
                    <div class="text-gray-3 text-body2"><span
                        class="text-primary">Fecha: </span>{{ acuerdo.FechaComparendo }}
                    </div>
                  </div>
                  <div class="w-full md:w-1/2">
                    <div class="text-gray-3 text-body2"><span
                        class="text-primary">Resolucion: </span>{{ acuerdo.Resolucion }}
                    </div>
                  </div>
                </div>
                <div class="md:flex">
                  <div class="w-full md:w-1/2">
                    <div class="text-gray-3 text-body2"><span
                        class="text-primary">Estado: </span>{{ acuerdo.Estado }}
                    </div>
                  </div>
                  <div class="w-full md:w-1/2">
                    <div class="text-gray-3 text-body2"><span
                        class="text-primary">Valor Multa: </span>{{ acuerdo.ValorPagar }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchView',
  props: {
    iconName: {
      type: String,
      default: 'box'
    },
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    }
  },
  data() {
    return {
      document: null,
      massiveSearch: false,
      file: null,
      data: [],
      itemDetail: null,
      viewDetail: false,
      loading: false,
      proccesing_data: false,
      tab: null,
      user_id: null
    }
  },
  mounted() {
    this.$store.commit('getNotification')
    this.user_id = this.$store.state.user._id
  },
  methods: {
    switchSearch() {
      this.document = null
      this.file = null
      this.data = []
      this.validateProcess()
    },
    viewItemDetail(item) {
      this.itemDetail = item
      this.viewDetail = true
    },
    search() {
      this.$store.commit('setMessage', {message: null, type: null})
      this.loading = true
      this.data = []
      if (this.massiveSearch) {
        let formData = new FormData();
        formData.append('file', this.file);
        let config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            token: localStorage.sutech_token,
          }
        }
        const url = `${process.env.VUE_APP_API_URL}/api/sutech/getSimitMasiveNew/`
        this.$axios.post(url, formData, config)
            .then(({data}) => {
              if (data.ok) {
                this.loading = false
                this.proccesing_data = true
              }
            })
            .catch((error) => {
              console.log('error ' + error)
            })
      } else {
        const body = this.$qs.stringify({
          document: this.document,
        })
        let config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            token: localStorage.sutech_token
          }
        }
        const url = `${process.env.VUE_APP_API_URL}/api/sutech/getSimitFinesOne/`
        this.$axios.post(url, body, config)
            .then(({data}) => {
              if (data.ok) {
                this.loading = false
                if (data.consultas.length) {
                  data.consultas.forEach(item => {
                    if ((item.datos.Comparendos && item.datos.Comparendos.length) || (item.datos.Resoluciones && item.datos.Resoluciones.length)) {
                      this.data.push(item)
                    }
                  })
                  if (!this.data.length) {
                    this.$store.commit('setMessage', {
                      message: 'Los documentos ingresados no tienen multas registradas en el simit',
                      type: 'info'
                    })
                  }
                }
              }
            })
            .catch((error) => {
              console.log('error ' + error)
            })
      }
    },
    validateProcess() {
      this.loading = true
      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }
      const url = `${process.env.VUE_APP_API_URL}/api/sutech/getProcessByUsuario`
      this.$axios.get(url, config).then(
          ({data}) => {
            this.loading = false
            if (data.status == 201) {
              this.proccesing_data = true
            } else if (data.status == 202) {
              this.loading = true
              this.$axios.get(`${process.env.VUE_APP_API_URL}/api/sutech/getDataTempByUsuario`, config)
                  .then(
                      ({data}) => {
                        this.loading = false
                        if (data.ok) {
                          this.data = data.list[0].finalData
                        }
                      }
                  )
                  .catch(function (error) {
                    console.log(error)
                  })
            }
          }
      ).catch(function (error) {
        console.log(error)
      })
    },
    backToHome() {
      this.proccesing_data = false
      this.massiveSearch = false
    }
  },
}
</script>
<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>