<template>
  <v-app id="inspire">
    <div v-if="!$store.state.view_menu" class="w-full h-full">
      <v-main class="w-full h-full">
        <router-view/>
      </v-main>
    </div>
    <div v-else>
      <v-navigation-drawer
          v-model="drawer"
          :clipped="$vuetify.breakpoint.lgAndUp"
          app
      >
        <v-list dense>
          <template>
            <v-list-item
                key="home"
                link
                @click="goToLink({icon: 'mdi-home', text: 'home', action: '/', key: 'home'})"
            >
              <v-list-item-action>
                <v-icon>mdi-home</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('home') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-if="$store.state.user_permissions.includes('adminPermission')">
            <v-list-group
                :value="false"
                prepend-icon="mdi-tools"
            >
              <template v-slot:activator>
                <v-list-item-title>{{ $t('admin') }}</v-list-item-title>
              </template>
              <v-list-item
                  v-for="(item, i) in admin_menu.subMenu"
                  :key="i"
                  link>
                <template v-if="item.link">
                  <template v-if="$store.state.user_permissions.includes(item.permission)">
                    <v-list-item-title class="ml-12" @click="goToSubLink(item.action)"
                                       v-text="item.name"></v-list-item-title>
                    <v-list-item-icon class="mr-4">
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                  </template>
                </template>
              </v-list-item>
            </v-list-group>
          </template>
          <div class="border-b border-primary text-body2 text-primary mx-4 my-4 flex">
            <div class="w-1/2">Servicios</div>
            <div class="w-1/2 text-right">
              <v-icon class="text-primary ">mdi-toolbox</v-icon>
            </div>
          </div>
          <template v-for="item in items">
            <div :key="item.text"
                 v-if="item.subMenu && item.subMenu.length && $store.state.user_permissions.includes(item.permission)">
              <v-list-group
                  :value="false"
                  :prepend-icon="item.icon"
              >
                <template v-slot:activator>
                  <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                </template>
                <v-list-item
                    v-show="$store.state.user_permissions.includes(item.permission)"
                    v-for="(item, i) in item.subMenu"
                    :key="i"
                    link>
                  <template v-if="item.link">
                    <template v-if="$store.state.user_permissions.includes(item.permission)">
                      <v-list-item-title class="ml-12" @click="goToSubLink(item.action)"
                                         v-text="item.name"></v-list-item-title>
                      <v-list-item-icon class="mr-4">
                        <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                    </template>
                  </template>
                  <template v-else-if="$store.state.user_permissions.includes(item.permission)">
                    <template>
                      <v-list-group
                          :value="false"
                          sub-group
                          no-action>
                        <template v-slot:activator>
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </template>
                        <v-list-item
                            v-for="(item, i) in item.actions"
                            :key="i"
                            link>
                          <div @click="goToSubLink(item.action)"
                               v-if="$store.state.user_permissions.includes(item.permission)"
                               class="flex -ml-6">
                            <v-list-item-icon>
                              <v-icon v-text="item.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-title v-text="item.title"></v-list-item-title>
                          </div>
                        </v-list-item>
                      </v-list-group>
                    </template>
                  </template>
                </v-list-item>
              </v-list-group>
            </div>
            <div :key="item.text" v-else>
              <v-list-item
                  @click="goToSubLink(item.action)"
                  link
                  v-if="$store.state.user_permissions.includes(item.permission)">
                <v-list-item-action>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t(item.text) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </template>
          <div class="relative mt-48">
            <a href="http://sutech.com.co/" target="_blank">
              <img src="@/assets/images/logo_menu.png" alt="icon" class="">
            </a>
          </div>
        </v-list>
      </v-navigation-drawer>
      <v-app-bar
          :clipped-left="$vuetify.breakpoint.lgAndUp"
          class="bg-primary"
          app
          dark
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title
            style="width: 300px"
            class="ml-0 pl-4 flex"
        >
          <span class="hidden-sm-and-down">Servicios Sutech</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <notifications-dropdown></notifications-dropdown>
        <div class="cursor-pointer mx-4" @click="logout">
          <v-icon>mdi-logout-variant</v-icon>
          Cerrar sesión
        </div>
      </v-app-bar>
      <v-main>
        <v-alert
            v-if="$store.state.alert_message && $store.state.alert_type"
            v-model="$store.state.alert_view"
            border="left"
            colored-border
            type="info"
            elevation="2"
            class="my-4 mx-4"
            dismissible
        >
          {{ $store.state.alert_message }}
        </v-alert>
        <router-view/>
      </v-main>
    </div>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  props: {
    source: String,
  },
  data: () => ({
        dialog: false,
        drawer: null,
        items: [
          {
            icon: 'mdi-table-search',
            text: 'massive_inquiries',
            action: '/search-home',
            key: 'MassiveInquiries',
            permission: 'consultingCreate'
          },
          {
            icon: 'mdi-virus',
            text: 'covid_poll',
            action: '/covid-home',
            key: 'CovidPoll',
            permission: 'consultingCreate'
          },
          {
            icon: 'mdi-order-bool-ascending-variant',
            text: 'fleet_check',
            action: '/checklist-home',
            key: 'FleetCheck',
            subMenu: [
              {
                name: 'Dashboard',
                link: true,
                action: 'checklist-home',
                permission: 'fleetInspectionView',
                icon: 'mdi-desktop-mac-dashboard'
              },
              {
                name: 'Inspecciones',
                link: true,
                action: 'inspections-check',
                permission: 'fleetInspectionPermission',
                icon: 'mdi-tune-vertical'
              },
              {
                name: 'Novedades',
                link: true,
                action: 'novelties-check',
                permission: 'fleetNovelitiesPermission',
                icon: 'mdi-alert'
              },
              {
                name: 'Maestros',
                permission: 'fleetMasterPermission',
                link: false,
                icon: 'mdi-folder-cog',
                actions: [
                  {
                    title: 'Tipos de inspeccion',
                    action: 'vehicle-type-check',
                    permission: 'fleetVehicleTypeViewPermission',
                    icon: 'mdi-clipboard-list',
                  },
                  {
                    title: 'Items',
                    action: 'items-check',
                    permission: 'fleetItemsPermission',
                    icon: 'mdi-order-bool-ascending-variant'
                  },
                ],
              },
            ],
            permission: 'fleetPermission'
          },
          {
            icon: 'mdi-bus-multiple',
            text: 'fleet_control',
            action: '/limit-speed',
            key: 'ControlFleet',
            permission: 'speedLimitView',
            subMenu: [
              {
                name: 'Control de velocidad',
                link: true,
                action: '/limit-speed',
                permission: 'speedLimitView',
                icon: 'mdi-speedometer'
              },
              {
                name: 'Control de recorrido',
                link: true,
                action: '/control-distance',
                permission: 'speedLimitView',
                icon: 'mdi-car-traction-control'
              },
              {
                name: 'Control de tanqueo',
                permission: 'fuelControl',
                link: false,
                icon: 'mdi-folder-cog',
                actions: [
                  {
                    title: 'Dashboard',
                    action: 'fuel-control-admin',
                    permission: 'fuelControl',
                    icon: 'mdi-monitor-dashboard',
                  },
                  {
                    title: 'Control de tanqueo',
                    action: 'fuel-control',
                    permission: 'fuelControl',
                    icon: 'mdi-gas-station'
                  },
                ],
              },
            ],
          },
        ],
        menu_header: [],
        admin_menu: {
          icon: 'mdi-tools', text: 'admin',
          action: '/admin-home',
          key: 'Admin',
          subMenu: [
            {
              name: 'Dashboard',
              link: true,
              action: 'admin-home',
              permission: 'adminPermission',
              icon: 'mdi-desktop-mac-dashboard'
            },
            {
              name: 'Usuarios',
              link: true,
              action: 'users',
              permission: 'adminUsers',
              icon: 'mdi-account-group-outline'
            },
            {name: 'Roles', link: true, action: 'roles', permission: 'adminBrands', icon: 'mdi-book-lock-open-outline'},
            {name: 'Vehiculos', link: true, action: 'vehicles', permission: 'adminTypeVehicle', icon: 'mdi-car-sports'}
          ],
          permission: 'adminPermission'
        },
      }
  ),
  mounted() {
    if (localStorage.sutech_token) {
      this.$store.commit('setMenu', true)
    } else {
      this.$store.commit('setMenu', false)
      this.$router.push('login')
    }
  },
  methods: {
    goToLink(item) {
      this.$router.push(item.action)
    },
    goToSubLink(action) {
      this.$router.push(action)
    },
    logout() {
      localStorage.removeItem('sutech_token')
      this.$store.commit('setMenu', false)
      this.$router.push('login')
    }
  },
}
</script>
