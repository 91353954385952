<template>
  <v-container>
    <v-row>
      <v-col
          cols="12"
          md="4"
      >
        <v-skeleton-loader
            class="mx-auto"
            max-width="300"
            type="card"
        ></v-skeleton-loader>
      </v-col>
      <v-col
          cols="12"
          md="4"
      >
        <v-skeleton-loader
            class="mx-auto"
            max-width="300"
            type="card"
        ></v-skeleton-loader>
      </v-col>
      <v-col
          cols="12"
          md="4"
      >
        <v-skeleton-loader
            class="mx-auto"
            max-width="300"
            type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
name: "Loading"
}
</script>

<style scoped>

</style>