import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        alert_message: null,
        alert_type: null,
        alert_view: false,
        notifications: [],
        notifications_read: [],
        notifications_unread: [],
        view_menu: false,
        user: null,
        user_permissions: [],
        permissions: []
    },
    mutations: {
        setPermissions(state, permissions) {
            state.user_permissions = []
            state.permissions = []
            for (const property in permissions[0]) {
                if (property !== '_id') {
                    state.permissions.push(property)
                    if (permissions[0][property]) state.user_permissions.push(property)
                }
            }

            if (state.user_permissions.includes('fleetItemsCreate') ||
                state.user_permissions.includes('fleetItemsView') ||
                state.user_permissions.includes('fleetItemsDelete') ||
                state.user_permissions.includes('fleetInspectionCreate') ||
                state.user_permissions.includes('fleetInspectionView') ||
                state.user_permissions.includes('fleetInspectionDelete') ||
                state.user_permissions.includes('fleetNoveltiesCreate') ||
                state.user_permissions.includes('fleetNoveltiesView') ||
                state.user_permissions.includes('fleetNoveltiesDelete')) {
                state.user_permissions.push('fleetPermission')
                state.user_permissions.push('fleetVehicleTypeViewPermission')
                state.user_permissions.push('fleetMasterPermission')
            }

            if (state.user_permissions.includes('fleetItemsCreate') ||
                state.user_permissions.includes('fleetItemsView') ||
                state.user_permissions.includes('fleetItemsDelete')){
                state.user_permissions.push('fleetItemsPermission')
            }
            if (state.user_permissions.includes('fleetInspectionCreate') ||
                state.user_permissions.includes('fleetInspectionView') ||
                state.user_permissions.includes('fleetInspectionDelete')){
                state.user_permissions.push('fleetInspectionPermission')
            }
            if (state.user_permissions.includes('fleetNoveltiesCreate') ||
                state.user_permissions.includes('fleetNoveltiesView') ||
                state.user_permissions.includes('fleetNoveltiesDelete')){
                state.user_permissions.push('fleetNovelitiesPermission')
            }
            if (state.user.brands._id === '6037c30e7cafa9002f7a45f8') state.user_permissions.push('fleetVehicleTypePermission')

            if (state.user_permissions.includes('adminProcess') ||
                state.user_permissions.includes('adminTypeVehicle')||
                state.user_permissions.includes('adminBrandsView')||
                state.user_permissions.includes('adminBrandsCreate')||
                state.user_permissions.includes('adminBrandsDelete')||
                state.user_permissions.includes('adminUsersView')||
                state.user_permissions.includes('adminUsersCreate')||
                state.user_permissions.includes('adminUsersDelete')){
                state.user_permissions.push('adminPermission')
            }
            if (state.user_permissions.includes('adminBrandsView') ||
                state.user_permissions.includes('adminBrandsCreate') ||
                state.user_permissions.includes('adminBrandsDelete')){
                state.user_permissions.push('adminBrands')
            }
            if (state.user_permissions.includes('adminUsersView') ||
                state.user_permissions.includes('adminUsersCreate') ||
                state.user_permissions.includes('adminUsersDelete')){
                state.user_permissions.push('adminUsers')
            }
            if (state.user_permissions.includes('fuelControlCreate') ||
                state.user_permissions.includes('fuelControlView') ||
                state.user_permissions.includes('fuelControlDelete')){
                state.user_permissions.push('fuelControl')
            }
        },
        setUser(state, user) {
            state.user = user
        },
        setMenu(state, value) {
            state.view_menu = value
        },
        setMessage(state, items) {
            state.alert_view = true
            state.alert_message = items.message
            state.alert_type = items.type
        },
        getNotification(state) {
            state.notifications = []
            let config = {
                headers: {
                    token: localStorage.sutech_token,
                }
            }
            let url = `${process.env.VUE_APP_API_URL}/api/sutech/getNotificationsByUsuario`
            axios.get(url, config).then(
                ({data}) => {
                    if (data.notifications && data.notifications.length) {
                        state.notifications = data.notifications
                        let unread = []
                        let read = []
                        data.notifications.forEach(item => {
                            if (!item.state) unread.push(item)
                        })
                        state.notifications_read = read
                        state.notifications_unread = unread
                    }
                }
            ).catch(function (error) {
                console.log(error)
            })
        },
    },
    actions: {},
    modules: {}
})
