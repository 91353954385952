<template>
  <div>
    <div v-if="loading">
      <loading></loading>
    </div>
    <div v-else class="w-full md:flex mx-8 my-8">
      <div class="w-full md:w-1/4 m-4">
        <v-card shadow="always" class="relative rounded-md border-none bg-gray-4 m-4">
          <div class="py-2 pr-2">
            <div class="text-gray-2 text-body2 m-4">Consultas del mes</div>
            <div class="text-primary h2 text-center">
              {{ this.total_consulting_month }}
            </div>
          </div>
        </v-card>
        <v-card shadow="always" class="relative rounded-md border-none bg-gray-4 m-4">
          <div class="py-2 pr-2">
            <div class="text-gray-2 text-body2 m-4">Total consultas</div>
            <div class="text-primary h2 text-center">
              {{ total_consultings }}
            </div>
          </div>
        </v-card>
        <v-card shadow="always" class="relative rounded-md border-none bg-gray-4 m-4">
          <div class="py-2 pr-2">
            <div class="text-gray-2 text-body2 m-4">Inspecciones del mes</div>
            <div class="text-secondary h2 text-center">
              {{ this.total_inspections_month }}
            </div>
          </div>
        </v-card>
        <v-card shadow="always" class="relative rounded-md border-none bg-gray-4 m-4">
          <div class="py-2 pr-2">
            <div class="text-gray-2 text-body2 m-4">Total inspecciones</div>
            <div class="text-secondary h2 text-center">
              {{ total_inspections }}
            </div>
          </div>
        </v-card>
        <v-card shadow="always" class="relative rounded-md border-none bg-gray-4 m-4">
          <div class="py-2 pr-2">
            <div class="text-gray-2 text-body2 m-4">Cantidad de vehiculos</div>
            <div class="flex">
              <div class="w-1/2">
                <div class="text-center text-caption text-gray-3">Activos</div>
                <div class="text-success h2 text-center">
                  {{ vehicles_on }}
                </div>
              </div>
              <div class="w-1/2">
                <div class="text-center text-caption text-gray-3">Inactivos</div>
                <div class="text-danger h2 text-center">
                  {{ vehicles_off }}
                </div>
              </div>
            </div>
          </div>
        </v-card>
        <v-card shadow="always" class="relative rounded-md border-none bg-gray-4 m-4">
          <div class="py-2 pr-2">
            <div class="text-gray-2 text-body2 m-4">Cantidad de usuarios</div>
            <div class="flex">
              <div class="w-1/2">
                <div class="text-center text-caption text-gray-3">Activos</div>
                <div class="text-success h2 text-center">
                  {{ users_on }}
                </div>
              </div>
              <div class="w-1/2">
                <div class="text-center text-caption text-gray-3">Inactivos</div>
                <div class="text-danger h2 text-center">
                  {{ users_off }}
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </div>
      <div class="w-full md:w-3/4 mx-6">
        <bar-chart :chartdata="chartdataBar" :data_text="data_text" class="mx-4 mb-4"></bar-chart>
        <bar-chart :chartdata="chartdataBarInspection" class="mx-4 mt-4"></bar-chart>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from '../components/charts/Bar'

export default {
  components: {BarChart},
  name: "AdminHome",
  data() {
    return {
      loading: false,
      total_consulting_month: 0,
      total_consultings: 0,
      total_inspections_month: 0,
      total_inspections: 0,
      vehicles_on: 0,
      vehicles_off: 0,
      users_on: 0,
      users_off: 0,
      chartdataBar: [],
      chartdataBarInspection: [],
      data_text: {
        title: 'Consultas por mes',
        ytitle: 'Cantidad de consultas',
        xtitle: 'Consultas por meses'
      }
    }
  },
  mounted() {
    this.$store.commit('getNotification')
    this.getConsultingByMonth()
    this.getInspectionsByMonth()
    this.getVehicles()
    this.getUsers()
    this.getTotalConsulting()
    this.getTotalInspections()
    this.getDataBar()
    this.getDataBarInspections()
  },
  methods: {
    getConsultingByMonth() {
      this.loading = true

      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }
      let url = `${process.env.VUE_APP_API_URL}/api/sutech/getQuerysMonthByBrands/`

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.ok) {
              this.total_consulting_month = data.queryCounterMonth
            } else {
              this.total_consulting_month = 0
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    getInspectionsByMonth() {
      this.loading = true
      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }
      let url = `${process.env.VUE_APP_API_URL}/api/sutech/platesWithMonthlyInspectionsByBrands/`

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.ok) {
              this.total_inspections_month = data.result.inspectionsTrue.length
            } else {
              this.total_inspections_month = 0
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    getVehicles() {
      this.loading = true
      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }
      let url = `${process.env.VUE_APP_API_URL}/api/sutech/getMovilesByBrandsAndState/`

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.ok) {
              this.vehicles_off = data.data.inactiveMoviles
              this.vehicles_on = data.data.activeMoviles
            } else {
              this.vehicles_off = 0
              this.vehicles_on = 0
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    getUsers() {
      this.loading = true
      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }
      let url = `${process.env.VUE_APP_API_URL}/api/sutech/getUsersByBrandsAndState/`

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.ok) {
              this.users_off = data.data.inactiveUsers
              this.users_on = data.data.activeUsers
            } else {
              this.users_off = 0
              this.users_on = 0
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    getTotalConsulting() {
      this.loading = true
      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }
      let url = `${process.env.VUE_APP_API_URL}/api/sutech/getQuerysByBrands/`

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.ok) {
              this.total_consultings = data.queryCounterTotal
            } else {
              this.total_consultings = 0
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    getTotalInspections() {
      this.loading = true
      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }
      let url = `${process.env.VUE_APP_API_URL}/api/sutech/getInspetionsByMovilesAndBrands/`

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.ok) {
              this.total_inspections = data.data.inspections.length ?? 0
            } else {
              this.total_inspections = 0
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    getDataBar() {
      this.loading = true

      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }
      let url = `${process.env.VUE_APP_API_URL}/api/sutech/querysByMonthOfTheYearByBrands/`

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.data) {
              Object.entries(data.data).forEach(([key, value]) => {
                this.chartdataBar.push({name: key, y: value})
              });
            }
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
    getDataBarInspections() {
      this.loading = true

      let config = {
        headers: {
          token: localStorage.sutech_token,
        }
      }
      let url = `${process.env.VUE_APP_API_URL}/api/sutech/inspectionsByMonthOfTheYearByBrands/`

      this.$axios.get(url, config)
          .then(({data}) => {
            if (data.data) {
              Object.entries(data.data).forEach(([key, value]) => {
                this.chartdataBarInspection.push({name: key, y: value})
              });
              this.loading = false
            } else {
              this.loading = false
            }
          })
          .catch((error) => {
            this.loading = false
            console.log('error ' + error)
          })
    },
  },
}
</script>